import {Card} from "antd";
import {useForm} from "antd/es/form/Form";
import Modal from "app/Base/Modals/Modal";
import DOMPurify from "dompurify";
import React, {useEffect} from "react";

import {ForwardEmailForm} from "../Forms/PPMailForm";


export function MailModal({emailBody, ...props}) {
    const sanitizedContent = DOMPurify.sanitize(emailBody);

    return (
        <Modal
            title="Содержимое письма"
            width={"60%"}
            {...props}
            footer={null}
        >
            <Card>
                <div dangerouslySetInnerHTML={{__html: sanitizedContent}}/>
            </Card>
        </Modal>
    );
}


export function ForwardMailModal({ppmail, onSubmit, onCancel, ...props}) {
    const [form] = useForm();

    useEffect(() => {
        form.resetFields();
    }, []);

    function _onSubmit() {
        form.submit();
        onSubmit?.();
    }

    return (
        <Modal
            onCancel={onCancel}
            btnname={"Переслать письмо"}
            title="Переслать"
            width={"60%"}
            footer={null}
            form='resend_form'
            {...props}
        >
            <ForwardEmailForm 
                ppmail={ppmail} form={form} 
                id='resend_form' 
                onFinish={onCancel}
                />
        </Modal>
    );
}
