import {Button, Divider, Modal, notification, Spin} from "antd";
import BaseService from "app/API/BaseAPI";
import {BillingService} from "app/API/BillingAPI";
import PPModal from "app/Base/Modals/Modal";
import {DateTime} from "app/Components/Common/OtherComponents";
import {convertDateTime} from "app/lib/tools";
import React, {useEffect, useState} from "react";
import {useToggle} from "react-use";
import useUser from "../../Providers/UserProvider";
import TimeManageForm from "./TimeManageForm";


export default function TimeManageModal({visible, onClose}) {

    const {stopTimer, runningTask} = useUser();

    function locClose() {
        if (!runningTask) {
            stopTimer();
            onClose?.();
        }
    }

    return (
        <Modal title="Машина времени"
               visible={visible}
               onCancel={locClose}
               footer={false}
               destroyOnClose
               maskClosable={!runningTask}
        >
            <TimeManageForm/>
        </Modal>
    );
}


function BillingManageModal({visible, onClose, date}) {

    const [loading, setLoading] = useState(false);
    const [tgl, trg] = useToggle();
    const {getUser} = useUser();

    useEffect(() => {
        BillingService.check_status().then(x => {
            switch (x.status) {
                case "STARTED":
                    setTimeout(trg, 2000);
                    setLoading(true);
                    break;
                case "SUCCESS":
                case "REVOKED":
                case "REJECTED":
                    setLoading(false);
                    getUser().then(x => {});
                    break;
                case "FAILURE":
                    setLoading(false);
                    notification.error({
                        message: "Рассчёт дня завершился с ошибкой",
                    });
                    break;
                case "PENDING":
                    setLoading(false)
                    break;
                case null:
                    setLoading(false);
                    break;
            }

        }).catch(x => {
            setLoading(false);
        });
        // .catch(x => {
        //     BaseService.error_handler(x);
        // });
    }, [tgl]);

    function click_close_day() {
        setLoading(true);
        BillingService.run_next_day().then(res => {
            getUser().then(x => {});
            trg();
        }).catch(x => {
            setLoading(false);
            BaseService.error_handler(x);
        });
    }

    return <PPModal title={"Управление биллингом"}
                    onCancel={() => {
                        onClose?.();
                    }}
                    footer={false}
                    destroyOnClose
                    maskClosable={!loading}
                    visible={visible || loading}>
        <Spin spinning={loading}>
            Дата последнего выполнения биллинга <DateTime dateOnly dt={date}/>
            <Divider type={"horizontal"}/>
            <Button onClick={click_close_day}>
                Закрыть день:
            </Button> <DateTime dateOnly dt={convertDateTime(date).add(1, "days")}/>
        </Spin>
        <Divider type={"horizontal"}/>
    </PPModal>;
}

export function BillingManageButton({onClick}) {
    const [modalVsb, setModalVsb] = useState(false);
    const {lastBillingDate} = useUser();

    console.log("lastBillingDate=", lastBillingDate);

    function _onClick() {
        toggleVsbl();
        onClick?.();
    }

    function toggleVsbl() {
        setModalVsb(x => !x);
    }

    return <>
        <Button onClick={_onClick}
                title={"Дата последнего биллинга"}>
            <DateTime dt={lastBillingDate}
                      dateOnly/>
        </Button>
        <BillingManageModal
            visible={modalVsb}
            onClose={toggleVsbl}
            date={lastBillingDate}
        />
    </>;
}
