import React, { useEffect, useState } from "react";
import {Table, Space, Button, message, Tag} from 'antd';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {
  TagService
} from "../../../API/TagsAPI";
import Modal from "../../../Base/Modals/Modal";
import TagForm from "../Forms/TagForm";
import {useForm} from "antd/es/form/Form";
import useUser from "../../../Providers/UserProvider";


export default function TagsTable(){
  const {is_permitted} = useUser();
  const [tags, setTags] = useState([]);
  const [visible, setVisible] = useState(false);
  const [update, setUpdate] = useState(false);

  const [form] = useForm();

  const columns = [
    { title: 'id', dataIndex: 'id', key: 'id', },
    { title: 'Название', dataIndex: 'name', key: 'name',
      render: (_, rec) => {
        return <Tag color={rec.color}>{rec.name}</Tag>
      }
    },
    // { title: 'Цвет', dataIndex: 'color', key: 'color',
    //   render: (_, record) => {
    //     return <Tag color={record.color}>{record.name}</Tag>
    //   }
    // },
    { title: 'Тип',  dataIndex: 'type', key: 'type', },
    { title: 'Действие',
      key: 'operation',
      fixed: 'right', width: 50,
      render: (_, record) => {
        return <Space>
          {is_permitted('application.attribute.delete') &&
            <Button size='small' shape='circle' icon={<DeleteOutlined />} danger ghost onClick={() => {
              new TagService(record.id).delete().then(res => {
                message.success('Тэг удален');
                setUpdate(prev => !prev);
              })
            }} />
          }
          {is_permitted('application.attribute.edit') &&
            <Button size='small' shape='circle' icon={<EditOutlined />} onClick={() => {
              new TagService(record.id).get().then(res => {
                  form.setFieldsValue(res.data);
                }
              );
              setVisible(true);
            }} />
          }
        </Space>
      },
    },
  ];

  useEffect(() => {
    new TagService().list().then(res => {
      setTags(res.data.data)
    })
  }, [update])

  return <>
    {is_permitted('application.attribute.create') &&
      <Button type="primary" onClick={() => {
        form.resetFields();
        setVisible(true);
      }} style={{marginBottom: '1em'}}>Создать</Button>
    }
    <Table size='small' dataSource={tags} columns={columns} pagination={false} />
    <Modal title={'Тэг'}
           btnname={'Сохранить'}
           visible={visible}
           form="form"
           onSuccess={data => {
             setVisible(false);
             new TagService(form.getFieldValue('id'))
                  .update_or_create(form.getFieldsValue()).then(() => {

               setUpdate(p => !p);
             })
           }}
           onCancel={() => {setVisible(false)}}
    >
      <TagForm form={form} />
    </Modal>
  </>

}
