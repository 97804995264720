import SearchTable from "app/Base/Tables/SearchTable";
import React, { useEffect, useState } from "react";
import {Space, Button, message} from 'antd';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {AttributeService} from "src/API/DictionaryAPI";
import AttributeModal from "../Modals/AttributeModal";
import {useToggle} from "react-use";
import useUser from "src/Providers/UserProvider";



export default function AttributeTable(){
    const {is_permitted} = useUser();

    const [attributes, setAttributes] = useState([]);
    const [visible, setVisible] = useState(false);
    const [edited, setEdited] = useState(null);
    const [tgl, toggle, setValue] = useToggle(false);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    function match(haystack, needle) {
        if (needle === undefined || needle === null || needle === "")
            return true;

        if (!haystack)
            return true;

        if (typeof haystack === 'string')
            return haystack.toLowerCase().includes(needle.toLowerCase());

        return false;
    }

    const filteredAttributes = attributes.filter(x =>
        match(x.name, search)||
        match(x.codename, search)||
        match(x.type, search))

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Идентификатор',
            dataIndex: 'codename',
            key: 'codename',
        },
        {
            title: 'Тип данных',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Единица измерения',
            dataIndex: 'measure',
            key: 'measure',
            render: val => val?.name || val
        },
        {
            title: 'Действие',
            key: 'operation',
            fixed: 'right',
            render: (_, record) => {
                return <Space>
                    {is_permitted('application.attribute.delete') &&
                        <Button size='small' shape='circle' icon={<DeleteOutlined />} danger ghost onClick={() => {
                            new AttributeService(record.id).delete().then(res => {
                                message.success('Словарь удалён');
                                setEdited({});
                                setAttributes(attributes.filter(x => x.id != record.id));
                            }).catch(err => {
                                message.error('Возникла ошибка');
                            })
                        }} />
                    }
                    {is_permitted('application.attribute.edit') &&
                        <Button size='small' shape='circle' icon={<EditOutlined />} onClick={() => {
                            setEdited(record);
                            setVisible(true);
                        }} />
                    }
                </Space>
            },
        },
    ];

    useEffect(() => {
        if (!visible) {
            setLoading(true);
            new AttributeService().list().then(res => {
                setAttributes(res.data.data)
                setLoading(false);
            })
        }
    }, [tgl])

    function onCreateAttribute(){
        setVisible(false);
        setEdited(null);
        toggle();
    }

    return <>
        <SearchTable title={() => is_permitted('application.attribute.create') &&
                                <Button type="primary"
                                        onClick={() => {
                                            setVisible(true);
                                            setEdited(null);
                                        }}>
                                    Создать
                                </Button>}
                     size='small'
                     dataSource={filteredAttributes}
                     columns={columns}
                     pagination={false}
                     onSearchEdit={setSearch}
                     toggle={toggle}
                     loading={loading}/>
        <AttributeModal
            data={edited}
            visible={visible}
            destroyOnClose
            onCancel={() => {setVisible(false); setEdited(null)}}
            onSuccess={onCreateAttribute}
        />
    </>

}
