import {useState} from "react";


export default function usePagination() {

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);

    const previousPage = () => {
        if (page > 1) {
            setPage(prev => prev--);
        }
    };

    const nextPage = () => {
        setPage(prev => prev++);
    };

    const actions = {
        setPage,
        setLimit,
        previousPage,
        nextPage,
    };
    return [page, limit, actions];
}

export function usePaginationEx() {
    const [page, limit, actions] = usePagination();
    const {setPage, setLimit} = actions;

    const [total, setTotal] = useState(0);
    const [order, setOrder] = useState("");

    function onChange(pg, lmt) {
        if (pg != page)
            setPage(pg);
        if (lmt != limit)
            setLimit(lmt);
    }

    function onSortChange(pg, filters, sorter, extra) {
        if (extra.action == 'sort') {
            const {field, order: o} = sorter;

            if (!o) setOrder("");
            if (o == "descend") setOrder(`-${field}`);
            if (o == "ascend") setOrder(field);
        }
    }

    const pagination = {
        current: page,
        pageSize: limit,
        total,
        size: "small",
        responsive: true,
        hideOnSinglePage: false,
        pageSizeOptions: [10, 20, 50, 100],
        showSizeChanger: true,
        onChange,
    };

    const _actions = {
        ...actions,
        setTotal,
        setOrder,
        onSortChange
    };

    return [{page, limit, total, order}, pagination, _actions];
}
