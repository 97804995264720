import BaseService, {BaseServiceException} from "./BaseAPI";

function TagServiceException(message) {
    return new BaseServiceException(message);
}

class TagService extends BaseService {
    MODEL = 'tags'
    OBJ = 'obj'
    USED = 'used'
    FREE = 'free'

    getColors() {
        return this._get('colors');
    }

    _getTags(object_id, view) {
        return this._get(view, object_id);
    }

    getUsedTags(object_id) {
        return this._getTags(object_id, this.USED);
    }

    getFreeTags(object_id) {
        return this._getTags(object_id, this.FREE);
    }

    _makeOperOfObject(object_id, tag_id, comment, oper) {
        if (object_id) {
            return this._post(this.OBJ, object_id, oper,
                {'object': object_id, 'tag': tag_id, 'comment': comment}
            );
        } else throw TagServiceException('object_id is null!');
    }

    toggle(object_id, tag_id, comment) {
        return this._makeOperOfObject(object_id, tag_id, comment, 'toggle');
    }

    set(object_id, tag_id, comment) {
        return this._makeOperOfObject(object_id, tag_id, comment, 'set');
    }

    unset(object_id, tag_id, comment) {
        return this._makeOperOfObject(object_id, tag_id, comment, 'unset');
    }

    clear(object_id) {
        if (!object_id) throw TagServiceException('object_id is null!');
        return this._get(this.OBJ, object_id, 'clear');
    }

}

export {
    TagService
}
