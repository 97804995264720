import axios from 'axios';
import Qs from 'qs';
import AutorizationService from './autorization_backends';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(promise => {
        if (error) {
            promise.reject(error);
        } else {
            promise.resolve(token);
        }
    });

    failedQueue = [];
};

const refresh_axios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});
refresh_axios.defaults.headers.common['Content-Type'] = 'application/json';
refresh_axios.interceptors.request.use(request => {
    let backend = AutorizationService.currentBackend();
    let auth_header = backend.authHeader();
    if (auth_header)
        request.headers['Authorization'] = auth_header;
    return request;
}, error => Promise.reject(error));

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'}),
});

instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.timeout = 60000;

instance.interceptors.request.use(request => {
    let backend = AutorizationService.currentBackend();
    let auth_header = backend.authHeader();
    if (auth_header)
        request.headers['Authorization'] = auth_header;
    return request;
}, error => Promise.reject(error));

instance.interceptors.response.use(res => {
    return res;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !error.config._retry) {
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({resolve, reject})
            }).then(token => {
                originalRequest.headers['Authorization'] = token;
                return instance(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            })
        }
        originalRequest._retry = true;
        isRefreshing = true;
        try {
            let backend = AutorizationService.currentBackend();
            await backend.refresh();
            instance.defaults.headers.common['Authorization'] = backend.authHeader();
            originalRequest.headers['Authorization'] = backend.authHeader();
            processQueue(null, backend.authHeader())
            return instance(originalRequest);
        } catch (_error) {
            localStorage.removeItem('jwt_sec_token_v1')
            processQueue(_error, null);
            window.location.href = '/'
            return Promise.reject(error);
        } finally {
            isRefreshing = false;
        }
    }

    if (error.response.status === 451) { //работает машина времени
        // TODO: тут можно вызвать контекст useUser (каким-то образом) и
        // заставить фронт запросить данные о машине времени.
        // и принудительно отдать форму работы машины времени.
        // но это необязательно
    }
    return Promise.reject(error);
});

export default instance;

export {
    refresh_axios
}
