import {ReactKeycloakProvider} from "@react-keycloak/web";
import {ConfigProvider} from "antd";
import locale from "antd/lib/locale/ru_RU";
import Keycloak from "keycloak-js";
import "moment/locale/ru";
import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Loader, SandboxLoader} from "./Base";
import "./Base/theme.less";
import Login from "./Components/Auth/Login";
import Register from "./Components/Auth/Register";
import PriceplanWelcome from "./Components/RootComponent";
import PrivateRouter from "./config/PrivateRouter";
import {AuthProvider} from "./Providers/AuthProvider";
import {IntegrationProvider} from "./Providers/IntegrationsProvider";
import useSystemInfo from "./Providers/SystemProvider";
import {UserProvider} from "./Providers/UserProvider";


function EntrypointComponent() {
  // Основной входной компонент
  return (
      <Router basename={'app'}>
        <ConfigProvider locale={locale}>
          <AuthProvider>
            <Switch>
             <Route exact path='/signin/8998f68b-3c3c-4366-93ed-a83291ac257e'>
                <Login admin />
              </Route>
              <Route exact path='/signin'>
                <Login />
              </Route>
              <Route exact path='/register'>
                <Register />
              </Route>
              <UserProvider>
                  <PrivateRouter path='/'>
                    <IntegrationProvider>
                      <PriceplanWelcome />
                    </IntegrationProvider>
                  </PrivateRouter>
              </UserProvider>
            </Switch>
          </AuthProvider>
        </ConfigProvider>
      </Router>
  );
}

export default function InitializationComponent(){
  const {system, sandbox} = useSystemInfo();

  function SetupSystem() {

      let Component = <EntrypointComponent/>;

      if (system?.keycloak && system?.keycloak?.enabled && system?.keycloak?.keycloak_type_integration) {
          let keycloak = new Keycloak({
              url: system?.keycloak?.server,
              realm: system?.keycloak?.realm,
              clientId: system?.keycloak?.client_id,
          })

          Component = <ReactKeycloakProvider
              authClient={keycloak}
              initOptions={{
                  checkLoginIframe: false,
                  onLoad: 'sso_check',
                  redirectUri: window.location.origin
              }}
          >
              <EntrypointComponent/>
          </ReactKeycloakProvider>
      }
      return Component
  }

  return system ? SetupSystem() : sandbox ? <SandboxLoader visible /> : <Loader visible />

}
