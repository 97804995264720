import React, {useEffect} from "react";
import {
    Modal, Form,
    Button, Card
} from "antd";
import { NumeratorForm, SequenceForm } from "../Forms/NumeratorForm";

// import { DeleteOutlined } from '@ant-design/icons';


export function NumeratorModal({item, ...props}) {

    const [form] = Form.useForm();
    const [seq_form] = Form.useForm();

    useEffect(() => {
        if(item){
            form.setFieldsValue(item);
            seq_form.setFieldsValue(item);
        }
    }, [item])

    return <Modal 
            form={form} destroyOnClose={true} width={"80%"} 
            title={`Редактирование нумератора ${item?.seq_name}`}
            footer={false}
            {...props}
        >
            <Form.Item>
                <Card size='small'>
                    <NumeratorForm layout='vertical' form={form} id='numerator_form' onFinish={() => props?.onCancel()} />
                    <Button type='primary' htmlType='submit' form='numerator_form'>Сохранить</Button>
                </Card>
            </Form.Item>
            <Form.Item>
                <Card size='small'>
                    <SequenceForm layout='vertical' form={seq_form} id='seq_form' onFinish={() => props?.onCancel()} />
                    <Button type='primary' htmlType='submit' form='seq_form'>Сохранить</Button>
                </Card>
            </Form.Item>
    </Modal>;
}
