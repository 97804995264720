import React, { useEffect, useState } from 'react'
import {
  Space, Button, Table, Tag, Dropdown, Menu
} from 'antd'
import { SettingOutlined, UserSwitchOutlined, ImportOutlined } from '@ant-design/icons'
import UserAPI from '../../../API/UserAPI'
import EditForm from '../Forms/EditForm'
import Modal from '../../../Base/Modals/Modal'
import { useForm } from 'antd/lib/form/Form'

import { Card } from '../../../Base'
import { ContragentService } from '../../../API/ContragentAPI'
import SearchTable from "../../../Base/Tables/SearchTable";

const menu = (
  <Menu>
    <Menu.Item key='1'>
      Экспорт
    </Menu.Item>
    <Menu.Item key='2'>
      Импорт
    </Menu.Item>
  </Menu>
)


export default function UserTable ({ ContragentID }) {
  const columns = [
    { title: '№', dataIndex: 'id', key: 'id'},
    { title: 'username', dataIndex: 'username', key: 'username' },
    { title: 'Имя', dataIndex: 'first_name', key: 'first_name' },
    { title: 'Фамилия', dataIndex: 'last_name', key: 'last_name' },
    { title: 'E-Mail', dataIndex: 'email', key: 'email' },
    {
      title: 'Активен',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (status) => {
        return status === true ? (<Tag color='green'>Активен</Tag>) : <Tag color='red'>Заблокирован</Tag>
      }
    }
  ]

  const [visible, setVisible] = useState(false)
  const [users, setUsers] = useState([])
  const [formTitle, setFormTitle] = useState('')
  const [userform] = useForm()
  const [isUsersModal, setIsUsersModal] = useState(false)
  const [usersIdsModal, setUsersIdsModal] = useState([])
  const [usersModal_, setUsersModal] = useState([])
  const columnsUsersModal = [
    { title: 'username', dataIndex: 'username', key: 'username' },
    { title: 'Имя', dataIndex: 'first_name', key: 'first_name' },
    { title: 'Фамилия', dataIndex: 'last_name', key: 'last_name' },
    { title: 'E-Mail', dataIndex: 'email', key: 'email' }]
  const rowSelectionUsersModal = {
    onChange: (selectedRowKeys) => {
      setUsersIdsModal(selectedRowKeys)
    }
  }

  useEffect(() => {
    if (ContragentID) {
      new ContragentService(ContragentID).getContragentUsers().then(res => {
        setUsers(res.data.data)
      })
    } else {
      new UserAPI().getUserList().then(res => {
        setUsers(res.data.data)
      })
    }
  }, [visible, ContragentID, isUsersModal])

  useEffect(() => {
    if (isUsersModal) {
      new ContragentService(ContragentID).getUsers().then((r) => {
        setUsersModal(r.data.data)
      })
    }
  }, [isUsersModal, ContragentID])

  function onSendData () {
    const userId = userform.getFieldValue('id')
    if (userId == null) {
      new UserAPI().addUser(userform.getFieldsValue()).then((res) => {
        setUsers([])
      }).catch(err => {
      }).finally(() => setVisible(false))
    } else {
      new UserAPI(userId).modifyUser(userform.getFieldsValue()).then(res => {
      }).catch(err => {
      }).finally(val => {
        setVisible(false)
      })
    }
  }


  return (
    <Card
      bordered={false}
      extra={
          <Space>
             <Button
              key='0' icon={<UserSwitchOutlined />}
              onClick={() => {
                setFormTitle('Новый пользователь')
                setVisible(true)
                userform.resetFields()
              }}
            >Создать
            </Button>
            {(() => {
              if (ContragentID) {
                return (
                  <Button
                    icon={<ImportOutlined />}
                    key='2'
                    onClick={() => setIsUsersModal(true)}
                  >
                    Добавить
                  </Button>
                )
              }
            })()}
            <Dropdown key='1' overlay={menu} trigger={['click']}>
              <Button icon={<SettingOutlined />} />
            </Dropdown>
          </Space>
      }
    >
      <Table
        size='small' columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: ev => {
              setFormTitle('Редактирование пользователя')
              userform.setFieldsValue({
                id: record.id,
                username: record.username,
                first_name: record.first_name,
                last_name: record.last_name,
                email: record.email,
                is_active: record.is_active
              })
              setVisible(true)
            }
          }
        }}
        dataSource={users}
        pagination={{ pageSize: 100 }}
      />
      <Modal
        visible={visible}
        btnname='Сохранить'
        title={formTitle}
        form='userform'
        onCancel={() => setVisible(false)}
        onSuccess={onSendData}
      >
        <EditForm form={userform} />
      </Modal>
      <Modal
        // style={{width: "100%"}}
        width={"50%"}
        btnname='OK'
        cancelText='Отмена'
        onCancel={() => setIsUsersModal(false)}
        onSuccess={() => {
          new ContragentService(ContragentID).setUsers(usersIdsModal).then(() => {
            setIsUsersModal(false)
            setUsersIdsModal([])
          })
        }}
        title='Добавить пользователей'
        visible={isUsersModal}
      >
        <SearchTable
          columns={columnsUsersModal}
          dataSource={usersModal_}
          pagination={false}
          rowKey='id'
          rowSelection={{ ...rowSelectionUsersModal }}
          size='small'
          scroll={{x:true, y:500}}
        />
      </Modal>
    </Card>
  )
}
