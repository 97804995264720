import React, {useEffect, useState} from "react";
import {Form, Input, Collapse, Radio, message} from 'antd';
import {DictionaryService} from "../../../API/DictionaryAPI";
import {useForm} from "antd/es/form/Form";
import {renderInput} from '../../../lib/dynamic-comps';

import './forms.css'
import {ContragentService} from "../../../API/ContragentAPI";

const FL_CONTR = 'fl_contragents';
const UL_CONTR = 'ul_contragents';
const TP_DICT = {
    [UL_CONTR]: 1,
    [FL_CONTR]: 2
};

export default function CreateContragentForm({...props}) {

    const [dictionaries, setDictionaries] = useState([]);
    const [typePerson, setTypePerson] = useState(FL_CONTR);
    const [form] = useForm();

    useEffect(() => {
        if (typePerson) {
            new DictionaryService().getDictsByEntity(typePerson).then(res => {
                setDictionaries(res.data.data)
            })
        }
    }, [typePerson])

    function createContragent(data) {
        data['type'] = TP_DICT[typePerson];

        new ContragentService().create(data).then(res => {
            message.success('Контрагент успешно создан');
            typeof props?.onFinish === 'function' && props.onFinish(data);
        });
    }

    return (
        <Form form={form} layout='vertical' {...props} onFinish={createContragent}>
            <Form.Item label='Форма' valuePropName={'value'} rules={[
                {required: true, message: 'Необходимое поле'}
            ]}>
                <Radio.Group className={'fl-ul-radio'} defaultValue={FL_CONTR}
                                onChange={(e) => setTypePerson(e.target.value)}>
                    <Radio.Button value={FL_CONTR}>Физическое лицо</Radio.Button>
                    <Radio.Button value={UL_CONTR}>Юридическое лицо</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item name={'name'} label={'Наименование'} rules={[
                {required: true, message: 'Необходимое поле'}
            ]}>
                <Input type={'text'} placeholder={'Наименование'}/>
            </Form.Item>
            <Form.Item name={'attributes'} label='Дополнительная информациия'>
                {dictionaries.map(el => (
                    <Collapse>
                        <Collapse.Panel header={el.name}>
                            {/* <AttributesForm id={el.codename} attributes={el.attributes}/> */}
                            {el.attributes.map(attr => (
                                <Form.Item key={attr.codename}
                                        name={['attributes', attr.codename]}
                                        label={attr.name}
                                        required={attr.is_required}
                                        initialValue={attr.default}>
                                    {renderInput(attr.type, attr.enums)}
                                </Form.Item>
                            ))}
                        </Collapse.Panel>
                    </Collapse>
                ))}
            </Form.Item>
        </Form>
    )
}

export {
    TP_DICT, FL_CONTR, UL_CONTR
}
