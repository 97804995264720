import {DeleteOutlined, MenuOutlined} from "@ant-design/icons";
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Layout,
    Radio,
    Row,
    Select,
    Space,
    Transfer,
} from "antd";
import {T_BOOL, T_CHOICE, T_DATE, T_DATETIME, T_INT, T_STR, EXPRESSIONS,} from "app/Base/Tables/FlexibleTable/const";
import {useEffect, useRef, useState} from "react";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {useDebounce, useList} from "react-use";
import {useColumns, useFilters, useFlexible} from "./FlexibleTable";
import {Column, Filter} from "./Types";
import moment from 'moment';

const Option = Select.Option;



function ControlsFactory({column, filter, val, onChange}) {
    const style = {
        minWidth: 300
    }

    useEffect(() => {
        if (!val) {
            switch (filter?.type) {
                case T_INT: onChange(0); break;
                case T_STR: onChange(""); break;
                case T_BOOL: onChange(false); break;
                case T_DATE:
                    if (val)
                        onChange(moment(val).toDate?.());
                    else
                        onChange(moment().toDate());
                    break;
                case T_DATETIME:
                    if (val)
                        onChange(moment(val).toDate?.());
                    else
                        onChange(moment().toDate());
                    break;
                case T_CHOICE:
                    onChange(filter?.choices?.[0]?.[0]); break;
            }
        }
    }, [filter]);

    function _onChange_input(e) {
        return onChange(e?.target?.value);
    }

    function _onChange_number_input(val) {
        return onChange(val);
    }

    function _onChange_datetime(mmnt) {
        return onChange(mmnt?.toDate?.() || mmnt)
    }

    function _onChange_date(mmnt) {
        return onChange(mmnt.format('YYYY-MM-DD'));
    }

    function _onChange_checkbox(value) {
        return onChange(value)
    }

    function _onChange_select(value) {
        return onChange(value)
    }

    switch (filter?.type) {
        case T_INT:
            return <InputNumber style={style}
                                onChange={_onChange_number_input}
                                value={val}/>;
        case T_BOOL:
            return <Checkbox onChange={_onChange_checkbox} checked={!!val}/>;
        case T_CHOICE:
            return <Select style={style}
                           onChange={_onChange_select}
                           value={val}>
                      {filter.choices
                             ?.map(([val, display]) => (
                                 <Option value={val}>
                                     {display}
                                 </Option>
                             ))}
                </Select>
        case T_DATE:
            return <DatePicker style={style}
                               onChange={_onChange_date}
                               onSelect={_onChange_date}
                               value={moment(val)}/>;
        case T_DATETIME:
            return <DatePicker style={style}
                               onChange={_onChange_datetime}
                               onSelect={_onChange_datetime}
                               value={moment(val)}
                               showTime/>;
        default:
            return <Input style={style}
                          onChange={_onChange_input}
                          value={val}/>;
    }
}


export function FilterRow({filter, index, onChange}) {
    const {removeFilter, updateFilter, metadata} = useFilters();

    const [col, set_col] = useState(filter.column);
    const [oper, set_oper] = useState(filter.operator);
    const [value, setValue] = useState(filter.value);
    const [ch_flt, set_ch_flt] = useState(filter);

    const filter_fields = metadata
        .reduce((a,b) => {
            if (!a.find(x => x.field==b.field))
                a.push(b);
            return a;
        },[]);

    const opers = metadata
        .filter(x => x.field==col)
        .map(x => EXPRESSIONS[x.expr])
        .reduce((a,b) => {
            if (!a.find(x => x==b))
                a.push(b);
            return a;
        }, []);

    useEffect(() => {
        if (col && oper && value !== undefined) {
            updateFilter(new Filter(col, oper, value, ch_flt.type, ch_flt?.choices), index);
        }
    }, [col, oper, value, ch_flt]);

    function setFilter(colname) {
        const flt_obj = filter_fields.find(x => x.field == colname);
        set_ch_flt(flt_obj);
        set_col(colname);
    }

    return <Space>
        <Select onChange={setFilter}
                value={col}
                style={{width: 200}}>
            {filter_fields.map(
                x => <Select.Option value={x.field}>
                    {x.label}
                </Select.Option>
            )}
        </Select>
        <Select onChange={set_oper} value={oper} style={{width: 200}}>
            <Select.Option value={null}> </Select.Option>
            {
                opers.map?.(op => <Select.Option value={op}>{op}</Select.Option>)
            }
        </Select>
        <ControlsFactory val={value}
                         onChange={setValue}
                         filter={ch_flt}/>
        <Button icon={<DeleteOutlined/>}
                onClick={() => {
                    removeFilter(filter)
                }}/>
    </Space>
}


export function RowOrderBy() {
    const {orderby, setOrderby, model} = useFlexible();
    const {columns, metadata, precolumns} = useColumns();
    // const order_cols = model.options.orders.map(x => metadata.find(z => z.key==x || z.name==x));
    const precs = precolumns.map(x => x.name);
    const order_cols = metadata.filter(x => precs.includes(x.key));

    return <div>
        <Row>
            <Select value={orderby?.column} style={{width: "100%"}}
                    onChange={value => setOrderby({...orderby, column: value})}
                    disabled={!order_cols?.length}>
                {order_cols.map(x =>
                    <Select.Option value={x.key}> {x.title} </Select.Option>
                )}
            </Select>
        </Row><Row>
            <Radio.Group onChange={e => setOrderby({...orderby, asc: e.target.value})}
                         style={{width: "100%"}}
                         value={orderby?.asc}
                         disabled={!order_cols?.length}>
                <Col><Radio value={true}>По возрастанию</Radio></Col>
                <Col><Radio value={false}>По убыванию</Radio></Col>
            </Radio.Group>
        </Row>
    </div>;
}

export function Pagination() {
    const {current, updateCurrent} = useFlexible();
    const [val, setVal] = useState(current.limit || 20);

    useDebounce(() => {
        updateCurrent({...current, limit: val})
    }, 500, [val]);

    return <div>
        <Form.Item name={'limit'} label={'Количество записей на странице'}>
            <InputNumber onChange={setVal}
                         min={1} max={50}
                         defaultValue={val}/>
        </Form.Item>
    </div>
}

export function TransferDragItem({item, moveRow}) {
    const ref = useRef(null);
    const index = item.key;

    const [{isOver, dropClassName}, drop] = useDrop({
        accept: 'columns',
        collect: (monitor) => {
            const {index: dragIndex} = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName:
                    dragIndex < index ? ` drop-over-downward` : ` drop-over-upward`
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        }
    });

    const [{isDragging}, drag, preview] = useDrag({
        type: 'columns',
        item: {index},
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    preview(drop(ref));

    return (
        <div
            key={item.id}
            ref={ref}
            className={`${isOver ? dropClassName : ""}`}
        >
            <Row className="label" ref={drag} justify='space-between'>
                {item.title || item.title}
                {index !== -1 && (<MenuOutlined/>)}
            </Row>
        </div>
    )
}


export function TransferDnD() {
    const {precolumns, metadata, setColumns, remove, insertAt} = useColumns();
    const [sldKeys, {set: sldset}] = useList([]);
    const trgKeys = precolumns.map(x => x.name);

    function onChange(keys) {
        setColumns(metadata.filter(x => keys.includes(x.key)));
    }

    function moveRow(dragIndex, hoverIndex) {
        const   i = trgKeys.indexOf(dragIndex),
                j = trgKeys.indexOf(hoverIndex);
        const col = precolumns[i];

        remove(i);
        insertAt(j, col);
    }

    function filterOption(value, option) {
        return option.title.includes(value);
    }

    return <div>
        <DndProvider backend={HTML5Backend}>
            <Transfer
                dataSource={metadata}
                showSearch
                filterOption={filterOption}
                titles={['Доступные поля', 'Поля на вывод']}
                targetKeys={trgKeys}
                selectedKeys={sldKeys}
                onChange={onChange}
                onSelectChange={(sKeys, tKeys) => sldset([...sKeys, ...tKeys])}
                render={item => (
                    <TransferDragItem item={item}
                                      moveRow={moveRow}/>)}
                listStyle={{
                    width: "100%",
                    height: 350,
                }}/>
        </DndProvider>
    </div>
}
