import React, { useEffect, useState } from "react";
import {Layout, Row, Col, Menu} from 'antd'
import {Scrollbars} from 'react-custom-scrollbars';


export default function AsideMenu({defaultId, colAside, colContent, ...props}){

    const [currentComponent, setCurrentComponent] = useState(null)

    useEffect(() => {
        let i = 0;
        React.Children.map(props.children, child => {
            if(defaultId && (child.props.id === defaultId)){
                setCurrentComponent(child.props.children)
            }else{
                if (i === 0) setCurrentComponent(child.props.children)
            }
            i++;
            return child
        })
    }, [props.children])

    const childrenWithProps = React.Children.map(props.children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {onSelect: function(key, component){
              setCurrentComponent(component)
            }, ...child.props});
        }
        return child;
    });

    return (<Layout.Content style={{height: '100%'}}>
        <Row gutter={16} style={{height: '100%'}}>
            <Col xs={4}>
                <Scrollbars>
                    <Menu>
                        {childrenWithProps && childrenWithProps.map(child => {
                            return child
                        })}
                    </Menu>
                </Scrollbars>
            </Col>
            <Col xs={20}>
                <Scrollbars>
                    {currentComponent}
                </Scrollbars>
            </Col>
        </Row>
    </Layout.Content>
    )

}

export function AsideTab({title, id, onSelect, ...props}){

    function onSelectChildren(){
        return onSelect?.(id, props.children)
    }
    return (
        <Menu.Item
            key={String(id)}
            onClick={() => onSelectChildren(props.children)}
            {...props}
        >
            {title}
        </Menu.Item>
    )
}
