import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {Card, Col, Descriptions, Select, message, PageHeader, Row, Typography, Button} from 'antd'
import {DeleteOutlined, EditOutlined, CheckOutlined, StopOutlined} from '@ant-design/icons';

import {ProductService, ProductNomenclatureService} from 'src/API/ProductAPI'

import useUser from 'src/Providers/UserProvider'
import useProduct, {Product} from './ProductContext'

import { ProductModal } from './Modals/ProductModal'
import { OfferProductTable } from './Table/OffersTable';
import { ListAttrAsDescription } from '../Common/OtherComponents';


export default function ProductDetail() {

    const {is_permitted, contragent} = useUser();

    const {product, setProduct} = useProduct();
    const [productAttributes, setProductAttributes] = useState([]);
    const [productModal, setProductModal] = useState(false);
    const [update, setUpdate] = useState(true);

    const [nomenclatureList, setNomenclatureList] = useState([])
    const [nomenclature, setNomenclature] = useState(null)
    const [editNomenclature, setEditNm] = useState(false)

    const nmFilter = {contragent: contragent?.id, pp_product__isnull: true}

    const params = useParams()

    useEffect(() => {
        let ps = new ProductService(contragent?.id, params.id);
        Promise.all([
            ps.get()
        ]).then(([productInfoResp]) => {
            setProduct( new Product(productInfoResp.data))
        }).catch(err => {
            message.error(`Продукт не найден ${err.message}`)
        })

    }, [params.id, update])

    useEffect(() => {
        let ps = new ProductService(contragent?.id, params.id);
        ps.attributes.list().then(res => {
            setProductAttributes(res.data.data)
        })

    }, [params, update, ])

    useEffect(() => {
        if (contragent)
            new ProductNomenclatureService(contragent?.id).list(
                {params: nmFilter}).then(res =>
                setNomenclatureList(res.data.data)
                )
    }, [contragent])

    function searchNomenclature (value) {
        new ProductNomenclatureService(contragent?.id).list(
          {params: {...nmFilter, code__startswith: value}}).then(res => {
          setNomenclatureList(res.data.data)
        })
      }

    function changeNomenclature (value) {
        setNomenclature(value);
    };

    function onBlurNomenclature() {
        setEditNm(false)
        new ProductService(contragent?.id, product.id).setNomenclature({nomenclature: nomenclature}).then(res =>
            setProduct( new Product(res.data))
        )
    }

    function deleteNm () {
        new ProductService(contragent?.id, product.id).setNomenclature().then(res =>
            setProduct( new Product(res.data))
        )
    }

    return (
      <PageHeader
        style={{background: 'white'}}
        title={<Typography.Title level={3}>{product?.name}</Typography.Title>}
      >
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Card size='small'>
                    <Descriptions column={1} bordered title='Общая информация' size='small' extra={
                                <Typography.Link onClick={() => setProductModal(true) }>
                                    <EditOutlined style={{cursor: 'pointer'}} />
                                </Typography.Link>
                            }>
                        <Descriptions.Item label='Наименование'>{product?.name}</Descriptions.Item>
                        <Descriptions.Item label='Статус продукта'>{product?.status_text}</Descriptions.Item>
                        <Descriptions.Item label='Единицы измерения'>{product?.measure_name || 'отсутствует'}</Descriptions.Item>
                        <Descriptions.Item label='Описание продукта'>{product?.description || 'отсутствует'}</Descriptions.Item>
                    </Descriptions>
                </Card>
            </Col>
            <Col xs={24}>
                <Card size='small'>
                    <Descriptions column={1} bordered title='Номенклатура бухгалтерии' size='small'
                        style={{marginBottom: 25}}>
                        <Descriptions.Item label='Код номенклатуры в бухгалтерии'>
                            {editNomenclature
                                ?  <Row justify='space-between'>
                                        <Select
                                            style={{width: '50%'}}
                                            showSearch
                                            value={nomenclature}
                                            showArrow={true}
                                            filterOption={false}
                                            onSearch={searchNomenclature}
                                            onChange={changeNomenclature}
                                            notFoundContent={null}
                                        >
                                            {nomenclatureList.length > 0
                                                ? nomenclatureList.map(nm =>
                                                    <Select.Option
                                                        key={nm.id}
                                                        value={nm.id}
                                                        >
                                                        <Typography>{nm.code} {nm.name}</Typography>
                                                    </Select.Option>)
                                                : <Typography>Нет записей номенклатуры без привязки к продуктам</Typography>
                                            }
                                        </Select>
                                        <Col>
                                            <Button size='small' shape='circle' icon={<CheckOutlined />}
                                                    style={{marginRight: 10}} onClick={onBlurNomenclature}/>
                                            <Button size='small' shape='circle' icon={<StopOutlined />}
                                                    onClick={() =>setEditNm(false)} danger/>
                                        </Col>
                                    </Row>
                                : <Row justify='space-between'>
                                        <Col>
                                            <Typography.Text >
                                                {(product && product.nomenclature_code && product.nomenclature_name)
                                                    ? `${product.nomenclature_code}  ${product.nomenclature_name}`
                                                    : 'отсутствует'
                                                 }
                                            </Typography.Text>
                                        </Col>
                                        <Col>
                                            <Button size='small' shape='circle'
                                                style={{marginRight: 10}}
                                                icon={ <EditOutlined />} onClick={() => {
                                                    setEditNm(true);
                                                    setNomenclature(product?.nomenclature_code)}} />
                                            <Button size='small' shape='circle' icon={<DeleteOutlined />}
                                                onClick={deleteNm} danger/>
                                        </Col>
                                    </Row>
                                }
                        </Descriptions.Item>
                    </Descriptions>
                    {productAttributes && <ListAttrAsDescription attrs={productAttributes}/>}
                </Card>
            </Col>
            <Col xs={24}>
                <Card size='small'>
                    <Typography.Title level={5} style={{fontWeight: 'bold'}}>Ценовые предложения</Typography.Title>
                    {product && <OfferProductTable product={product}/>}
                </Card>
            </Col>
          </Row>
          <ProductModal
                visible={productModal}
                item={product}
                onCancel={() => {setProductModal(false); setUpdate(p => !p)}}
            />

      </PageHeader>
    )

}
