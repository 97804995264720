import {EditOutlined} from "@ant-design/icons";
import {Card, Col, Descriptions, message, PageHeader, Row, Tabs, Tag, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useToggle} from "react-use";
import {useParams} from "react-router-dom";
import {PackageService} from "src/API/PackageAPI";
import Modal from "../../Base/Modals/Modal";
import useUser from "../../Providers/UserProvider";
import {ListAttrAsDescription} from "../Common/OtherComponents";
import {PackageModal} from "./Modals/PackageModal";
import usePackage, {Package} from "./PackageContext";
import {PackagePriceTable} from "./PackageTable";


export default function PackageDetail() {
    const {is_global_permitted, contragent} = useUser();
    const {packages, setPackage} = usePackage();
    const [packageModalVisible, setPackageModalVisible] = useState(false);
    const [packageAttributes, setPackageAttributes] = useState([]);
    const [packageModal, setPackageModal] = useState(false);
    const [update,toggle] = useToggle(false);
    const params = useParams();

    useEffect(() => {
        let ps = new PackageService(contragent?.id, params.id);
        Promise.all([
            ps.get()
        ]).then(([packageInfoResp]) => {
            setPackage( new Package(packageInfoResp.data))
        }).catch(err => {
            message.error(`Продукт не найден ${err.message}`)
        })
    }, [params.id, packageModalVisible, update, packageModal]);

    useEffect(() => {
        let ps = new PackageService(contragent?.id, params.id);

        ps.attributes.list().then(res => {
            setPackageAttributes(res.data.data)
        })

    }, [params, packageModal]);

    return (
        <PageHeader style={{background: 'white'}}
                    title={<Typography.Title level={3}>{packages?.name}</Typography.Title>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Card size='small'>
                        <Descriptions column={1} bordered title='общая информация' size='small' extra={
                            is_global_permitted('application.package.edit') &&
                            <Typography.Link onClick={() => setPackageModal(true) }>
                                <EditOutlined style={{cursor: 'pointer'}} />
                                <PackageModal visible={packageModal} item={packages}
                                              onSuccess={() => {setPackageModal(false); toggle()}}
                                              onCancel={() => setPackageModal(false)}
                                />
                            </Typography.Link>
                        }>
                            <Descriptions.Item label='Наименование'>
                                <Typography.Text>{packages?.name}</Typography.Text>
                            </Descriptions.Item>
                            <Descriptions.Item label='статус продукта'>
                                {packages?.status_text}
                            </Descriptions.Item>
                            <Descriptions.Item label='Артикул'>
                                {packages?.identifier || 'отсутствует'}
                            </Descriptions.Item>
                            <Descriptions.Item label='Единицы измерения'>
                                {packages?.measure_name || 'отсутствует'}
                            </Descriptions.Item>
                            <Descriptions.Item label='Описание продукта'>
                                {packages?.description || 'отсутствует'}
                            </Descriptions.Item>
                            <Descriptions.Item label='Тэги'>
                                {packages && packages.tags_as_objects
                                    ? packages.tags_as_objects.map(tag =><Tag>{tag.name}</Tag>)
                                    : 'нет тэгов'
                                }
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
                {packageAttributes && <ListAttrAsDescription attrs={packageAttributes}/>}
            </Row>
            <Tabs>
                <Tabs.TabPane tab="Ценовые предложения входящие в пакет" key="prices">
                    <PackagePriceTable />
                </Tabs.TabPane>
            </Tabs>
            <Modal form="edit_package"
                 visible={packageModalVisible}
                 btnname={'Сохранить'}
                 onCancel={() => {
                     setPackageModalVisible(false)
                 }}
                 title={"Изменить продукт"}>
            </Modal>

        </PageHeader>
    )
}
