import axios from "../config/axios";
import BaseService from "./BaseAPI";


class MeasureService extends BaseService {
    MODEL = 'attribute/measure'

    getMeasures(params) {
        return this.list(params)
    }

    postMeasure(data) {
        return this.create(data)
    }

    patchMeasure(data) {
        return axios.patch(this._mk_res(this._id), data)
    }

    putMeasure(data) {
        return axios.put(this._mk_res(this._id), data)
    }

    deleteMeasure() {
        return axios.delete(this._mk_res(this._id))
    }
}

class DictionaryService extends BaseService {
    MODEL = 'dictionary'

    getDictsByEntity(entity) {
        return this._get("entity",entity)
    }

    getAttrsByEntity(entity) {
        return this._get("entity",entity, "attribute")
    }

    getEntities() {
        return axios.get(this._mk_res('entity'))
    }
}

class AttributeService extends BaseService {
    MODEL = 'attribute'

    getTypes() {
        return this._get('types');
    }

    getFields() {
        return this._get('fields');
    }
}

class AttributesProperty extends AttributeService {
    MODEL = 'attribute'

    constructor(objserv) {
        super(objserv.get_id());
        this._object = objserv;
        this.dictionary = new DictionaryService();
    }

    _mk_res() {
        return this._object._mk_res(this._id, this.MODEL);
    }
}

class EnumGroupService extends BaseService {
    MODEL = 'enumgroup'

    getValues() {
        return this._get('values');
    }
}

export {
    MeasureService,
    AttributeService,
    AttributesProperty,
    DictionaryService,
    EnumGroupService
}
