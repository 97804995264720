import BaseService from './BaseAPI'


class BlockingService extends BaseService {
    MODEL = 'blocking'

    constructor(contragent_id, blocking_id) {
        super(blocking_id);
        this.MODEL = `contragent/${contragent_id}/blocking`
    }

    cancel() {
        return this._post('cancel').then(res => {
            return Promise.resolve(res);
        }).catch(BlockingService.error_handler);
    }
}

class BlockingTypeService extends BaseService {
    MODEL = 'blocking-type'
}


class BlockingContragentService extends BaseService {
    // MODEL = 'blocking'

    constructor(contragent_id, id=null) {
        super(id);
        this.MODEL = `contragent/${contragent_id}/blocking`
    }

    cancel() {
        return this._post('cancel').then(res => {
            return Promise.resolve(res);
        }).catch(BlockingService.error_handler);
    }
}


export {
    BlockingService,
    BlockingTypeService,
    BlockingContragentService
}
