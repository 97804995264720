import { BaseAuthService, KeycloakAuthService, UnAuthService } from "../API/AuthAPI";


class AutorizationService {
    access_token = null;
    resresh_token = null;
    token_type = null;
    AuthBackendService = new UnAuthService();

    constructor(AuthBackendService) {
        let token = this.token();
        if (token) {
            this.access_token = token.access_token
            this.resresh_token = token.refresh_token
            this.token_type = token.token_type
        }
        this.authBackendService = new AuthBackendService()
    }

    test() {
        return this.authBackendService.test()
    }

    login({username, password}) {
        return this.authBackendService.login({username, password}).then(res => {
            this.setToken(res.data);
            return Promise.resolve(this.token());
        }).catch(err => {
            return Promise.reject('Authentication error');
        })
    }

    logout() {
        let token = this.token();
        return this.authBackendService.logout(token).then(res => {
            this.removeToken();
            return Promise.resolve(res.data);
        }).catch(err => {
            this.removeToken();
            return Promise.reject('Authentication error');
        })
    }

    refresh() {
        let token = this.token();
        return this.authBackendService.refresh(token).then(res => {
            this.setToken(res.data);
            return Promise.resolve(res.data);
        }).catch(err => {
            return Promise.reject('Authentication error');
        })
    }

    token() {
        return JSON.parse(localStorage.getItem('jwt_sec_token_v1'));
    }

    getInfoToken(tkn) {
        let token = this.token() || tkn;
        return JSON.parse(atob(token?.access_token.split('.')[1]));
    }

    setToken({access_token, refresh_token, token_type}) {
        // назначение токена в приложения
        localStorage.setItem('jwt_sec_token_v1', JSON.stringify({
            access_token: access_token,
            refresh_token: refresh_token,
            token_type: token_type
        }));
    }

    removeToken() {
        // удаление токена из приложения
        localStorage.removeItem('jwt_sec_token_v1');
    }

    authHeader() {
        return `${this.token_type} ${this.access_token}`;
    }

    static currentBackend() {
        // Статический метод. Возвращает текущий авторизационный бэкенд
        let token = JSON.parse(localStorage.getItem('jwt_sec_token_v1'));
        let BACKENDS = {
            'JWT': BaseAuthService,
            'Keycloak': KeycloakAuthService
        }
        let CurrentBackend = token?.token_type ? BACKENDS[token?.token_type] : UnAuthService;
        return new AutorizationService(CurrentBackend);
    }

}


export default AutorizationService
