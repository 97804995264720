import {ContragentableService} from "app/API/ContragentBasedService";


class PPMailService extends ContragentableService {
    MODEL = 'mail';
}

export {
    PPMailService,
}
