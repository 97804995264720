import {Form, notification} from "antd";
import Modal from "app/Base/Modals/Modal";
import React, {useEffect} from "react";
import {DictionaryService} from "src/API/DictionaryAPI";
import DictionaryForm from "../Forms/DictionaryForm";


export default function DictionaryModal({data, onSuccess, ...props}) {

    const [form] = Form.useForm();

    useEffect(() => {
        if (data) {
            new DictionaryService(data.id).get().then(res => {
                res.data.attributes = res.data.attributes.map(el => {
                    el = el.id
                    return el
                })
                form.setFieldsValue(res.data);
            })
        } else {
            form.resetFields();
        }
    }, [data, form])

    function execute(d) {
        const ds = new DictionaryService(data?.id);
        const methodname = data?'update':'create';
        ds[methodname](d).then(res => {
            (typeof onSuccess === 'function') && onSuccess(res.data);
            notification.success({message: "Группа атрибутов сохранена"});
        }).catch(DictionaryService.error_handler)
    }

    return <Modal {...props}
                  title={(data) ? "Редактировать группу атрибутов" : "Создать группу атрибутов"}
                  form={"manage_attribute"}
                  btnname={"Сохранить"}>
        <DictionaryForm form={form} id='manage_attribute' onFinish={execute}/>
    </Modal>;
}
