import React from 'react'
import {Modal} from 'antd'
import OfferTable, {PackageTable} from '../Tables/OfferTable';

export function SelectOfferModal({service_item, onSelectOffer, ...props}) {
    // модальное окно для выбора оффера

    return (
        <Modal {...props} width='90%' destroyOnClose title='Выбрать тариф' footer={false}>
            <OfferTable onSelectOffer={onSelectOffer} type='3'/>
        </Modal>
    )
}

export function SelectPackageModal({service_item, onSelect, ...props}) {
    // модальное окно для выбора оффера

    return (
        <Modal {...props} width='90%' destroyOnClose title='Выбор пакета' footer={false}>
            <PackageTable onSelect={onSelect} type='3'/>
        </Modal>
    )
}
