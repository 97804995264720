import { message } from "antd";
import axios from "../config/axios";
import BaseService, {error_handler} from "./BaseAPI";

import moment from 'moment'

const API_TIME = '/api/sandbox'

class SettingService extends BaseService {
    MODEL = 'setting';

    getVersion() {
        return this._get('version').catch(x => {
            return {status: "fail", msg: "-unknown-", branch: "-unknown-"};
        })
    }

    uploadLogo(data) {
        return this._post('logo', data, {headers: {'Content-Type': 'multipart/form-data'}})
    }

    emailTest() {
        return this._post('emailtest').then(res => {
            message.success('Отправка почты успешна');
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        })
    }

    s3Test(data) {
        return this._post('s3test', data).then(res => {
            message.success('соединение успешно');
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        })
    }

    update(data) {
        return this._post(data)
    }

    importData(data) {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("label", data.label);

        return axios({
            method: 'post',
            url: '/api/setting/import',
            data: formData,
            headers: { 
                'content-type': 'multipart/form-data',
                // 'Authorization': `JWT ${token}` 
            }
            }).then(res => {

                // message.success('Импорт данных .');
                return Promise.resolve(res);
            }).catch(err => {
                error_handler(err);
                return Promise.reject(err);
            })

        // return this._post('import_data', data).then(res => {

        //     message.success('Импорт данных .');
        //     return Promise.resolve(res);
        // }).catch(err => {
        //     error_handler(err);
        //     return Promise.reject(err);
        // })
    }
}

function getCurrentTime() {
    return axios.get(API_TIME).then(res => {
        return Promise.resolve(res);
    }).catch(err => {
        error_handler(err);
        return Promise.reject(err);
    })

}

function breakingSandbox(){
    return axios.post('/api/sandbox/break').then(res => {
        message.success('Машина времени будет остановлена при завершении текущего дня');
        return Promise.resolve(res);
    }).catch(err => {
        error_handler(err);
        return Promise.reject(err);
    })
}

function setCurrentTime(time) {
    var _time;
    if (moment.isDate(time)) {
        _time = moment(time)
    } else if (moment.isMoment(time)) {
        _time = time
    } else if (typeof time === 'string') {
        _time = moment(time, 'YYYY-MM-DD')
    } else
        _time = moment(time)
    return axios.post(API_TIME, {'arrive_date': _time.format('YYYY-MM-DD')}).then(res => {
        return Promise.resolve(res);
    }).catch(err => {
        error_handler(err);
        return Promise.reject(err);
    })
}

function resetCurrentTime() {
    return axios.delete(API_TIME)
}

function checkTimeTask(task_id) {
    return axios.get(API_TIME, {params: {'task_id': task_id}}).then(res => {
        return Promise.resolve(res);
    }).catch(err => {
        error_handler(err);
        return Promise.reject(err);
    })
}

function initialization() {
    return axios.get('/api/server').then(res => {
        return Promise.resolve(res);
    }).catch(err => {
        // error_handler(err);
        return Promise.reject(err);
    })

}

export {
    SettingService,
    getCurrentTime,
    setCurrentTime,
    resetCurrentTime,
    checkTimeTask,
    breakingSandbox,
    initialization
}
