import React, {useState, useEffect} from "react";
import {Form, Input, Select, Tag} from 'antd';
import {TagService} from "../../../API/TagsAPI";

export default function TagForm({form,...props}){
  const [colors, setColors] = useState([])

  useEffect(() => {
    let t = new TagService();
    t.getColors().then(res => {
      setColors(res.data.data);
    })
  }, [])

  return <Form layout='vertical' form={form} {...props} >
    <Form.Item name='name' label='Наименование' rules={[
      {required: true, message: 'Это поле необходимо заполнить'}
    ]}>
      <Input type='text' />
    </Form.Item>
    <Form.Item name='color' label='Цвет' rules={[ { required: false } ]}>
      <Select>
        {colors.map(el => {
          return <Select.Option key={el.id} value={el.code}><Tag color={el.code}>{el.name}</Tag></Select.Option>
        })}
      </Select>
    </Form.Item>
  </Form>
}
