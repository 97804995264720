import React, {useCallback, useEffect, useState} from "react";
import {useEffectOnce} from "react-use";
import PropTypes from "prop-types";
import {Col, Descriptions, Form, Input, InputNumber, Row, Select} from "antd";
import {AtolPayService} from "src/API/AtolAPI";
import useAccount from "src/Providers/AccountProvider";
import useIntegration from 'src/Providers/IntegrationsProvider'
import useContragent from '../../Contragents/ContragentContext';
import { AccountService } from "src/API/AccountAPI";


export default function PayUpForm({onFinish, ...props}) {

    const [form] = Form.useForm();
    const {account, toggle} = useAccount();
    const {atol} = useIntegration()
    const {contragent} = useContragent();

    // const [taxDisabled, toggle] = useToggle(true);

    const [stageVisible, setStageVisible] = useState(false);

    // const [accounts, setAccounts] = useState([]);
    const [payType, setPayType] = useState([]);

    const [info, setInfo] = useState({
        amount: 0,
        tax: 0,
        taxCalculated: 0,
        total: 0,
    });

    const computeInfoChange = useCallback(() => {
        // Меняет общую информацию
        let amount, total;

        amount = isNaN(+form.getFieldValue("amount"))
          ? 0
          : +form.getFieldValue("amount");
        total = amount;

        setInfo({
            amount: amount,
            tax: 0,
            taxCalculated: 0,
            total: total,
        });
    }, [form])

    useEffectOnce(() => {
        new AccountService(contragent?.id).paytypes().then((res) => {
            let pay_type_list = res.data.data.filter(el => {
                return el.type === 3
            })
            setPayType(pay_type_list);
        });
        form.setFields([{name: "procent", value: 20}]);
        setStageVisible(true)
    });

    useEffect(() => {
        computeInfoChange();
    }, [computeInfoChange]);

    let negativeAmountValidator = (rule, value) => {
        return +value < 0 ? Promise.reject() : Promise.resolve();
    }

    let zeroAmountValidator = (rule, value) => {
        return +value === 0 ? Promise.reject() : Promise.resolve();
    }

    function onSendData(data) {
        data = {
            type: 3,
            paytype: data.paytype,
            number: data.number,
            amount: info.amount,
            to_number: account?.number,
            procent: 0,
            tax: 0,
            total: info.total,
            comment: data.comment,
        }
        const ptype = payType.find(pt => pt.id === data.paytype)
        if (atol && ptype.name === 'АТОЛ Пэй') {
            data['contragent'] = contragent.external_id
            new AtolPayService().payUp(data).then( res => {
                typeof onFinish === 'function' && onFinish();
            }).finally(() => { //catch + error_handler уже и так есть внутри метода
                toggle();
            })
        }
        else {
            new AccountService(contragent?.id, account?.number).topup(data).then(res => {
                typeof onFinish === 'function' && onFinish();
            }).finally(() => { //catch + error_handler уже и так есть внутри метода
                toggle();
            })
        }
    }

    return (
      <Form {...props} layout="vertical" form={form} onFinish={onSendData}>
          <Form.Item
            name="paytype"
            label="тип пополнения"
            rules={[{required: true, message: "поле обязательно к заполнению"}]}
          >
              <Select>
                  {payType.map((type) => {
                      return <Select.Option key={type.id} value={type.id}>{type.name}</Select.Option>;
                  })}
              </Select>
          </Form.Item>

          {!account?.number ? (
            <Form.Item
              name="number"
              label="счет пополнения"
              rules={[
                  {required: true, message: "необходимо выбрать счёт пополнения"},
              ]}
            >
            </Form.Item>
          ) : (
            <Descriptions bordered size="small" style={{marginBottom: "1em"}}>
                <Descriptions.Item label="Счёт пополнения">
                    {account?.number}
                </Descriptions.Item>
            </Descriptions>
          )}

          {stageVisible && (
            <>
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item
                          name="amount"
                          label="сумма"
                          required
                          rules={[
                              {required: true, message: "должна быть указана сумма"},
                              {
                                  validator: negativeAmountValidator,
                                  message: "сумма не может быть отрицательной",
                              },
                              {
                                  validator: zeroAmountValidator,
                                  message: "сумма не может быть равной нулю",
                              },
                          ]}
                        >
                            <InputNumber
                              onChange={computeInfoChange}
                              style={{width: "100%"}}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="comment" label="комментарий">
                    <Input.TextArea/>
                </Form.Item>

                <Descriptions
                  title="Предварительный расчёт"
                  size="small"
                  bordered
                  column={2}
                >
                    <Descriptions.Item label="Итого">{info.total}</Descriptions.Item>
                </Descriptions>
            </>
          )}
      </Form>
    );
}

PayUpForm.propTypes = {
    number: PropTypes.string,
    onFinish: PropTypes.func.isRequired,
};
