import {useForm} from 'antd/es/form/Form'
import React from 'react'

import ContractRequestForm from '../Forms/ContractRequestForm'
import Modal from '../../../Base/Modals/Modal'

export default function ContractRequestModal(props) {
    const [form] = useForm()

    return (
        <Modal
            btnname='OK'
            onSuccess={() => form.submit()}
            title=' Запросить договор'
            {...props}
        >
            <ContractRequestForm form={form} setIsModal={props.setIsModalR}/>
        </Modal>
    )
}
