import React from "react";
import { Layout } from "antd";
import { Scrollbars } from 'react-custom-scrollbars';
import styles from './Layout.module.less';


export default function Content({children}){
    return <Layout.Content className={styles.content__layout}>
        <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
        >
            {children}
        </Scrollbars>
    </Layout.Content>
}
