import React, {useContext, useMemo, useState} from "react";
import {useToggle} from "react-use";


const OrderContext = React.createContext();


export function OrderProvider({children}) {
    // Контекст хранилища по работе с заказом

    const [order, setOrder] = useState(null);
    const [update, toggle] = useToggle(false);

    const memoedValue = useMemo(
        () => ({
            order,
            update,
            toggle,
            setOrder,
        }),
        [order, update]
    );

    return (
        <OrderContext.Provider value={memoedValue}>
            {children}
        </OrderContext.Provider>
    );
}

export function useOrder() {
    return useContext(OrderContext);
}
