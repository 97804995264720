import {DeleteOutlined, MenuOutlined} from "@ant-design/icons";
import {Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Space, Transfer} from "antd";
import {useFable} from "app/Base/Tables/Fable/Fable";
import {usePreFable} from "app/Base/Tables/Fable/FableModal";
import {EXPRESSIONS, T_BOOL, T_CHOICE, T_DATE, T_DATETIME, T_INT, T_STR} from "./const";
import moment from "moment";
import {useEffect, useRef, useState} from "react";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {useDebounce, useList} from "react-use";
// import {useColumns, useFilters, useFlexible} from "./FlexibleTable";
import {ColumnFactory, Filter} from "./Types";


const Option = Select.Option;


function ControlsFactory({column, filter, val, onChange}) {
    const style = {
        minWidth: 300,
    };

    useEffect(() => {
        if (!val) {
            switch (filter?.type) {
                case T_INT:
                    onChange(0);
                    break;
                case T_STR:
                    onChange("");
                    break;
                case T_BOOL:
                    onChange(false);
                    break;
                case T_DATE:
                    if (val)
                        onChange(moment(val).toDate?.());
                    else
                        onChange(moment().toDate());
                    break;
                case T_DATETIME:
                    if (val)
                        onChange(moment(val).toDate?.());
                    else
                        onChange(moment().toDate());
                    break;
                case T_CHOICE:
                    onChange(filter?.choices?.[0]?.[0]);
                    break;
            }
        }
    }, [filter]);

    function _onChange_input(e) {
        return onChange(e?.target?.value);
    }

    function _onChange_number_input(val) {
        return onChange(val);
    }

    function _onChange_datetime(mmnt) {
        return onChange(mmnt?.toDate?.() || mmnt);
    }

    function _onChange_date(mmnt) {
        return onChange(mmnt.format("YYYY-MM-DD"));
    }

    function _onChange_checkbox(value) {
        return onChange(value);
    }

    function _onChange_select(value) {
        return onChange(value);
    }

    switch (filter?.type) {
        case T_INT:
            return <InputNumber style={style}
                                onChange={_onChange_number_input}
                                value={val}/>;
        case T_BOOL:
            return <Checkbox onChange={_onChange_checkbox} checked={!!val}/>;
        case T_CHOICE:
            return <Select style={style}
                           onChange={_onChange_select}
                           value={val}>
                {filter.choices
                       ?.map(([val, display]) => (
                           <Option value={val}>
                               {display}
                           </Option>
                       ))}
            </Select>;
        case T_DATE:
            return <DatePicker style={style}
                               onChange={_onChange_date}
                               onSelect={_onChange_date}
                               value={moment(val)}/>;
        case T_DATETIME:
            return <DatePicker style={style}
                               onChange={_onChange_datetime}
                               onSelect={_onChange_datetime}
                               value={moment(val)}
                               showTime/>;
        default:
            return <Input style={style}
                          onChange={_onChange_input}
                          value={val}/>;
    }
}


export function FilterRow({filter, index}) {
    const {model} = useFable();
    const {removePrefilter, updatePrefilter} = usePreFable();
    const metadata = model.options.filters || [];

    const filter_fields = metadata.map(x =>
            ({field: x.field,
                label: x.label,
                type: x.type,
                choices: x.choices
            }))
        .reduce((a, b) => {
            if (!a.find(x => x.field == b.field))
                a.push(b);
            return a;
        }, []);

    const types_map = Object.fromEntries(filter_fields.map(x => [x.field, {type: x.type, choices: x.choices}]));

    const opers = metadata
        .filter(x => x.field == filter.column)
        .map(x => EXPRESSIONS[x.expr])
        .reduce((a, b) => {
            if (!a.find(x => x == b))
                a.push(b);
            return a;
        }, []);

    function setFilter(colname) {
        if (colname != filter.column) {
            let flt = {
                ...filter,
                column: colname,
                ...(types_map[colname])
            };
            updatePrefilter(flt, index);
        }
    }

    function setOper(oper) {
        if (oper != filter.operator) {
            let flt = {...filter, operator: oper};
            updatePrefilter(flt, index);
        }
    }

    function setValue(value) {
        if (value != filter.value) {
            let flt = {...filter, value: value};
            updatePrefilter(flt, index);
        }
    }

    return <Space>
        <Select onChange={setFilter}
                value={filter.column}
                style={{width: 200}}>
            {filter_fields.map(
                x => <Select.Option value={x.field}>
                    {x.label}
                </Select.Option>,
            )}
        </Select>
        <Select onChange={setOper} value={filter.operator} style={{width: 200}}>
            <Select.Option value={null}> </Select.Option>
            {
                opers.map?.(op => <Select.Option value={op}>{op}</Select.Option>)
            }
        </Select>
        <ControlsFactory val={filter.value}
                         onChange={setValue}
                         filter={filter}/>
        <Button icon={<DeleteOutlined/>}
                onClick={() => {
                    removePrefilter(filter);
                }}/>
    </Space>;
}


export function RowOrderBy() {
    const {model, columns} = useFable();
    const {options} = model;
    const precs = columns.map(x => x.name);
    const order_cols = options.columns.filter(x => precs.includes(x.key));
    const {preorder, setPreorder} = usePreFable();

    function onChange(value) {
        setPreorder({...preorder, column: value});
    }

    return <Form.Item label={"Сортировка"}>
        <Row>
            <Select value={preorder?.column} style={{width: 400}}
                    onChange={onChange}
                    disabled={!order_cols?.length}>
                {order_cols.map(x =>
                    <Select.Option value={x.key}> {x.title} </Select.Option>,
                )}
            </Select>
        </Row><Row>
        <Radio.Group onChange={e => setPreorder({...preorder, asc: e.target.value})}
                     style={{width: 400}}
                     value={preorder?.asc}
                     disabled={!order_cols?.length}>
            <Col><Radio value={true}>По возрастанию</Radio></Col>
            <Col><Radio value={false}>По убыванию</Radio></Col>
        </Radio.Group>
    </Row>
    </Form.Item>;
}

export function Pagination() {
    const {prelimit, setPrelimit} = usePreFable();

    return <>
        <Form.Item name={"limit"}
                   label={"Пагинация"}>
            <Select options={[10, 20, 50, 100].map(x => ({label: x.toString(), value: x}))}
                    value={{label: prelimit.toString(), value: prelimit}}
                    onChange={setPrelimit}
                    style={{width: 200}}
            />
        </Form.Item>
    </>;
}

export function TransferDragItem({item, moveRow}) {
    const ref = useRef(null);
    const index = item.key;

    const [{isOver, dropClassName}, drop] = useDrop({
        accept: "columns",
        collect: (monitor) => {
            const {index: dragIndex} = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName:
                    dragIndex < index ? ` drop-over-downward` : ` drop-over-upward`,
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        },
    });

    const [{isDragging}, drag, preview] = useDrag({
        type: "columns",
        item: {index},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    preview(drop(ref));

    return (
        <div
            key={item.id}
            ref={ref}
            className={`${isOver ? dropClassName : ""}`}
        >
            <Row className="label" ref={drag} justify="space-between">
                {item.title || item.title}
                {index !== -1 && (<MenuOutlined/>)}
            </Row>
        </div>
    );
}


export function TransferDnD() {
    const {model} = useFable();
    const metadata = model.options.columns;
    const [sldKeys, {set: sldset}] = useList([]);

    const {precolumns, setPrecolumns} = usePreFable();
    const trgKeys = precolumns.map(x => x.name);

    function onChange(keys) {
        setPrecolumns(metadata
            .map(x => new ColumnFactory(metadata).Column(x))
            .filter(x => keys.includes(x.key)));
    }

    function moveRow(dragIndex, hoverIndex) {
        const
            i = trgKeys.indexOf(dragIndex),
            j = trgKeys.indexOf(hoverIndex);
        const col = precolumns[i];
        let precs = [...precolumns];

        precs.splice(i, 1);
        precs.splice(j, 0, col);
        setPrecolumns(precs);
    }

    function filterOption(value, option) {
        return option.title.includes(value);
    }

    return <div>
        <DndProvider backend={HTML5Backend}>
            <Transfer
                dataSource={metadata}
                showSearch
                filterOption={filterOption}
                titles={["Доступные поля", "Поля на вывод"]}
                targetKeys={trgKeys}
                selectedKeys={sldKeys}
                onChange={onChange}
                onSelectChange={(sKeys, tKeys) => sldset([...tKeys, ...sKeys])}
                render={item => (
                    <TransferDragItem item={item}
                                      moveRow={moveRow}/>)}
                listStyle={{
                    width: "100%",
                    height: 350,
                }}/>
        </DndProvider>
    </div>;
}


