import React, {useState, useEffect} from "react";
import {Button, Card, Layout, Dropdown, Menu, Table, Typography, Tag, message} from "antd";
import {Route, Switch} from "react-router";
import {DiscountModal} from "./Modal/DiscountModal";
import DiscountService from "src/API/Discount";
import useUser from "src/Providers/UserProvider";
import {MoreOutlined} from "@ant-design/icons";
import {DateTime} from "../Common/OtherComponents";
import Money from "../Accounts/utils/Money";


export default function DiscountRouter() {
    // Раздел скидок

    const [discountModal, setDiscountModal] = useState({visible: false});
    const [discounts, setDiscounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);

    const {contragent} = useUser();

    useEffect(() => {
        setLoading(true);
        new DiscountService(contragent.id).list().then(res => {
            setDiscounts(res.data.data);
            setLoading(false);
        });
    }, [update]);

    function menu(record) {
        return <Menu>
            <Menu.Item key="edit" onClick={() => {
                setDiscountModal({visible: true, item: record});
            }}>Редактировать</Menu.Item>
            <Menu.Item danger key="delete" onClick={() => {
                new DiscountService(contragent.id, record.id).delete().then(res => {
                    message.success("Скидка удалена");
                    setUpdate(p => !p);
                });
            }}>Удалить</Menu.Item>
        </Menu>;
    }

    const columns = [
        {
            title: "Действия", width: 100, dataIndex: "action", render: (_, record) => (
                <Dropdown
                    overlay={() => menu(record)}
                    trigger={["click"]}
                    placement="bottomRight"
                >
                    <Button size="small" icon={<MoreOutlined/>}/>
                </Dropdown>
            ),
        },
        {
            title: "id", dataIndex: "id", key: "id",
        },
        {
            title: "Создан", dataIndex: "created", key: "created", render: (val) => {
                return <DateTime dt={val} dateOnly/>;
            },
        },
        {
            title: "Наименование", dataIndex: "name", key: "name",
        },
        {
            title: "Статус", dataIndex: "is_avaivable", key: "is_avaivable",
            render: (val) => {
                return val ? <Tag color="green">Активен</Tag> : <Tag color="grey">Неактивен</Tag>;
            },
        },
        {
            title: "Скидка", dataIndex: "type_pay", key: "type_pay",
            render: (val, record) => {
                switch (val) {
                    case "percent":
                        return `${record.value}% на ${record.offer ? "позицию" : "заказ"}`;
                    case "money":
                        return <><Money sum={record.value}/>
                            <Typography>на {record.offer ? "позицию" : "заказ"}</Typography></>;
                    default:
                        return "N/a";
                }
            },
        },
        {
            title: "Дата начала", dataIndex: "date_start", key: "date_start",
            render: (val) => {
                return val ? <DateTime dt={val} dateOnly/> : null;
            },
        },
        {
            title: "Дата окончания", dataIndex: "date_end", key: "date_end",
            render: (val) => {
                return val ? <DateTime dt={val} dateOnly/> : null;
            },
        },
        // {
        //     title: "Статус",
        //     dataIndex: "status",
        //     key: "status",
        //     render: (val, obj) => {
        //         if (val == 'active')
        //             return <Tag color='green'>Активна</Tag>
        //         if (val == 'inactive')
        //             return <Tag color='gray'>Неактивна</Tag>
        //         if (val == 'postponed')
        //             return <Tag color='blue'>Отложена</Tag>
        //     }
        // },
    ];

    return (
        <Card title="Модификаторы цен"
              extra={<Button onClick={() => setDiscountModal({visible: true})}>Создать</Button>}>
            <Table
                size="small"
                dataSource={discounts}
                columns={columns}
                loading={loading}
                rowKey="id"
            />
            <DiscountModal
                visible={discountModal?.visible}
                item={discountModal?.item}
                onCancel={() => {
                    setDiscountModal({visible: false});
                    setUpdate(p => !p);
                }}
            />
        </Card>
    );

    // после того, как будет слита с веткой отрефакторенным апи, здесь надо будет поправить
    return <Layout.Content>
        <Switch>
            <Route path="/contragents/:contragent_id/discount/:id">

            </Route>
            <Route path="/contragents/:contragent_id/discount">

            </Route>
        </Switch>
    </Layout.Content>;
}
