import React from "react";

import useUser from "../Providers/UserProvider";

export default function Permission({children, global, local, ...props}) {
    const {is_global_permitted, is_local_permitted} = useUser();

    return  is_global_permitted(global) ||
            is_local_permitted(local) ?
        children : null;
}

export function SimplPerm({children, perms, ...props}) {
    const {is_permitted} = useUser();

    return  is_permitted(perms) ? children : null;
}



// export function AdminPermission({children}) {
//     const perms = [
//         "create_edit_tarif_package", "read_invoices", "edit_tarif_access", "prepayment_account", "read_oper_history_account",
//         "setup_tarif_params", "read_active_subscribes_and_counters", "create_order_for_period", "download_doc_files", "read_account_details",
//         "annul_invoice", "create_edit_bank_requisits", "read_tarif_counter", "bind_subacc_1C_acc_order", "read_orders_paid_invoices",
//         "setup_tarif_package_visibility", "edit_subscribe_status", "read_notify_templates", "read_billing_operations", "disable_subscribe",
//         "setup_user_permissions", "edit_processed_operation", "setup_periods_and_conditions_tarifs", "create_closing_docs",
//         "bind_costs_item_to_contragent_user", "create_edit_costs_item", "create_edit_notify_templates", "bind_subscribe_to_subacc",
//         "create_edit_doc_numerators", "run_and_process_in_different_modes", "setup_bind_tarif_costitem", "import_payments_info",
//         "edit_subacc_params", "create_edit_tarif_counter", "read_rule", "read_closing_docs_for_contragent", "setup_rules_notify_send",
//         "create_edit_tarif_calculator", "setup_calc_rules_of_outrealize_accruals", "lookup_contragents", "change_tarif_status",
//         "create_edit_report_period", "setup_loyalty", "contragent.edit", "edit_bind_subacc_1C_contract", "edit_subacc_status",
//         "create_edit_doc_templaltes", "watch_status", "mass_docs_print_for_send", "read_tarif_calculator", "read_blocked_accounts",
//         "read_tarif_package", "read_costs_items", "read_tarif", "bind_tarif_to_report_period", "edit_account_status", "create_edit_subacc",
//         "create_edit_rule", "read_subacc_oper_history", "manual_bind_unknown_1c_payments", "bind_subscribe_to_period",
//         "connect_tarif_and_packages_to_calculator", "contragent.create"
//     ]

//     return Permission({
//             global: perms,
//             local: perms,
//             children
//         })
// }


// export function TestMode({children}) {
//     const {stand} = useUser()
//     return ((stand === 'test') ? <>{children}</> : null)
// }
