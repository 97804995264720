import { Layout } from 'antd'
import { Route, Switch } from 'react-router'
import React from 'react'

import TemplateTable from './Tables/TemplateTable'
import TemplateCard from './Cards/TemplateCard'
import { TemplateProvider } from '../../Providers/TemplateProvider';

export default function Template () {
  return (
    <Layout.Content>
      <Switch>
        <Route exact path='/templates/create'>
          <TemplateProvider>
            <TemplateCard />
          </TemplateProvider>
        </Route>
        <Route exact path='/templates/:id'>
          <TemplateProvider>
            <TemplateCard />
          </TemplateProvider>
        </Route>
        <Route exact path='/templates'>
          <TemplateTable />
        </Route>
      </Switch>
    </Layout.Content>
  )
}
