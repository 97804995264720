import {MoreOutlined, ReloadOutlined} from "@ant-design/icons";
import {
    Button,
    Checkbox,
    Col,
    Dropdown,
    Layout,
    List,
    Menu,
    notification,
    Row,
    Table,
    Tabs,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import {AtolRevenueService} from "app/API/AtolAPI";
import RevenueService from "app/API/RevenueAPI";
import {Fable} from "app/Base/Tables/Fable/Fable";
import {useRevenueData} from "app/Data/Base";
import {sleep} from "app/lib/tools";
import useIntegration from "app/Providers/IntegrationsProvider";
import React, {useEffect, useState} from "react";
import {Link as ToLink} from "react-router-dom";
import {useToggle} from "react-use";
import {downloadDocument} from "src/API/BaseAPI";
import Money from "../../Accounts/utils/Money";
import {DateTime} from "../../Common/OtherComponents";
import RevenueCard from "../Cards/RevenueCard";
import useContragent from "../ContragentContext";


export const UNJOINED = "unjoined";

const Link = Typography.Link;

const UNJOINED_REVENUE = {
    "name": "Свободные",
    "status": UNJOINED,
    "contract": null,
    "id": null,
    "order": null,
    "invoice": null,
    "amount": 0,
    "status_name": "Необъединённые",
    "date": null,
    "time": null,
    "number": "Необъединённые",
    "items": [],
};


function Docs({revenue}) {
    const {atol} = useIntegration();
    const [docs, setDocs] = useState([]);
    const [visible, setVisible] = useState(false);

    function on_click() {
        if (!visible)
            new AtolRevenueService(revenue.id).get_documents().then(res => {
                setDocs(res.data.data);
                setVisible(true);
            });
        else
            setVisible(false);
    }

    if (atol)
        return <Dropdown overlay={(docs.length > 0) ?
            <List dataSource={docs}
                  itemLayout={"vertical"}
                  size={"small"}
                  style={{paddingRight: 20}}
                  split={true}
                  renderItem={item => (
                      <List.Item>
                          {/*<Link href={`${process.env.REACT_APP_BASE_URL||""}${item.url}`}*/}
                          {/*      target={"_blank"}>*/}
                          <Link onClick={() => downloadDocument(item.url)}>
                              {item.filename}
                          </Link>
                      </List.Item>
                  )}/> :
            <Layout>
                <Row>
                    <b>Документов нет</b>
                </Row><Row>
                ПО этой реализации печатные формы отсутствуют
            </Row>
            </Layout>}
                         visible={visible}
                         trigger={["click"]}>
            <Button size={"small"}
                    icon={<MoreOutlined/>}
                    onBlur={() => sleep(200).then(x => setVisible(false))}
                    onClick={on_click}/>
        </Dropdown>;
    else
        return "--";

    // return <Link onClick={() => new AtolRevenueService(revenue.id).get_documents()}>
    //     Документы {revenue.name}
    // </Link>;
}

export default function RevenueTable({doctype, ...props}) {
    const [corrections, setCorrections] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [revenue, setRevenue] = useState(null);
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refresh, tglRefresh] = useToggle(false);
    const {contragent} = useContragent();
    const model = useRevenueData({contragent});
    const {toggleTableUpdate} = model;

    function render_revenue(text, record) {
        return <Typography.Link onClick={() => {
            if (record.status !== UNJOINED) {
                new RevenueService(record.id).retrieve().then(res => {
                    setRevenue(res.data);
                    setIsModal(true);
                }).catch(r => RevenueService.error_handler(r, "Не удалось отобразить реализацию"));
            }
        }}>
            {text}
        </Typography.Link>;
    }

    function realizeRecord(record) {

        new RevenueService(record.id).realize().finally(() => {
            toggleTableUpdate();
        });
    }

    function sendAgain(record) {
        new RevenueService(record.id).send_again().finally(() => {
            toggleTableUpdate();
            notification.success({
                message: "Повторная отправка документа",
                description: "Документ помещён в очередь на отправку повторно. Ожидайте результата"
            })
        })
    }

    function construct_status(record) {
        if (record.is_deleted)
            return <Tag color={'grey'}>Удалено</Tag>;
        if (record.is_correction)
            return <Tag color={'blue'}>Корректировка</Tag>;
        switch (record.status) {
            case 'active':
                return <Tag color={'green'}>{record.status_name}</Tag>;
            case 'problem':
                return <Tooltip title={record.status_message}>
                    <Tag color={'volcano'}>{record.status_name}</Tag>
                </Tooltip>;
            case 'send_error':
                return <Tooltip title={record.status_message}>
                    <Tag color={'red'}>{record.status_name}</Tag>
                </Tooltip>;
            case 'template':
                return <Tooltip title={record.queue_info}>
                    <Tag>{record.status_name}</Tag>
                </Tooltip>;
            case 'incorrect':
                return <Tag color={'yellow'}>{record.status_name}</Tag>;
            default:
                return <Tag>{record.status_name}</Tag>;
        }
    }

    const columns = [
        // {title: "№", dataIndex: "id", width: 50},
        {
            title: "Наименование",
            hidden: true,
            dataIndex: "name",
            render: render_revenue,
        }, {
            title: "Номер", dataIndex: "number",
            render: render_revenue,
        }, {
            title: "Дата", dataIndex: "date", render: (dt) => <DateTime dt={dt} dateOnly/>,
        }, {
            title: "Контрагент", dataIndex: "contragent_name", render: (cname, rec) => (
                <ToLink to={`/contragents/${rec.contragent}`}>
                    {cname}
                </ToLink>
            )
        }, {
            title: "Сумма", dataIndex: "amount",
            align: "right",
            render: sum => <Money sum={parseFloat(sum)}/>,
        }, {
            title: "НДС", dataIndex: "tax",
            align: "right",
            render: sum => <Money sum={parseFloat(sum)}/>
        }, {
            title: "Печатные формы", dataIndex: "bill", key: "bill", render:
                (val, rec) => <Docs revenue={rec}/>,
        },
        {title: "Статус", dataIndex: "status", render: (val, rec) => construct_status(rec)},
    ].filter(x => !x.hidden);

    return (isModal ?
            <RevenueCard revenue={revenue} setDetailView={setIsModal}/> :
            <Tabs>
                <Tabs.TabPane tab={"Реализации"} key={1}>
                    <Fable columns={columns}
                                   actions={(rec) => (
                                       <Menu>
                                       <Menu.Item
                                               disabled={rec?.status === "active"}
                                               onClick={() => realizeRecord(rec)}>
                                           Реализовать
                                       </Menu.Item>
                                       <Menu.Item
                                               disabled={rec?.status === "active"}
                                               onClick={() => sendAgain(rec)}>
                                           Отправить повторно
                                       </Menu.Item>
                                       </Menu>)}
                                   model={model}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={UNJOINED_REVENUE.name} key={2}>
                    <RevenueCard revenue={UNJOINED_REVENUE}
                                 setDetailView={setIsModal}
                                 refresh={refresh}
                                 tglRefresh={tglRefresh}
                                 status={UNJOINED}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={"Корректировки"} key={3}>
                    <Table columns={columns}
                           dataSource={corrections}
                           rowKey="id"
                           size="small"
                           loading={loading}
                           title={() => (
                               <Row justify="space-between"><Col/>
                                   <Col><Button icon={<ReloadOutlined/>}
                                                onClick={() => setUpdate(p => !p)}/></Col>
                               </Row>
                           )}
                           {...props}/>
                </Tabs.TabPane>
            </Tabs>
    );
}


export function RevenueDetailTable({revenue, ids, setIds, status, refresh, ...props}) {
    const [items, setItems] = useState([]);
    const {contragent} = useContragent();

    function setData(data) {
        setItems(data.reduce((a, b) => {
                if (data.find(x => x.id == b.parent?.id) || !b.parent)
                    return a.concat([b]);
                if (b.parent)
                    return a.concat([b, b.parent]);
            }, []).sort((a, b) => {
                if (a.parent && b.parent || !a.parent && !b.parent)
                    return a.id - b.id;
                if (a.parent && !b.parent)
                    return b.id - a.parent.id + 1;
                if (!a.parent && b.parent)
                    return b.parent.id - a.id - 1;
            }));
    }

    useEffect(() => {
        if (revenue.status === status) {
            new RevenueService().get_items(contragent.id).then(r => {
                setData(r.data.data);
            });
        } else {
            setData(revenue.items);
        }
    }, [refresh]);

    const columns = [
        {title: "", width: 50, render: (_, record) => {
                return <Checkbox onChange={x => {
                    if (x.target.checked) {
                        ids.push(record.id);
                        setIds(ids);
                    } else {
                        setIds(ids.filter(x => x.id === record.id));
                    }
                }}/>;
            }, unjoined: true},
        {title: "", dataIndex: "kind", width: 100, render: (val, rec) => (
                (rec.parent) ?
                    <Tag color={"green"}>После</Tag> :
                    <Tag color={"red"}>До</Tag>),
            correction: true},
        {title: "№", dataIndex: "id", width: 50},
        {title: "Продукт", dataIndex: "product_name"},
        {title: "Описание", dataIndex: "text"},
        {title: "Потребитель", dataIndex: "contragent_name"},
        {title: "Количество", dataIndex: "count", key: "count", align: "right"},
        {title: "Ед.изм", dataIndex: "measure_name"},
        {title: "Цена", dataIndex: "price", align: "right", render: (value) => <Money sum={value}/>},
        {title: "НДС", dataIndex: "tax", align: "right", render: (value) => <Money sum={value}/>},
        {title: "Сумма", dataIndex: "amount", align: "right", render: (value) => <Money sum={value}/>},
        // {title: 'Время создания', dataIndex: 'created', render: value => moment(value).format("DD.MM.YYYY HH:mm:ss")},
    ].filter(x => (x.unjoined && (revenue.status === status)) || !x.unjoined)
     .filter(x => x.correction == revenue.is_correction || !x.correction);

    return <Table columns={columns}
                  dataSource={items}
                  rowKey={"id"}
                  size={"small"}
                  pagination={false}
                  {...props}
        // expandable={{
        //     expandedRowRender: (item) => {
        //         if (item.parent)
        //             return <ExpandableReveueItemOriginal data={item.parent}
        //                                                  columns={columns.filter(x => !x.unjoined)}/>
        //         else if (item.corrections && item.corrections?.length)
        //             return <ExpandableRevenueItemCorrections data={item.corrections}
        //                                                      columns={columns.filter(x => !x.unjoined)}/>
        //
        //     },
        //     rowExpandable: item => !!item.parent || (item.corrections?.length)
        // }}
    />;
}
