import React, { useEffect, useState } from "react";
import { ForwardOutlined } from "@ant-design/icons";
import { Result, Typography, Progress, Tag, Space, Badge } from "antd";
import useSystemInfo from "../../Providers/SystemProvider";
import moment from 'moment';
import './style.css';


export default function Loader({children, visible}){
    const {system, sandbox} = useSystemInfo();
    const [percent, setPercent] = useState(0);

    useEffect(() => {
      let perc = ((sandbox?.current_date - sandbox?.begin_date) * 100) / (sandbox?.arrive_date - sandbox?.begin_date)
      setPercent(perc)
    }, [sandbox])

    return (
      visible && <div className="sk-cube-container">
        <Result 
          title="Машина времени в процессе"
          extra={
            <>
              <Space>
                <Tag color="blue">
                  {sandbox?.begin_date?.toLocaleDateString()}
                </Tag>
                <ForwardOutlined />
                <Tag>
                  {sandbox?.current_date?.toLocaleDateString()}
                </Tag>
                <ForwardOutlined />
                <Tag color="green">
                  {sandbox?.arrive_date?.toLocaleDateString()}
                </Tag>
              </Space>
              <Progress percent={parseFloat(percent).toFixed(2)} status="active" />
            </>
          }
        />
    </div>
    )
}
