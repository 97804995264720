import React from "react";
import {Layout, Space, Typography, Row, Col, Divider} from "antd";
import useSystemInfo from "../../Providers/SystemProvider";


export default function Footer(){

    const {system} = useSystemInfo();
    // const {version} = useUser();

    return (
        <Layout.Footer style={{background: 'transparent', padding: '10px 25px'}}>
            <Row justify='center'>
                <Col>
                    <Space justify='center' gutter={16} split={<Divider type='vertical' />}>
                        {/* <Col> */}
                            <Typography.Link type='secondary' underline style={{fontSize: '10px'}}>Политика обработки персональных данных</Typography.Link>
                            <Typography.Link type='secondary' underline style={{fontSize: '10px'}}>Согласие на обработку персональных данных</Typography.Link>
                        {/* </Col> */}
                        {/* <Col> */}
                            {/* <Divider type="vertical"/> */}
                            {system?.versions && 
                                <Space split={<Divider type='vertical' />}>
                                    {system?.versions.map(el => (
                                        <Typography.Text type="secondary" style={{fontSize: "10px"}}>
                                            v.{el?.version}
                                        </Typography.Text>
                                    ))}
                                </Space>
                            }
                        {/* </Col> */}
                        {/* <Col> */}
                            <Typography.Text type='secondary' style={{fontSize: '10px'}}>PricePlan © {new Date().getFullYear()}</Typography.Text>
                        {/* </Col> */}
                    </Space>
                </Col>
            </Row>
        </Layout.Footer>
    )
}
