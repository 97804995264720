import React, { useState } from "react";
import { Typography } from "antd";
import PayUpModal from "../../Accounts/Modal/PayUpModal";



export default function PayUpAction({component: Component, onTransactionDone }) {
  const [visible, setVisible] = useState(false);

  let renderAction = () => {
      if(Component){
        return (
          <div onClick={() => setVisible(true)}>
            {Component}
          </div>
        )
      }
      return (
        <Typography.Text onClick={() => setVisible(true)}>
          Пополнение счёта
        </Typography.Text>
      )
  }

  return (
    <>
      {renderAction()}
      <PayUpModal
        visible={visible}
        onCancel={() => setVisible(false)}
        onSuccess={() => {
          setVisible(false);
          if(typeof onTransactionDone === 'function'){
            onTransactionDone();
          }
        }}
      />
    </>
  );
}