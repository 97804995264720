import {Button} from "antd";
import React, {useState} from "react";
import ClientModal from "../Modals/ClientModal";


export default function ContragentCreateButton({children, ...props}) {
    const [visible, setVisible] = useState(false);

    return <>
        <Button {...props} onClick={() => setVisible(true)} type={"primary"}>
            {children}
        </Button>
        <ClientModal
            visible={visible} onCancel={() => setVisible(false)}
        />
    </>
}
