import Modal from "app/Base/Modals/Modal";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {Form, Button, message, Card, Typography} from "antd";

import {ProductService} from "src/API/ProductAPI";
import {DictionaryService} from "src/API/DictionaryAPI";

import useUser from "src/Providers/UserProvider";

import ProductForm from "../Forms/ProductForm";
import AttributesForm from "src/Components/Common/Forms/AttributesForm";


export function ProductModal({item, ...props}) {

    const [form] = Form.useForm();
    const [attrs_form] = Form.useForm();
    const {contragent} = useUser();

    const [dictionaries, setDictionaries] = useState([]);

    useEffect(() => {
        if (item) {
            form.setFieldsValue(item);
            new ProductService(contragent?.id, item?.id).attributes.get().then(res =>
                setDictionaries(res.data.data));
        } else {
            new DictionaryService().getDictsByEntity("products").then(res => {
                setDictionaries(res.data.data);
            });
        }
    }, [item]);

    const createOrUpdate = (data) => {
        data["contragent"] = contragent.id;
        data["attributes"] = attrs_form.getFieldsValue();

        if (item) {
            new ProductService(contragent?.id, item?.id).update(data).then(res => {

                message.success("продукт обновлён");
                typeof props.onCancel === "function" && props.onCancel();
            });
        } else {
            new ProductService(contragent?.id).create(data).then(res => {
                message.success("продукт создан");
                typeof props.onCancel === "function" && props.onCancel();
            });
        }
    };

    return (
        <Modal id="create_or_update_product"
               title={item ? "Изменить продукт" : "Создать продукт"}
               btnname={item ? "Изменить" : "Сохранить"}
               {...props}>
            <ProductForm id="create_or_update_product"
                         form={form}
                         onFinish={(d) => createOrUpdate(d)}
                         edit={!!item}/>

            {!dictionaries.length &&
                <Typography>Расширьте информацию в <Link to="/dictionary">справочнике</Link></Typography>}
            {dictionaries.map(el => {
                return <AttributesForm id={el.codename}
                                       attributes={el.attributes}
                                       form={attrs_form}
                />;

            })}
        </Modal>
    );
}
