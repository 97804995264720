import {Button, Card, Col, notification, Row, Space, Typography} from "antd";
import {DateTime} from "app/Components/Common/OtherComponents";
import Money from "../../Accounts/utils/Money";
import React, {useState, useEffect} from "react";
import {RevenueDetailTable, UNJOINED} from "../Tables/RevenueTable";
import RevenueService from "../../../API/RevenueAPI";


const Text = Typography.Text;

export default function RevenueCard({revenue, setDetailView, status, refresh, tglRefresh, freeAmount, ...props}) {
    const [ids, setIds] = useState([]);
    const [tmp_rev,set_tmp_rev] = useState(revenue);

    function createRevenue() {
        if (ids.length > 0) {
            new RevenueService().create_from_unjoined(ids).then(r => {
                notification.success({
                    message: "Реализация создана",
                    description: "Позиции добавлены в реализацию"
                });
                setDetailView(false);
                tglRefresh(!refresh);
            }).catch(RevenueService.error_handler);
        } else {
            notification.warning({
                message: "Реализация не создана",
                description: "Не выбраны позиции для создания реализации"
            });
        }
    }

    function switchToRevenue(revenue) {
        set_tmp_rev(revenue);
    }

    return <>
        <Card title={<><Text>{status == UNJOINED ?
                        "Реализованные услуги (вне документа)" :
                        `${tmp_rev.number || tmp_rev.name} от ${tmp_rev.date}`}</Text>
                <Text style={{color: "#AAA"}}>{` uuid=(${tmp_rev.uuid})`}</Text></>}
              extra={
                  <Space>
                      {(tmp_rev.status === UNJOINED) &&
                        <Button type='primary' onClick={() => createRevenue()}>Создать документ из отмеченных</Button>}
                      {(tmp_rev.status !== UNJOINED) &&
                          <Button type='primary' onClick={() => setDetailView(false)}>Назад</Button>}
                  </Space>
              }>
            {status !== UNJOINED &&
                <Row>

                    <Col span={8}>
                        <p><b>Поставщик: </b>{tmp_rev.contract?.seller?.name}</p>
                    </Col>
                    <Col span={8}>
                        <p><b>Заказчик: </b>{tmp_rev.contract?.buyer?.name}</p>
                    </Col>
                    <Col span={8}>
                        <p><b>Статус: </b>{(tmp_rev.is_correction)?"Корректировка":tmp_rev.status_name}</p>
                    </Col>
                    <Col span={8}>
                        <p><b>Создан: </b>{tmp_rev.date}</p>
                    </Col>
                    {tmp_rev.is_correction &&
                        <Col span={8}>
                            <p><b>Родительская реализация: </b>
                                <Typography.Link onClick={() => switchToRevenue(tmp_rev.parent)}>
                                    {tmp_rev.parent?.number} ({tmp_rev.parent?.name})</Typography.Link></p>
                        </Col>}
                </Row>}
            <Row>
                <Col span={8}>
                    <p><b>Сумма: </b><Money sum={tmp_rev.amount}/></p>
                </Col>
                <Col span={8}>
                    <p><b>Время отправки: </b><DateTime dt={revenue.date_send}/></p>
                </Col>
                <Col span={8}>
                    <p><b>Время возврата: </b><DateTime dt={revenue.date_finish}/></p>
                </Col>
                {status !== UNJOINED &&
                    <Col span={16}>
                        <p><b>Договор: </b>{tmp_rev?.contract?.number} от {tmp_rev?.contract?.date}</p>
                    </Col>}
            </Row>
            <RevenueDetailTable
                ids={ids}
                setIds={setIds}
                revenue={tmp_rev}
                status={status}
                refresh={refresh}
            />
        </Card>
    </>
}
