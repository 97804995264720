import React, {useState} from "react";
import {Modal, Menu} from "antd";
import {MarketTable} from "../../Marketplace/Table/MarketTable";
import {PackageTable} from "../../Products/PackageTable";


const OFFERS = "offers";
const PACKAGES = "packages";

export function OfferModal({...props}) {
    const [option, setOption] = useState(OFFERS);

    return (
        <Modal {...props} width={"80%"} destroyOnClose={true} footer={false}>
            <Menu mode="horizontal"
                  selectedKeys={option}
                  defaultSelectedKeys={OFFERS}>
                <Menu.Item key={OFFERS} onClick={() => setOption(OFFERS)}>Предложения</Menu.Item>
                <Menu.Item key={PACKAGES} onClick={() => setOption(PACKAGES)}>Пакетные предложения</Menu.Item>
            </Menu>
            {option === OFFERS && <MarketTable/>}
            {option === PACKAGES && <PackageTable/>}
        </Modal>
    );
}

