import React from 'react';
import { Layout} from "antd";
import { Route, Switch } from "react-router";
import TreeRules from './Components/TreeRules';
import RuleCard from './Components/RuleCard';
import { ContragentProvider } from '../Contragents/ContragentContext';
import { RuleProvider } from '../../Providers/RuleProvider';

import useUser from "../../Providers/UserProvider";

export default function Rules(){

    const {contragent} = useUser();

    return <Layout>
        <Switch>
            <Route exact path='/rules'>
                <ContragentProvider initial_contragent={contragent}>
                    <RuleProvider>
                        <Layout.Sider width={300} style={{padding: '1em'}}>
                            <TreeRules />
                        </Layout.Sider>
                        <Layout>
                            <Layout.Content>
                                <RuleCard />
                            </Layout.Content>
                        </Layout>
                    </RuleProvider>
                </ContragentProvider>
            </Route>
        </Switch>
    </Layout>
}