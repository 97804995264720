import axios from "../config/axios";
import BaseService from "./BaseAPI";


function getExistUsername({username}) {
    return axios.post('/api/auth/register/exist-username', {username})
}

class UserService extends BaseService {
    // TODO: Отрефакторить. Полная срань
    MODEL = 'user'
    USER = `${this.API}/user`
    CONTRAGENTS = `${this.USER}/contragent`

    getUserList() {
        return this._get();
    }

    addUser(data) {
        return this.create(data);
    }

    modifyUser(data) {
        // return axios.patch(`${this.USERS}/${this._id || data.id}`, data)
        return this._patch(data);
    }

    getContragents() {
        return axios.get(this.CONTRAGENTS)
    }

    setActiveContragent(id) {
        return axios.post(`contragent/active`, {'contragent': id})
    }
}

export default UserService;
export {
    getExistUsername
}
