import {BillingService} from "app/API/BillingAPI";
import React, {useContext, useMemo, useState} from 'react';
import {getUserContragentAPI, getUserInfoAPI, getUserPermissionsAPI} from '../API/AuthAPI';
import {ContragentService} from "../API/ContragentAPI";
import {CartService} from "../API/OrderAPI";
import {useHistory} from 'react-router-dom';
import {checkTimeTask, resetCurrentTime, setCurrentTime, SettingService} from "../API/SysAPI";
import moment from 'moment'
import {message} from 'antd';
import {dateTime} from '../Components/Common/OtherComponents'
import {useToggle} from "react-use";
import {check_permission, convertDateTime} from "../lib/tools";
import Modal from "../Base/Modals/Modal";
import CtgSelectForm from "../Components/Auth/Forms/CtgSelectForm";


const UserContext = React.createContext()


export function UserProvider({children}) {
    // Контекст хранилища по работе с данными пользователя

    const [user, setUser] = useState({});
    const [contragent, setContragent] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [localperms, setLocalperms] = useState([]);

    const [settings, setSettings] = useState({});

    // TODO: вынести функционал машины времени за рамки этого контекста
    const [time, _setTime] = useState(null);
    const [runningTask, setRunningTask] = useState(false);
    const [runningMessage, setRunningMessage] = useState(null);
    const [time_machine, setTimeMachine] = useState(false);
    // ================================================================
    const [lastBillingDate, setLastBillingDate] = useState(null);

    let timer;
    // let long_timer;


    const [groups, setGroups] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const [bucket, setBucket] = useState({data: [], total: 0, packages: [], total_with_packages: 0});
    const [version, setVersion] = useState(null);
    const [branch, setBranch] = useState(null);
    const [merges, setMerges] = useState(null);

    const [globalUpdate, signalGlobalUpdate] = useToggle(false);

    const [viewsetsLoaded, setViewsetsLoaded] = useState(false); // грязный хак

    const history = useHistory();

    function setTime(time) {
        // TODO: вынести функционал машины времени за рамки этого контекста
        _setTime(convertDateTime(time))
    }

    function stopTimer() {
        // TODO: вынести функционал машины времени за рамки этого контекста
        if (timer)
            clearInterval(timer)
        setRunningTask(false)
    }

    function checkTimeTaskIsDone(tt_id) {
        // TODO: вынести функционал машины времени за рамки этого контекста
        checkTimeTask(tt_id).then((res) => {
            if (res.data?.is_travelling) {
                setRunningMessage(`Перемещение активно. Дата: ${dateTime(res.data?.sandbox_date, true)}`);
            } else {
                if (res.data?.status === 'FAILURE') {
                    message.error(`Перемещение прерванно. Дата: ${dateTime(res.data?.sandbox_date, true)}. Биллинг завершен с ошибкой`)
                } else {
                    message.success(`Перемещение завершено. Дата: ${dateTime(res.data?.sandbox_date, true)}`)
                }
                stopTimer();
                setTime(res.data?.sandbox_date);
                signalGlobalUpdate();
            }
        }).catch(err => {
            message.error("Возникла ошибка при перемещении времени")
        })
    }

    function applyTime(time) {
        // TODO: вынести функционал машины времени за рамки этого контекста
        setTime(time);
        setRunningTask(true);
        setCurrentTime(time).then((res) => {
            setRunningMessage(`Перемещение активно. Дата: ${dateTime(res.data?.sandbox_date, true)}`);
            timer = setInterval(() => checkTimeTaskIsDone(res.data.task_id), 2000)
        }).catch(err => {
            setRunningTask(false);
        });
    }

    function resetTime(time) {
        // TODO: вынести функционал машины времени за рамки этого контекста
        setTime(time);
        resetCurrentTime();
    }

    function getUser() {
        setLoading(true);
        const sserv = new SettingService();
        return Promise.all([
                getUserInfoAPI(),
                getUserContragentAPI(),
                getUserPermissionsAPI(),
                sserv.list(),
                sserv.getVersion(),
                BillingService.get()
            ])
            .then(([user, ctg, perms, setts, vrsn, bln_date]) => {
                // const l_setts = setts.data.data
                //                      .reduce((dict, obj) => (
                //                          {...dict, [obj.name]: obj.value}
                //                      ), {});

                setUser(user.data);
                setContragent(ctg.data);
                setPermissions(perms.data?.permissions);
                setGroups(perms.data?.groups || []);
                setLocalperms(perms.data?.locals || []);
                setSettings(setts.data);
                setTimeMachine(setts.data.time_machine || false);
                setTime(moment(setts.data.virtual_current_date || new Date(), 'YYYY-MM-DD'));
                setVersion(vrsn.data.msg);
                setBranch(vrsn.data.branch);
                setMerges(vrsn.data.merges);
                setLastBillingDate(bln_date);
                console.log("bln_date=", bln_date);
            }).catch(err => {
                setError(err.data);
            }).finally(() => setLoading(false))
    }

    function changeContragent(contragentId) {
        setLoading(true);
        if (contragentId) {
            let ctgSrv = new ContragentService(contragentId)
            ctgSrv.setCurrentContragent().then(res => {
                if (res.data) {
                    setContragent(res.data);
                    new CartService(contragentId).get_items({contragent: contragentId}).then(response => {
                        setBucket(response.data)
                    })
                    signalGlobalUpdate()
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            setContragent(null)
            history.push('/')
        }
    }

    function is_global_permitted(perm) {
        return check_permission(permissions, perm)
    }

    function is_local_permitted(perm) {
        return check_permission(localperms, perm)
    }

    function is_permitted(perm) {
        return check_permission(permissions, perm) || check_permission(localperms, perm)
    }

    function is_admin() {
        for (const grp of groups) {
            if (grp.code === 'ADMIN')
                return true;
        }
        return false;
    }

    function is_vendor(ctg=null) {
        if (ctg)
            return (['VENDOR','MAIN_OPERATOR'].includes(ctg.role.name));
        return (['VENDOR','MAIN_OPERATOR'].includes(contragent.role.name));
    }

    function is_operator(ctg=null) {
        if (ctg)
            return ctg.role.name === 'MAIN_OPERATOR'
        return contragent.role.name === 'MAIN_OPERATOR'
    }

    const values = useMemo(() => {
        return {
            //variables
            user, contragent, loading, error, permissions,
            time, time_machine, runningTask, settings,
            groups, localperms, bucket, version, branch, merges, globalUpdate,
            runningMessage, lastBillingDate, viewsetsLoaded,
            //functions
            getUser, setContragent, changeContragent,
            setTime, applyTime, resetTime, stopTimer,
            is_permitted, is_global_permitted, is_local_permitted,
            setBucket, is_admin, is_vendor,
            is_operator, signalGlobalUpdate, setViewsetsLoaded
        }
    }, [user, contragent, loading, error, permissions,
        time, time_machine, runningTask, settings,
        groups, localperms, bucket, version, branch, merges,
        globalUpdate, runningMessage, lastBillingDate, viewsetsLoaded]);

    return (
        <UserContext.Provider value={values}>
            {children}
        </UserContext.Provider>
    );
}

export default function useUser() {
    return useContext(UserContext);
}
