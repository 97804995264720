import React, { useState } from 'react'
import { Form, Input, Select } from 'antd'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useTemplate from '../../../Providers/TemplateProvider';


const required = { required: true, message: 'Обязательное поле.' }


export default function TemplateForm (props) {

  const {editorRef, setSelection} = useTemplate();
  const [type, setType] = useState('text');

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  return (
    <Form {...props}>
      <Form.Item label='Заголовок' name='title'>
        <Input type='text' />
      </Form.Item>
      <Form.Item label='Имя' name='name' rules={[required]}>
        <Input type='text' />
      </Form.Item>
      <Form.Item label='режим' initialValue={'text'}>
        <Select defaultValue={'text'} onSelect={setType}>
          {/* временно отключено из-за проблем сохранения шаблона */}
          {/* <Select.Option value='editor'>Редактор</Select.Option> */}
          <Select.Option value='text'>Текст</Select.Option>
        </Select>
      </Form.Item>
      {type === 'text' && <Input.TextArea/> && 
        <Form.Item label='шаблон' name='html' rules={[required]}>
          <Input.TextArea rows={8} />
        </Form.Item>
      }
      {type === 'editor' && 
        <Form.Item label='шаблон' name='html' rules={[required]}>
          <ReactQuill modules={modules} ref={editorRef} theme="snow" />
        </Form.Item>
      }
    </Form>
  )
}
