import axios from "app/config/axios";


class BillingService {
    MODEL = '/api/billing/time'

    static get MODEL(){
        return new BillingService().MODEL
    }

    static get() {
        return axios.get(this.MODEL).then(res => {
            return res.data.date;
        });
    }

    static run_next_day() {
        return axios.post(this.MODEL).then(res => {
            return res.data;
        });
    }

    static check_status() {
        return axios.put(this.MODEL).then(res => {
            return res.data;
        })
    }
}


export {
    BillingService
}
