import {PlusOutlined} from "@ant-design/icons";
import {Button, Divider, Form, notification, Row, Select, Space, Spin, Typography} from "antd";
import {Card} from "app/Base";
import {useFable} from "app/Base/Tables/Fable/Fable";
import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import Modal from "../../Modals/Modal";
import {FilterRow, Pagination, RowOrderBy, TransferDnD} from "./Controls";
import {ColumnFactory, Filter, OrderBy, View} from "./Types";


export function FiltersContainer() {
    const [orStatementValue, setOrStatementValue] = useState(false);
    const {prefilters, addPrefilter} = usePreFable();

    function addFilter(flt) {
        addPrefilter(flt);
    }

    return <>
        {/*<Row>*/}
        {/*    <Form.Item name={"or_statement"}>*/}
        {/*        <Radio.Group onChange={(e) => setOrStatementValue(e.target.value)}*/}
        {/*                     value={orStatementValue}>*/}
        {/*            <Radio value={false}>Все условия верны</Radio>*/}
        {/*            <Radio value={true}>Хотя бы одно условие верно</Radio>*/}
        {/*        </Radio.Group>*/}
        {/*    </Form.Item>*/}
        {/*</Row>*/}

        <Row style={{padding: 10}}>
            <Button icon={<PlusOutlined/>} onClick={() => {
                addFilter(new Filter());
            }}/>
        </Row><Row>
        {prefilters.map((flt, ind) => (
            <FilterRow filter={flt} index={ind}/>
        ))}
    </Row></>;
}

export function ViewEditForm() {
    return <Form layout="vertical">
        <TransferDnD/>
        <Divider style={{margin: 10, padding: 0}}/>

        <Row justify="space-between">
            <Space align={"top"}>
                <Pagination/>
                <RowOrderBy/>
            </Space>
        </Row>

        <Divider style={{margin: 10, padding: 0}}/>
        <FiltersContainer/>
    </Form>;
}


export function ViewMngPanel({onSave}) {
    const {
        current,
        viewsets,
        setCurrent,
        addViewset,
        deleteViewset,
        updateCurrent,
    } = useFable();

    function onSelect(value) {
        if (value) {
            setCurrent(value);
        }
    }

    function onDelete() {
        deleteViewset(current);
    }

    function onChange(val) {
        if (viewsets.filter(x => x.name != current.name).find(x => x.name == val))
            notification.error({message: "Представление с таким именем уже существует"});
        else
            updateCurrent({...current, name: val});
    }

    return <Card size="small">
        <Row justify="space-around">
            <Space>
                <Select value={current.name}
                        key={current.name}
                        onChange={onSelect}
                        style={{minWidth: 200}}>
                    {viewsets.map(f =>
                        <Select.Option value={f.name}>{f.name} {f.default && "По умолчанию"}</Select.Option>,
                    )}
                </Select>
                <Button onClick={() => {
                    const vs = addViewset(View.NEW_VIEW);
                    setCurrent(vs);
                }}
                        icon={<PlusOutlined/>} type={"primary"}/>
            </Space>
            <Typography.Text style={{paddingTop: 5}}
                             editable={{onChange}}>
                {current?.name}
            </Typography.Text>
            <Button onClick={onSave}>Сохранить</Button>
            <Button onClick={onDelete} type="danger"
                    disabled={(viewsets.length == 1 || current.name == "Default")}>
                Удалить
            </Button>
        </Row>
    </Card>;
}


const PreFableContext = createContext();


export function FableModal({visible, onSuccess, onCancel}) {

    const {columns, filters, updateCurrent, current, save, orderby, excols, model} = useFable();
    const [precolumns, setPrecolumns] = useState([]);
    const [prefilters, setPrefilters] = useState([]);
    const [preorder, setPreorder] = useState(new OrderBy());
    const [prelimit, setPrelimit] = useState(20);

    const {options} = model;
    const metadata = options.filters;

    useEffect(() => {
        if (visible) {
            setPrelimit(current.limit);
            setPreorder(orderby);

            if (columns?.length)
                setPrecolumns(columns);
            else if (!precolumns.length)
                setPrecolumns(excols.map(x => ColumnFactory(options).Column(x)));

            if (filters?.length)
                setPrefilters(filters);
        }
    }, [columns, filters, orderby, current, visible]);

    function onSave() {
        const vs = {
            ...current,
            filters: prefilters,
            columns: precolumns,
            orderby: preorder,
            limit: prelimit,
        };

        updateCurrent(vs);
        save();
    }

    function _onSuccess() {
        onSave();
        onSuccess?.();
    }

    function addPrefilter(flt) {
        setPrefilters([...prefilters, new Filter(flt)]);
    }

    function removePrefilter(flt) {
        const prefs = prefilters.filter(x =>
            !(x.column == flt.column &&
                x.operator == flt.operator));
        setPrefilters(prefs);
    }

    function updatePrefilter(fltobj, index) {
        let _prefs = [...prefilters];
        _prefs[index] = Filter.create(fltobj);
        setPrefilters(_prefs);
    }

    const values = useMemo(() => ({
        precolumns, prefilters, preorder, prelimit,
        setPrecolumns, setPrefilters, setPreorder, setPrelimit,
        addPrefilter, removePrefilter, updatePrefilter,
    }), [precolumns, prefilters, preorder, prelimit]);

    return <Modal onSuccess={_onSuccess}
                  width={"70%"}
                  visible={visible}
                  title="Настройки представления"
                  onCancel={onCancel}
                  destroyOnClose={false}
                  btnname={"Сохранить и показать"}>
        <PreFableContext.Provider value={values}>
            <Spin spinning={false}>
                <ViewMngPanel onSave={onSave}/>
                <Divider style={{margin: 10, padding: 0}}/>
                <ViewEditForm/>
            </Spin>
        </PreFableContext.Provider>
    </Modal>;
}

export function usePreFable() {
    return useContext(PreFableContext);
}


export function FableModalButton({...props}) {
    const [modalVisible, setModalVisible] = useState(false);

    return <>
        <Button onClick={() => setModalVisible(true)}
                title={"Редактировать настройки: колонки, фильтры, сортировка"}
                {...props}>
            ...
        </Button>

        <FableModal visible={modalVisible}
                    onSuccess={() => setModalVisible(false)}
                    onCancel={() => setModalVisible(false)}/>
    </>;
}
