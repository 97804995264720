import axios from "app/config/axios";
import CurrencyService from "./CurrencyAPI";
import {AttributableService} from "./AttributableService";


class AccountService extends AttributableService {
    MODEL = "account";
    TRANSACTIONS = "transactions";
    INFO = "information";

    constructor(contragent_id, account_id){
        super(account_id);
        this.MODEL = `contragent/${contragent_id}/account`
    }

    getInfo() {
        return this._get(this.INFO);
    }

    getContragentInfo() {
        return this._get("contragent");
    }

    getCurrencies() {
        return new CurrencyService().list();
    }

    getByNumber(number) {
        return this._get("search", {params: {"value": number}});
    }

    // search(data) {
    //     return this._post("search", data);
    // }

    getTransactions(params) {
        return this._get(this.TRANSACTIONS, {params});
    }

    getBalance() {
        return this._get("balance");
    }

    uploadSigns(sign, obj) {
        let fd = new FormData();
        fd.append(sign, obj.file);

        return axios.postForm(this._mk_res('signs'), fd);
        // return this._post('signs', data, {headers: {'Content-Type': 'multipart/form-data'}})
    }

    signs() {
        return this._get("signs");
    }

    topup(data) {
        return this._post('top-up', data).catch(AccountService.error_handler)
    }

    withdrawal(data) {
        return this._post("withdrawal", data).catch(AccountService.error_handler)
    }

    paytypes() {
        return this._get('paytype');
    }

}

export {
    AccountService,
};
