import BaseService from "./BaseAPI";


class PPFilterService extends BaseService {
    MODEL = "ppfilter";

    set_default() {
        return this._post("set_default").then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            return Promise.reject(err);
        });
    }

    filters_data(params) {
        return this._get("filters_data", {params: params}).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            return Promise.reject(err);
        });
    }
}

class FlexibleService extends BaseService {
    MODEL = 'flexible'

    constructor(model) {
        super();
        this._model = model;
    }

    list() {
        return super.list("", {model_name: this._model})
    }

    load() {
        return super._get(`data/${this._model}`)
    }

    save(data) {
        return super._post(`data/${this._model}`, data);
    }
}

export {
    PPFilterService,
    FlexibleService,
};
