export const T_CHOICE= 'choice';
export const T_MULCHOICE= 'multiple choice';

export const T_NESTED = 'nested object';

export const T_JSON = 'json';
export const T_LIST = 'list';
export const T_INT = 'integer';
export const T_STR = 'string';
export const T_BOOL = 'boolean';
export const T_FLOAT = 'float';
export const T_NUMBER = 'number';
export const T_DECIMAL = 'decimal';
export const T_MONEY = 'money';
export const T_DATE = 'date';
export const T_DATETIME = 'datetime';
export const T_TIME = 'time';
export const T_FIELD = 'field';
export const T_URL = 'url';
export const T_EMAIL = 'email';
export const T_REGEX = 'regex';
export const T_SLUG = 'slug';
export const T_FILE = 'file upload';
export const T_IMG = 'image upload';

export const Operator = {
    EQUAL: "=",
    NOT_EQUAL: "\u2260",
    LESS: "<",
    LESS_OR_EQUAL: "\u2264",
    GREATER: ">",
    GREATER_OR_EQUAL: "\u2265",
    IN: "in",
    NOT_IN: "not in",
    CONTAINS: "содержит",
    STARTSWITH: "начинается с",
}

export const NEW_VIEW_NAME = "Новое представление";
export const DEFAULT_VIEW_NAME = "Default";

export const EXPRESSIONS = {
    "exact": Operator.EQUAL,
    "not_exact": Operator.NOT_EQUAL,

    "gt": Operator.GREATER,
    "gte": Operator.GREATER_OR_EQUAL,
    "lt": Operator.LESS,
    "lte": Operator.LESS_OR_EQUAL,

    "in": Operator.IN,
    "not_in": Operator.NOT_IN,

    "icontains": Operator.CONTAINS,
    "startswith": Operator.STARTSWITH,
};


export const EXPRESSION_MAP = {
    [Operator.EQUAL]: '__exact',
    [Operator.NOT_EQUAL]: '__not_exact',
    [Operator.GREATER]: '__gt',
    [Operator.GREATER_OR_EQUAL]: '__gte',
    [Operator.LESS]: '__lt',
    [Operator.LESS_OR_EQUAL]: '__lte',
    [Operator.IN]: '__in',
    [Operator.NOT_IN]: '__not_in',
    [Operator.CONTAINS]: '__icontains',
    [Operator.STARTSWITH]: '__startswith',
};

export const OPERATORS = [
    Operator.GREATER,
    Operator.LESS,
    Operator.GREATER_OR_EQUAL,
    Operator.LESS_OR_EQUAL,
    Operator.EQUAL,
    Operator.NOT_EQUAL,
    Operator.IN,
    Operator.NOT_IN
];

export const OPERATORS_TYPE = {
    'choice': [Operator.IN, Operator.NOT_IN, Operator.EQUAL],
    'date': OPERATORS,
    'datetime': OPERATORS,
    'field': OPERATORS,
    'float': OPERATORS,
    'integer': OPERATORS,
    'decimal': OPERATORS,
    'nested object': [],
    'string': [Operator.EQUAL, Operator.NOT_EQUAL, Operator.IN, Operator.NOT_IN],
    'boolean': [Operator.EQUAL]
}
