

export function plural_years(number) {
    // возвращает именованное название количества в годах
    const titles = ['год', 'года', 'лет']
    const cases = [2, 0, 1, 1, 1, 2];  
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
}

export function plural_months(months){
    if(isNaN(+months)){
        return 'N/a';
    }
    if([0].includes(+months)){
        return `N/a`;
    }
    if([1].includes(+months)){
        return `месяц`;
    }
    if([2, 3, 4].includes(+months)){
        return `месяца`;
    }
    if([5, 6, 7, 8, 9, 10, 11, 12].includes(+months)){
        return `месяцев`;
    }
}

export function plural_period(months){  
    
    if(isNaN(+months)){
        return 'N/a';
    }
    if(+months > 12){
        const years = Math.floor(+months/12);
        const relative_months = +months % 12;
        if(relative_months === 0){
            return `${years} ${plural_years(years)}`
        }else{
            return `${years} ${plural_years(years)} и ${relative_months} ${plural_months(relative_months)}`
        }
    }
    return `${+months} ${plural_months(+months)}`;
}

export function getItemFromListById (list, id) {
    for (const item of list) {
        if (item.id === id) return item
      }

    return null
}

export function replaceItemInListById (list, id, newItem) {
    let target_item = getItemFromListById(list, id)

    const index = list.indexOf(target_item)
    list = [
        ...list.slice(0, index),
        newItem,
        ...list.slice(index + 1)
        ]

    return list
}