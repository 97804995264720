import React from "react";
import {Table, Layout, Space, Button} from 'antd';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {Card} from '../../Base';


const dataSource = [
    {
      id: '1',
      title: 'Вес',
      identity: 'weight',
      type: 'Вещественное число',
      sizing: 'Килограмм',
    },
    {
        id: '2',
        title: 'Доп. пользователи',
        identity: 'users',
        type: 'целое число',
        sizing: 'Штуки',
    },
    {
        id: '2',
        title: '...',
        identity: '...',
        type: '...',
        sizing: '...',
    },
  ];

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Название',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Идентификатор',
      dataIndex: 'identity',
      key: 'identity',
    },
    {
      title: 'Тип данных',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Единица измерения',
      dataIndex: 'sizing',
      key: 'sizing',
    },
    {
        title: 'Действие',
        key: 'operation',
        fixed: 'right',
        render: () => <Space >
            <Button size='small' shape='circle' icon={<DeleteOutlined />} danger ghost />
            <Button size='small' shape='circle' icon={<EditOutlined />} />
        </Space>,
    },
  ];

export default function Variable(){

    return <Layout.Content>
        <Card title='Переменные'>
          <Table size='small' dataSource={dataSource} columns={columns} pagination={false} />
        </Card>
    </Layout.Content>

}
