import BaseService from './BaseAPI'

class CurrencyService extends BaseService{
  MODEL='currency'

}

export default CurrencyService
export {
  CurrencyService
}
