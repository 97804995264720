import { Layout } from 'antd'
import { Route, Switch } from 'react-router'
import React from 'react'

import ModuleDetail from './ModuleDetail'
import ModuleList from './ModuleList'

export default function Module () {
  return (
    <Layout.Content>
      <Switch>
        <Route exact path='/modules'>
          <ModuleList />
        </Route>
        <Route exact path='/modules/:id'>
          <ModuleDetail />
        </Route>
      </Switch>
    </Layout.Content>
  )
}
