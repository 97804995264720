import React, {useEffect, useState} from "react";
import {Select, Tag} from 'antd';
import {TagService} from "../../API/TagsAPI";
import {useDebounce} from "react-use";


export default function Tagger({object, commit, ...props}) {
    const [tags, setTags] = useState([]);
    const [free_tags, setFreeTags] = useState([]);
    const [fltr, setFltr] = useState("");
    const [upd, setUpd] = useState(false);

    // const [tagmap, setTagMap] = useState();
    // const [tagmapids, setTagMapIds] = useState();

    useEffect(() => {
        let ts = new TagService();
        if (object) {
            ts.getUsedTags(object).then(({data: {data}}) => {
                setTags(data);
            }).catch(err=>{});
        }
        ts.getFreeTags(object).then(({data: {data}}) => {
            let ft = data.filter(t => t.name.includes(fltr));
            setFreeTags(ft);
            // if (!object) {
            //     setTagMap(Object.fromEntries(data.map(t => [t.name, t])));
            //     setTagMapIds(Object.fromEntries(data.map(t => [t.id, t])));
            // }
        });
    }, [upd, object])

    useEffect(() => {
        if (commit) {
            let ts = new TagService();

            if (commit === 'update')
                ts.clear(object);

            tags.map(tag => {
                ts.set(object, tag.id, '')
                return tag
            })
        }
    }, [commit])

    useDebounce(() => setUpd(!upd), 1000, [fltr]);

    function render_tag(t) {
        return <Tag closable={t.closable}
                    onClose={t.onClose}
                    color={t?.label?.props?.color}
                    key={t?.value}
        >{t?.label?.props?.name}</Tag>;
    }

    return <Select mode={'multiple'} // tags  не использовать, иначе работает неправильно
                   showSearch
                   onSearch={setFltr}
                   notFoundContent={null}
                   filterOption={false}
                   {...props}
                   tagRender={render_tag}
    >
        {free_tags.map(tag => (
            <Select.Option key={tag.id} id={tag.id} value={tag.id}>
                <Tag color={tag.color} name={tag.name}>{tag.name}</Tag>
            </Select.Option>
        ))}
    </Select>;

}
