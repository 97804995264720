import React, {useEffect, useState} from "react";
import {Button} from "antd";

export default function Submit({finish,...props}) {
    const [loading, setLoading] = useState(false);

    function onClick() {
        if (!loading) {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 5000);
            props.onClick?.();
        }
    }

    useEffect(() => {
        setLoading(false);
    }, [finish]);

    return <Button {...props}
                   type={"primary"}
                   loading={loading}
                   onClick={onClick}
                   htmlType={'submit'}
    />;
}
