import {usePaginationEx} from "app/Components/Common/Hooks/usePagination";
import React, {useState} from "react";
import {Input, Space, Table, Button, Divider, Spin, Row, Col} from "antd";
import {ReloadOutlined} from "@ant-design/icons";
import {SearchOutlined} from "@ant-design/icons";
import {useDebounce} from "react-use";
import DataTable from "app/Base/Tables/DataTable";


function DebouncedInput({value, onChange, placeholder, onInput, ...props}) {
    const [_val, setVal] = useState(value);

    function setLoadingVal(value) {
        setVal(value);
        onInput?.();
    }

    useDebounce(() => onChange(_val), 1000, [_val]);

    return <Input style={{maxWidth: "100%"}}
                  prefix={<SearchOutlined/>}
                  placeholder={placeholder}
                  allowClear
                  value={_val}
                  onChange={(e) => setLoadingVal(e.target.value)}/>;
}

export default function SearchTable({columns, dataSource, title, onSearchEdit, toggle, loading = false, ...props}) {

    const [_ldng, set_ldng] = useState(false);

    return <Table className={"stable"}
                  showHeader={true}
                  columns={columns}
                  dataSource={dataSource}
                  loading={loading || _ldng}
                  title={() => {
                      return <Row gutter={8}>
                          <Col>{typeof title === "function" ? title?.() : title}</Col>
                          <Col flex={"auto"}>
                              <DebouncedInput onChange={x => {
                                                  onSearchEdit?.(x);
                                                  set_ldng(false);
                                              }}
                                              onInput={() => set_ldng(true)}
                                              placeholder={"Поиск"}/>
                          </Col>
                          <Col><Button icon={<ReloadOutlined/>} onClick={() => toggle?.()}/></Col>
                      </Row>;
                  }}
                  {...props}/>;
}


export function SearchDataTable(
    {
        model, columns, title,
        onSearchEdit, toggle, additional_title,
        extra,
        ...props
    }) {

    const {setSearch, setPage} = model;

    function onChange(x) {
        setSearch(x);
        if (x?.length)
            setPage(1);
    }

    return <DataTable model={model}
                      columns={columns}
                      title={() => (
                          <Space>
                              {title?.()}
                              <DebouncedInput onChange={onChange}
                                              placeholder={"Поиск"}/>
                              <Button icon={<ReloadOutlined/>} onClick={() => toggle?.()}/>
                              <Divider type={"vertical"}/>
                              {typeof additional_title == "function" &&
                                  additional_title() ||
                                  additional_title}
                              {additional_title &&
                                  <Divider type={"vertical"}/>}
                              {typeof extra == "function" &&
                                  extra() ||
                                  extra}
                          </Space>
                      )}
                      {...props}/>;

}


export function SearchBackendTable({total, ...props}) {

    const [{page, limit}, pagination,
        {setPage, setLimit, setTotal}] = usePaginationEx(total, onChange);

    function onChange(pg, lmt) {

    }

    return <SearchTable pagination={pagination}
                        {...props}/>;
}
