import {DeleteOutlined, MoreOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {Button, Dropdown, Menu, message, PageHeader, Popconfirm, Space, Tag} from "antd";
import {ContragentService} from "app/API/ContragentAPI";
import {ProductService} from "app/API/ProductAPI";
import SearchTable from "app/Base/Tables/SearchTable";
import {usePaginationEx} from "app/Components/Common/Hooks/usePagination";
import React, {useEffect, useState} from "react";

import {Link} from "react-router-dom";
import {useToggle} from "react-use";

import useUser from "../../Providers/UserProvider";
import {BlockingModal} from "../Common/Modal/BlockingModal";
import {productStatusTag} from "../Common/Tags";
import {ProductModal} from "./Modals/ProductModal";


export default function ProductTable({onProductClick}) {

    const {contragent} = useUser();
    const [modalVisible, setModalVisible] = useState({visible: false});
    const [products, setProducts] = useState([]);
    const [update, toggle] = useToggle(false);
    const [isModalBlock, setModalBlock] = useState(false);
    const [product, setProduct] = useState();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    const [{page, limit, order}, pagination, {setPage, setTotal, onSortChange, setOrder}] = usePaginationEx();

    function onSearchEdit(s) {
        setSearch(s);
        setPage(1);
    }

    useEffect(() => {
        if (!modalVisible?.visible) {
            setLoading(true);
            new ContragentService(contragent?.id).getProducts({page, limit, search, order: order||"name"}).then(res => {
                setProducts(res.data.data.map(el => {
                    el.key = el.id;
                    return el;
                }));
                setTotal(res.data.count);
            }).finally(x => {
                setLoading(false)
            });
        }
    }, [modalVisible, update, page, limit, search, order]);

    function access_actions() {
        let access = [];

        function render_menu(record) {

            function delete_product() {
                new ProductService(contragent?.id, record?.id).delete().then(res => {
                    message.success("продукт удален");
                    toggle();
                });
            }

            return (
                <Menu>
                    <Popconfirm
                        title={`вы уверены, что хотите удалить продукт ${record?.name}`}
                        onConfirm={delete_product}
                        okText="Удалить"
                        cancelText="Отменить"
                        icon={<QuestionCircleOutlined style={{color: "red"}}/>}
                    >
                        <Menu.Item icon={<DeleteOutlined/>} danger>Удалить</Menu.Item>
                    </Popconfirm>
                    <Menu.Item onClick={() => {
                        setModalBlock(true);
                        setProduct(record);
                    }
                    }>Заблокировать</Menu.Item>
                </Menu>
            );
        }

        access.push({
            title: "",
            dataIndex: "actions",
            key: "actions",
            width: 50,
            render: (value, record) => (
                <Dropdown overlay={() => render_menu(record)} trigger={["click"]}>
                    <Button size="small" icon={<MoreOutlined/>}/>
                </Dropdown>
            ),
        });
        return access;
    }

    const columns = [
        ...access_actions(),
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            width: 50,
        },

        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            render: function (name, item) {
                if (typeof onProductClick === "function")
                    onProductClick(item);
                return <Link to={`/contragents/${contragent.id}/products/${item.id}`}>{name}</Link>;
            },
            sorter: true,
            defaultSortOrder: "ascend",
        },
        {
            title: "Код номенклатуры",
            dataIndex: "nomenclature_code",
            key: "nomenclature_code",
        },
        {
            title: "Единица измерения",
            dataIndex: "measure_name",
            key: "measure_name",
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            width: 150,
            render: function (name, record) {
                if (record.active_blockings)
                    return <Tag color="red">Заблокировано</Tag>;
                return productStatusTag(name);
            },
            // ProductStatusTag,
        },
    ];

    return (
        <PageHeader
            style={{background: "white"}} title="Продукты"
        >
            <SearchTable
                size="small"
                columns={columns}
                dataSource={products}
                width={1000}
                title={<Button type="primary"
                               onClick={() => setModalVisible({visible: true})}>
                    Создать продукт
                </Button>}
                // expandable={{
                //     expandedRowRender: (record) => (
                //       <ProductProvider initial_product={record}>
                //             <OfferProductTable />
                //       </ProductProvider>
                //     ),
                //     defaultExpandedRowKeys: [22, 23],
                // }}
                pagination={pagination}
                onChange={onSortChange}
                loading={loading}
                toggle={toggle}
                onSearchEdit={onSearchEdit}
            />

            <ProductModal visible={modalVisible?.visible} onCancel={() => setModalVisible({visible: false})}/>

            <BlockingModal
                targetObject={product}
                onCancel={() => setModalBlock(false)}
                onSuccess={() => setModalBlock(false)}
                visible={isModalBlock}
            />
        </PageHeader>
    );
}
