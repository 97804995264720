import React, {useState} from "react";
import {
    Button, Calendar, Col, Form, Image, Layout, Row, Space,
    Badge, Popconfirm, Alert,
} from "antd";
import useUser from "src/Providers/UserProvider";
import moment from "moment";
import {checkTimeTask, breakingSandbox} from "src/API/SysAPI";
import {useEffectOnce} from "react-use";
import {convertDateTime} from "src/lib/tools";


moment.locale("ru-RU");

export default function TimeManageForm() {

    const {time, applyTime, runningTask, runningMessage} = useUser();
    const [localtime, _setLocaltime] = useState(time);
    const images = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const [initialSystem, setInitialSystem] = useState(null);

    // let image = images[Math.floor(Math.random() * images.length)]
    const [image, setImage] = useState(images[Math.floor(Math.random() * images.length)]);


    function GOClick() {
        applyTime(localtime);
    }

    function setLocaltime(_time) {
        _setLocaltime(moment(_time._d));
    }

    function breaking() {
        breakingSandbox().then(res => {

        });
    }

    useEffectOnce(() => {
        checkTimeTask().then(res => {
            setInitialSystem(convertDateTime(res.data.initial_date_system));
        });
    });

    return (
        <Form>
            <Form.Item>
                {(runningTask &&
                        <Space direction="vertical">
                            <Alert type="info" message={
                                <Badge status="processing" text={runningMessage} color="green"/>
                            }/>
                            <Image src={`/static/gif/${image}.gif`} preview={false}/>
                        </Space>
                    )
                    ||
                    <Calendar fullscreen={false}
                              disabledDate={(date) => !!(initialSystem && date.endOf("d").valueOf() < initialSystem.endOf("d").valueOf())}
                              value={localtime}
                              onChange={(a) => {
                                  setLocaltime(a);
                              }}/>}
            </Form.Item>
            <Form.Item>
                <Layout.Content>
                    <Row gutter={[3, 1]} justify={"space-around"}>
                        <Col>
                            <Space>
                                {/* <Button onClick={RollbackClick} disabled={runningTask}>Rollback</Button> */}
                                <Button onClick={GOClick} type={"primary"} disabled={runningTask}>Запуск</Button>
                                <Popconfirm
                                    placement="top"
                                    title={"Внимание! Вы уверены, что хотите остановить машину"}
                                    okText="Да!" cancelText="Нет"
                                    onConfirm={breaking}
                                >
                                    <Button danger>Остановить!</Button>
                                </Popconfirm>
                            </Space>
                        </Col>
                    </Row>
                </Layout.Content>
            </Form.Item>
        </Form>
    );
}
