import React, {useEffect, useState} from "react";
import { useParams, useHistory } from "react-router-dom";
import {Button, Card, Col, Row, Table, Typography, Tag, Descriptions, Form, Input, List, message, Skeleton} from "antd";
import {EditOutlined} from "@ant-design/icons";

import {OrderService} from "app/API/OrderAPI";
import {OrderPayStatusTag, orderStatusTag, OrderTypeTag} from "../../Common/Tags";
import {AccountShortDetail, ContractShortDetail, DateTime} from "../../Common/OtherComponents";
import {OrderItemTable, OrderItemsPlainTable} from '../Tables/OrdersTable';
import Money from "../../Accounts/utils/Money";
import { ListAttrAsDescription } from "../../Common/OtherComponents";
import { OrderAttributesModal } from "../Modals/OrderModal";
import { useOrder } from "src/Providers/OrderProvider";
import useContragent from "../ContragentContext";
import {ChangePriceInfo} from '../Forms/CrossgradeServiceChangeForm';


export function OrderTransactionsTable({transactions, loading}) {
    const columns = [
        {title: '№', dataIndex: 'id', key: 'id', align: 'center'},
        {title: 'Дата', dataIndex: '_created', key: '_created', align: 'center', render: (dt) => {
            return <DateTime dt={dt} />
        }},
        {
            title: 'Тип', dataIndex: 'type_name', key: 'type_name', align: 'center', render: (type_name, tr) => {
                if (type_name === 'Оплата') return <Typography.Text type='success'>{type_name}</Typography.Text>
                if (type_name === 'Отмена') return <Typography.Text type='danger'>{type_name}</Typography.Text>
                return <Typography.Text>{type_name}</Typography.Text>
            }
        },
        {title: 'НДС', dataIndex: 'tax', key: 'tax', align: 'right', render: (value) => {
            return <Money sum={value} />
        }},
        {
            title: 'Сумма', dataIndex: 'amount', key: 'amount', align: 'right', render: (amount, tr) => {
                if (tr.type_name === 'Оплата') return <Money sum={amount} text_color={'success'}/>
                if (tr.type_name === 'Отмена') return <Money sum={amount} text_color={'danger'}/>
                return <Money sum={amount} />
            }
        },
    ]
    return (
      <Table size='small' columns={columns} dataSource={transactions} pagination={false} align={'center'} loading={loading}/>
    )
}


export function OrderDetail() {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [update, setUpdate] = useState(false);
    const [orderAttributes, setOrderAttributes] = useState([]);
    const [editAttrs, setEditAttrs] = useState(false)
    const params = useParams();
    const history = useHistory();
    const {contragent} = useContragent();
    const {order, update, toggle, setOrder} = useOrder();

    useEffect(() => {
        setOrder(null);
        if (params?.order_id) {
            const ord_srv = new OrderService(contragent.id, params?.order_id);
            Promise.all([
                ord_srv.get(),
                ord_srv.listTransactions()
            ]).then(([order, tranzz]) => {
                setOrder(order.data);
                setTransactions(tranzz.data.data);
            }).finally(() => {
                setLoading(false)
            })

            ord_srv.attributes.get().then(res => {
                setOrderAttributes(res.data.data)
            })
        }
    }, [update])

    if (!order) {
        return <Skeleton></Skeleton>;
    }

    return <Card size='small' title={`Заказ № ${order?.id}`}
                 extra={<Button type='primary' size='small' onClick={() => history.push(`/contragents/${contragent.id}/orders`)}>Назад</Button>}
                 loading={loading}
    >
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Card size='small' title='Информация'>
                    <Descriptions bordered size='small'>
                        <Descriptions.Item label='Наименование' span={3}>{order.name}</Descriptions.Item>
                        <Descriptions.Item label='идентификатор' span={3}>{order.id}</Descriptions.Item>
                        <Descriptions.Item label='UID' span={3}>{order.uuid}</Descriptions.Item>
                        <Descriptions.Item label='Номер' span={3}>{order.number}</Descriptions.Item>
                        <Descriptions.Item label='Клиент'>{order.buyer && order.buyer.name}</Descriptions.Item>
                        <Descriptions.Item label='Статус'>
                            {order.active_blockings
                                            ? <Tag color='red'>Заблокировано</Tag>
                                            : orderStatusTag(order.status)}
                        </Descriptions.Item>
                        <Descriptions.Item label='Тип' >

                            < OrderTypeTag el={order.type} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Создан'><DateTime dt={order.date} dateOnly /></Descriptions.Item>
                        <Descriptions.Item label='Стоимость'><Money sum={order.price}/></Descriptions.Item>
                        <Descriptions.Item label='Оплачено'><Money sum={order.paid_amount}/></Descriptions.Item>
                        <Descriptions.Item label='Статус оплаты'>{OrderPayStatusTag(order.status_pay)}</Descriptions.Item>
                        <Descriptions.Item label='Договор'>
                            {order.contract &&
                                <ContractShortDetail contract={order.contract} br={true}/>}
                        </Descriptions.Item>
                        <Descriptions.Item label='Счет'>
                            {order.seller_account && <AccountShortDetail account={order.seller_account}/>}
                        </Descriptions.Item>
                        <Descriptions.Item label='Дата завершения'>
                            <DateTime dt={order.realize_date} ifnull={'Не завершен'}/>
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </Col>
            <Col xs={24}>
                <Card size='small' title='Список позиций'>
                    <OrderItemTable order_id={order.id}
                                    order={order}
                                    contragent_id={contragent.id}
                                    loading={loading}
                                    viewMode={'edit'}
                    />
                </Card>
            </Col>
            <Col xs={24}>
                <Card size='small' title='Список транзакций'>
                    <OrderTransactionsTable transactions={transactions} loading={loading} />
                </Card>
            </Col>
            {orderAttributes &&
                <Col xs={24}>
                    <Card size='small' title='Атрибуты заказа' extra={
                        <EditOutlined onClick={() => setEditAttrs(true)}/>
                    }>
                        <ListAttrAsDescription attrs={orderAttributes} showTitle={false}/>
                    </Card>
                </Col>
            }
            {order.meta &&
                <Col xs={24}>
                    <Card size='small' title='Дополнительная информация'>
                        {order.meta.freeze &&
                            <p>
                                тип {order.meta.type}.
                                Дата активации заморозки {order.meta.activate_date}.
                                Дата окончания заморозки {order.meta.cancel_date}.
                                Продлено на {order.meta.prolong_days} дней
                            </p>
                        }

                        {order.meta.change_type && order.meta.change_type === 'next_period' &&
                            <div>
                                Заказ на смену со следующего периода.
                                <p>Текущая позиции:</p>
                                {order.meta && order.meta.current_items
                                    && <OrderItemsPlainTable orderItems={order.meta?.current_items}/>}
                            </div>
                        }

                        {order.meta.change_type && order.meta.change_type === 'immediately' &&
                            <div>
                                Заказ на смену с текущей даты до конца периода. Расчет стоимости.

                                {order.meta && order.meta.change_data
                                    && <ChangePriceInfo changeData={order.meta.change_data}/>}
                            </div>
                        }

                    </Card>
                </Col>
            }

            <Col xs={24}>
                <Card size='small' title='Промокод' layout='inline'>
                    <Row gutter={4}>
                        <Col flex='1'>
                            <Form id='promocode_form' onFinish={
                                (d) => new OrderService(contragent.id, order?.id).setPromocode(d).then(res => {
                                    toggle();
                                })
                            }>
                                <Form.Item name='promocode'>
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col>
                            <Button htmlType='submit' type='primary' form='promocode_form'>Найти и применить все скидки</Button>
                        </Col>
                    </Row>

                    {!!order.promocodes?.length &&
                        <Card size='small' title='Промокоды'>
                            <List size='small' bordered dataSource={order.promocodes}
                                renderItem={(item) => (
                                    <List.Item>{item.name}</List.Item>
                                )}
                            >
                            </List>
                        </Card>
                    }

                </Card>
            </Col>
            {!!order.discounts?.length &&
                <Col xs={24}>
                    <Card size='small' title='Модификаторы скидок'
                          extra={<Button size='small' danger onClick={() => {
                            new OrderService(contragent.id, order?.id).clearDiscounts().then(res => {
                                message.success('Скидки и промокоды удалены');
                                toggle();
                            })
                          }}>Сбросить</Button>}>
                        <Descriptions size='small' column={1} bordered>
                            {order.discounts.map(el => (
                                <Descriptions.Item label={el.name}>
                                    {el?.type_pay === 'money' ? <Money sum={el.value} /> : `${el.value}%`}
                                </Descriptions.Item>
                            ))}
                        </Descriptions>
                    </Card>
                </Col>
            }
            <OrderAttributesModal
                visible={editAttrs}
                order={order}
                onFinish={() => {setEditAttrs(false); toggle();}}
                onCancel={() => setEditAttrs(false)}
                />
        </Row>
    </Card>
}
