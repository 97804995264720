import React from "react";
import {Menu} from "antd";
import useIntegration from "app/Providers/IntegrationsProvider";


export function CMenu(props) {
    return <Menu {...props}/>
}

export function CMenuItem({integration, ...props}) {
    const {isEnabled} = useIntegration();

    if (isEnabled(integration))
        return <Menu.Item {...props}/>;

    return null;
}
