import React from "react";
import {Input, Checkbox, DatePicker, Button} from 'antd';

export default function DynamicalyInput({widget, ...props}){
    // Динамическая создаваемая форма от сервера Джанго.
    
    const inputs = {
        'CheckboxInput': Checkbox,
        'TextInput': Input,
        'DateTimeInput': DatePicker,
        'Textarea': Input.TextArea,
        'DateInput': DatePicker
    }
    const Component = inputs[widget]


    return Component ? <Component {...props}></Component> : 
        <Button type="primary" danger ghost>
            {`Данное поле имеет неопределённый тип ${widget}`}
        </Button> 
}