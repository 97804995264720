import React from "react";
import {Form, Input} from 'antd';


export default function NomenclatureForm({onFinish, form, ...props}) {

    function _onFinish(data) {
        const {measure, ..._data} = data;
        return onFinish(data);
    }

    return <Form layout='vertical' onFinish={_onFinish} form={form} {...props}>
        <Form.Item name='uuid' label='UUID' rules={[
            {required: true, message: 'Это поле необходимо заполнить'}
        ]}>
            <Input type='text'/>
        </Form.Item>
        <Form.Item name='name' label='Название' rules={[
            {required: true, message: 'Это поле необходимо заполнить'}
        ]}>
            <Input type='text'/>
        </Form.Item>
        <Form.Item name='code' label='Код' rules={[
            {required: true, message: 'Это поле необходимо заполнить'}
        ]}>
            <Input type='text'/>
        </Form.Item>
        <Form.Item name='description' label='Описание' rules={[
            {required: false, message: 'Это поле необходимо заполнить'}
        ]}>
            <Input type='text'/>
        </Form.Item>
    </Form>
}
