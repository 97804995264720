import Modal from "app/Base/Modals/Modal";
import React from "react";
import DebitForm from "../Forms/DebitForm";


export default function DebitModal({onSuccess, ...props}) {

    return (
        <Modal
            {...props}
            title="Списать"
            form={"debit_form"}
            btnname={"Списать"}
        >
            <DebitForm
                id="debit_form"
                onFinish={onSuccess}
            />
        </Modal>
    );
}
