import {CloseCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

import {
    Button,
    Card,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Select,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
} from "antd";

import {useForm} from "antd/es/form/Form";
import React, {useEffect, useState} from "react";

import {Link} from "react-router-dom";
import {useEffectOnce} from "react-use";
import {ContragentService} from "src/API/ContragentAPI";
import Money from "src/Components/Accounts/utils/Money";
import {DictionaryService, MeasureService} from "src/API/DictionaryAPI";
import {PackageService} from "src/API/PackageAPI";

import {ProductService} from "src/API/ProductAPI";
import Tagger from "src/Base/Inputs/Tagger";

import useUser from "src/Providers/UserProvider";
import AttributesForm from "../../Common/Forms/AttributesForm";


const required = {required: true, message: 'Обязательное поле.'};


export default function PackageForm({...props}) {
    // Форма создания/радактирования Пакетного предложения
    const [status, setStatus] = useState([]);
    const [measures, setMeasures] = useState([]);
    const [modalChoiceVisible, setModalChoiceVisible] = useState(false);
    const [products, setProducts] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [update, setUpdate] = useState(false);
    const [data, setData] = useState([]);
    const {contragent} = useUser();
    const [dictionaries, setDictionaries] = useState([]);
    const [attrs_form] = useForm();
    const [package_id, setPackageId] = useState('');
    // const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!modalChoiceVisible) {
            new ProductService(contragent?.id).list().then(res => {
                setProducts(res.data.data.filter(el => {
                    if (el.contragent === contragent.id) {
                        el.key = el.id;
                        return el
                    }
                }))
            })
        }
    }, []);

    useEffect(() => {
        if (props?.item) {
            // setLoading(true);
            const pkgsrv = new PackageService(contragent?.id, props?.item?.id);
            pkgsrv.getAttributesValue().then(res =>
                setDictionaries(res.data.data))
            pkgsrv.getPrices().then(res => {
                setData(res.data.data);
            }).finally(() => {
                // setLoading(false)
            });
        } else {
            new DictionaryService().getDictsByEntity("package").then(res => {
                setDictionaries(res.data.data);
            });
        }
    }, []);

    useEffectOnce(() => {
        new MeasureService().list().then(res => {
            setMeasures(res.data.data)
        });
        new ProductService(contragent?.id).getStatuses().then(res => {
            setStatus(res.data.data)
        })
    });

    useEffect(() => {
        setRefresh(true)
    }, [data, update]);

    function addData(d) {
        if (!data.filter(c => c.id === d.id).length) {
            setData([...data, d])
        }
    }

    function handleChange(val, record) {
        setData(data.map(c => (c.id === record.id) ? {...c, count: val} : c))
    }

    function onDeleteItem(record) {
        setData(data.filter(n => n.id !== record.id));
        setUpdate(p => !p);
    }

    function onFinish(values) {
        values.offers = data.map((d) => ({'id': d.id, 'count': d.count}));
        values.attributes = attrs_form.getFieldsValue();

        if (values.offers.length > 0) {
            if (props?.item) {
                new PackageService(contragent?.id, props.item?.id).update(values).then(res => {
                    message.success('пакетное предложение изменено');
                    setPackageId(res.data.id);
                    props.onFinish(values);
                    // attrs_form.submit();
                });
            } else {
                new PackageService(contragent?.id).create(values).then(res => {
                    message.success('пакетное предложение создано');
                    setPackageId(res.data.id);
                    props.onFinish(values);
                    // attrs_form.submit();
                });
            }

            setUpdate(p => !p)
        } else {
            message.error('добавьте ценовое предложение');
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Тип',
            dataIndex: 'type_name',
            key: 'type_name',
        },
        {
            title: 'Период',
            dataIndex: 'period',
            key: 'period',
            render: (value, record) => {
                if (value) {
                    return <Space>
                        {value.days > 0 && <Typography.Text> {value.days} д</Typography.Text>}
                        {value.months > 0 && <Typography.Text> {value.months} м</Typography.Text>}
                        {value.years > 0 && <Typography.Text> {value.years} г</Typography.Text>}
                    </Space>
                }
            }
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'количество',
            dataIndex: 'count',
            key: 'count',
            render: (value, record) => (
                <Space size="middle">
                    <InputNumber
                        defaultValue={record.count}
                        min={record.min_orders}
                        max={record.max_orders}
                        size='small'
                        onChange={(val) => handleChange(val, record)}
                    />
                </Space>
            )
        },
        {
            title: 'НДС',
            dataIndex: 'taxes',
            key: 'taxes',
            render: (value) => {
                return value === 'NO_VAT' ? 'Без НДС' : `${value}%`
            }
        },
        // {
        //   title: 'Количество',
        //   dataIndex: 'count',
        //   key: 'count',
        // },
        {
            //title: 'Действия',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Удалить">
                        <Button type="text" size="small" onClick={() => onDeleteItem(record)}
                                icon={<CloseCircleOutlined style={{color: 'red'}}/>}/>
                    </Tooltip>
                </Space>
            ),
        },
    ];


    return (
        <Form layout='vertical' {...props} onFinish={onFinish} >
            <Form.Item>
                <Card size='small' title='Общее'>
                    {/* {products.length ? '' : <Form.Item>
                        <Text type="danger">Чтобы добавить пакетное предложение необходимо сначала добавить
                            продукты и ценовые предложения из которых будет состоять пакетное предложение.
                            У вас нет активных продуктов.</Text>
                    </Form.Item>} */}
                    <Form.Item label='Наименование пакета' name='name' rules={[required]}>
                        <Input type='text'/>
                    </Form.Item>

                    <Form.Item name='description' label='Описание'>
                        <Input type='text'/>
                    </Form.Item>

                    <Form.Item name='identifier' label='Артикул'>
                        <Input type='text'/>
                    </Form.Item>
                    <Form.Item label='Единица измерения' name='measure'>
                        <Select>{measures.map((m) => (
                            <Select.Option key={m.id} value={m.id}>
                                <Typography>{m.name} <Typography.Text type='secondary'>({m.short})</Typography.Text>
                                </Typography>
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Статус' name='status' rules={[required]}>
                        <Select>{status.map((m) => (
                            <Select.Option key={m.id} value={m.id}>
                                <Typography>{m.name}</Typography>
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label={'Тэги'} name={'tags'}>
                        <Tagger/>
                    </Form.Item>
                    {!dictionaries.length && <Form.Item>
                        <Typography>Расширьте информацию в <Link to='/dictionary'>справочнике</Link></Typography>
                    </Form.Item>}
                    {dictionaries.map(el => {
                        return <AttributesForm id={el.codename}
                                                attributes={el.attributes}
                                                form={attrs_form}
                        />
                    })}
                </Card>
            </Form.Item>
            <Card size='small' title='Позиции заказа' extra={
                <Button type='primary' icon={<PlusCircleOutlined />} onClick={() => setModalChoiceVisible(true)} />
            }>
                <Table pagination={false} columns={columns} dataSource={data} refresh={refresh} />
            </Card>
            <ChoiceOfferModal products={products}
                                visible={modalChoiceVisible}
                                onCancel={() => setModalChoiceVisible(false)}
                                onSelect={(d) => addData(d)}
            />
        </Form>
    )
}

export function ChoiceOfferModal({onSelect, onCancel, ...props}) {

    // const [prices, setPrices] = useState([]);
    const {contragent} = useUser();
    const [offers, setOffers] = useState([]);
    const [offer, setOffer] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const [form] = Form.useForm();


    // useEffect(() => {
    //     if(searchText){
    //         new ContragentService(contragent.id).getOffers({params: {name__icontains: searchText}}).then(res => {
    //             setOffers(res.data.data);
    //         })
    //     }
    // }, [contragent, searchText])



    function onSelectOffer() {
        let o = offers.filter(el => el.id === offer)[0];
        typeof onSelect === 'function' && onSelect(o);
        typeof onCancel === 'function' && onCancel();
    }

    function handleSearch(newValue){
        if (newValue) {
            new ContragentService(contragent.id).getOffers({params: {name__icontains: newValue, order_by: 'name'}}).then(res => {
                setOffers(res.data.data);
            })
        } else {
            setOffers([]);
        }
    }

    return (
        <Modal id="choice_offer"
               title="Выберите ценовое предложение"
               width={800}
               footer={
                   <Button type="primary" form="choice_offer" onClick={onSelectOffer}>Выбрать</Button>
               }
               onCancel={onCancel}
               {...props}
        >
            <Form layout='vertical' {...props} form={form}>
                <Form.Item
                    label='Ценовое предложение'
                    name='offer'
                    rules={[required]}
                >
                    <Select onSelect={(id) => setOffer(id)}
                            showSearch
                            placeholder="Введите наименование предложения для поиска"
                            onSearch={handleSearch}
                            notFoundContent={null}
                            filterOption={false}
                            defaultActiveFirstOption={false}
                            // filterOption={(input, option) => (option?.name ?? '').includes(input)}
                    >
                        {offers.map((m) => (
                            <Select.Option key={m.id} value={m.id} name={m.name}>
                                    <Typography.Text>{m.name}</Typography.Text>
                                    <Divider type='vertical' />
                                    <Money sum={m.price} currency={m.currency} />
                                    <Divider type='vertical' />
                                    <Tag>{m.type_name}</Tag>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}
