import React from "react";
import {Link} from 'react-router-dom';

export function CustomLink({children, onClick, ...props}) {

    return <Link style={{color: "#00407F"}}
                 to={"#"}
                 onClick={onClick}
                 {...props}>{children}</Link>;

}
