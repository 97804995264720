

import React from 'react'
import { Modal} from 'antd'
import OfferTable from '../../Tables/OfferTable';
import { AtolContragentService } from '../../../../API/AtolAPI';
import useContragent from '../../ContragentContext';


export default function ATOLRefillCounterModal({item, ...props}){

  const {contragent} = useContragent();

  function onSelectOffer(v){
    let data = {counter_id: item.id, offer_id: v.id}
    new AtolContragentService(contragent.external_id).createRefillMeter(data)
  }

  return (
    <Modal title='Выбрать предложение для пополнения' width={'60%'} {...props}>
      <OfferTable onSelectOffer={onSelectOffer} filter={{tags__codename: 'atol_refill_meter'}} />
    </Modal>
  )
}
