import React from "react";
import {Table} from "antd";


export default function DataTable({model, columns, onChange, ...props}) {

    const {
        query, page, setPage,
        limit, setLimit, columns: clms,
        loading
    } = model;

    const pagination = {
        current: page,
        pageSize: limit || 20
    };

    function _onChange(p, size) {
        setPage(p);
        if (size != limit)
            setLimit(size);
    }

    return <Table className={'stable'}
                  size={'small'}
                  showHeader={true}
                  dataSource={query.data?.data}
                  columns={columns || clms}
                  // scroll={{x: 1000, y: 500}}
                  pagination={{
                      ...pagination,
                      size: 'small',
                      total: query.data?.count || 0,
                      responsive: true,
                      hideOnSinglePage: false,
                      pageSizeOptions: [10, 20, 50, 100],
                      showSizeChanger: true,
                      onChange: _onChange,
                  }}
                  loading={loading}
                  {...props}/>
}

// FilterTable.propTypes = {
//     query: PropTypes.object.isRequired,
//     columns: PropTypes.arrayOf(PropTypes.object),
//     filter: PropTypes.object
// }
