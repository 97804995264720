import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {Button, Card, Col, Row, Statistic, Typography} from "antd";
import {MoreOutlined} from "@ant-design/icons";
import Currency from "../../Accounts/utils/Currency";
import useAccount from "../../../Providers/AccountProvider";
import useContragent from "../../Contragents/ContragentContext";
import useIntegration from "../../../Providers/IntegrationsProvider";
import {AccountService} from "app/API/AccountAPI";
import {AtolContragentService} from "app/API/AtolAPI";
import useUser from "app/Providers/UserProvider";
import Modal from "app/Base/Modals/Modal";
import Money from "app/Components/Accounts/utils/Money";


export function BalanceCard({...props}) {
    const {atol} = useIntegration();
    const {contragent} = useContragent();
    const {account, update, setAccount} = useAccount();
    const [detailBalance, setDetailBalance] = useState(null);
    const {user} = useUser();
    const [m_balVisible, setMBalVisible] = useState(false);
    const [bal_billing, setBalBilling] = useState(null);
    const [bal_1c, setBal1C] = useState(null);

    useEffect(() => {
        setDetailBalance(null);

        if (account) {
            if (atol && contragent?.external_id) {
                new AtolContragentService(contragent?.external_id).getAccount().then(res => {
                    setDetailBalance(res.data);
                })
            }
            else {
                new AccountService(contragent?.id, account?.number).getInfo().then(res => {
                    setDetailBalance(res.data);
                })
            }
        }
    }, [account, update])

    function onSelectAccount(acc) {
        setAccount(acc);
    }

    return (
        <Card size="small"
              title={`Финансы`}
              loading={!account}
              extra={[
                  <Button disabled size="small" icon={<MoreOutlined/>}/>
              ]}
              {...props}
        >
            <Row gutter={16}>
                <Col span={10}>
                    <Statistic
                        title={<Link to={`/contragents/${contragent?.id}/transactions`}
                                     onClick={() => onSelectAccount(detailBalance?.number)}>Доступно</Link>}
                        valueStyle={{color: detailBalance?.balance < 0 ? "#cf1322" : "#3f8600"}}
                        prefix={<Currency code={account?.currency}/>}
                        groupSeparator={' '}
                        decimalSeparator={','}
                        value={detailBalance?.balance}
                        loading={!detailBalance}
                    />
                </Col>
                <Col span={10}>
                    <Statistic
                        title={<Link to={`/contragents/${contragent?.id}/transactions`}
                                     onClick={() => onSelectAccount(detailBalance?.advanced_number)}>Зарезервировано</Link>}
                        prefix={<Currency code={account?.currency}/>}
                        groupSeparator={' '}
                        decimalSeparator={','}
                        value={detailBalance?.advanced}
                        loading={!detailBalance}
                    />
                </Col>
                {user.username == 'admin' && atol &&
                    <Col span={4}>
                        <Button onClick={() => {
                            new AccountService(contragent?.id, account?.number).getBalance().then(res => {
                                setBalBilling(res.data.billing_bal);
                                setBal1C(res.data['1c_bal']);
                                setMBalVisible(true);
                            })
                        }}>балансы</Button>
                        <Modal visible={m_balVisible}
                               onCancel={() => setMBalVisible(false)}
                               title={"сверка балансов"}>
                            <Row>
                                <Typography.Text>Баланс биллинга <Money sum={bal_billing}/></Typography.Text>
                            </Row>
                            <Row>
                                <Typography.Text>Баланс 1c: <Money sum={bal_1c}/> </Typography.Text>
                            </Row>
                        </Modal>
                    </Col>}
            </Row>
        </Card>
    )
}
