import React, { useState, useMemo, useContext, useEffect } from 'react';
import API_ from '../API/ModuleAPI'

const IntegrationContext = React.createContext();


export function IntegrationProvider({children}) {
    // Контекст хранилища по работе с сервисами интеграции

    const [atol, setAtol] = useState(false);

    const [integrations, setIntegrations] = useState({});
    const [update, setUpdate] = useState(false);

    const API = new API_();

    const intergrations = {
        'atol': {get: atol, set: setAtol}
    };

    useEffect(() => {
        API.list(res => {
            res.data.data.map(module => {
                if (typeof intergrations[module] !== "undefined" && typeof intergrations[module].set === 'function') {
                    intergrations[module].set(true);
                }
                return module
            });
        })

        API.new_list(res => {
            const outputDict = Object.assign(
                {},
                ...res.data.data.map(item => ({ [item.codename]: item }))
            );
            setIntegrations(outputDict);
        })
    }, [update]);

    function isEnabled(name) {
        if (integrations)
            return integrations[name]?.enable || false;
        return false;
    }

    function setStatusIntegration(name_module, status){
        intergrations[name_module].set(status);
    }

    function getStatusIntegration(name_module){
        return intergrations[name_module].get
    }

    const variables = useMemo(() =>({integrations, atol}), [atol, integrations]);
    const functions = {
        getStatusIntegration,
        setStatusIntegration,
        setUpdate,
        isEnabled
    };

    let values = {...functions, ...variables};

    return (
        <IntegrationContext.Provider value={values}>
            {children}
        </IntegrationContext.Provider>
    );
}

export default function useIntegration() {
    return useContext(IntegrationContext);
}
