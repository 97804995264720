import React from "react";
import { Card } from "../../Base";
import { Layout, Tabs } from "antd";
import AttributeTable from "./Tables/AttributeTable";
import DictionaryTable from "./Tables/DictionaryTable";
import MeasureTable from "./Tables/MeasureTable";
import TagsTable from "./Tables/TagsTable";
import EnumGroupTable from "./Tables/EnumGroupTable";
import NomenclatureTable from "./Tables/NomenclatureTable";
import BlockingTypeTable from "./Tables/BlockingTypeTable";

export default function Dictionaries(){

    return <Layout.Content>
        <Card title='Справочники'>
            <Tabs>
                <Tabs.TabPane key="1" tab='Атрибуты'>
                    <AttributeTable />
                </Tabs.TabPane>
                <Tabs.TabPane key="2" tab='Группа атрибутов'>
                    <DictionaryTable />
                </Tabs.TabPane>
                <Tabs.TabPane key="3" tab='Единицы измерения'>
                    <MeasureTable />
                </Tabs.TabPane>
                <Tabs.TabPane key="4" tab={"Тэги"}>
                    <TagsTable />
                </Tabs.TabPane>
                <Tabs.TabPane key="5" tab={"Списки"}>
                    <EnumGroupTable />
                </Tabs.TabPane>
                <Tabs.TabPane key="6" tab={"Номенклатура бухгалтерии"}>
                    <NomenclatureTable />
                </Tabs.TabPane>
                <Tabs.TabPane key="7" tab={"Типы блокировок"}>
                    <BlockingTypeTable />
                </Tabs.TabPane>
            </Tabs>
        </Card>

    </Layout.Content>
}
