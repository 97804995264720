import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { message, Table, Button, Space, Popconfirm, Card } from 'antd'
import React, { useEffect, useState } from 'react'

import TemplateService from '../../../API/TemplateAPI'

import { Link } from 'react-router-dom'
import useUser from '../../../Providers/UserProvider'

export default function TemplateTable () {
  const [data, setData] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const {contragent} = useUser();

  const columns = [
    { title: '№', dataIndex: 'id' },
    { title: 'Заголовок', dataIndex: 'title' },
    { title: 'Имя', dataIndex: 'name' },
    {
      title: 'Операции',
      fixed: 'right',
      key: 'operation',
      render: (_, record) => {
        return (
        // <Permission global={['create_edit_notify_templates']}>
          <Space>
            <Link to={`/templates/${record.id}`}>
            <Button
              icon={<EditOutlined />}
              shape='circle'
              size='small'
            /></Link>
            <Popconfirm
              placement='topRight'
              title='Вы уверены, что хотите удалить?'
              okText='Удалить'
              cancelText='Отмена'
              onConfirm={() => {
                new TemplateService(contragent?.id, record.id).delete().then(() => {
                  message.success('Шаблон удалён')
                  setIsRefresh(!isRefresh)
                })
              }}
            >
              <Button
                danger
                ghost
                icon={<DeleteOutlined />}
                shape='circle'
                size='small'
              />
            </Popconfirm>
          </Space>
        // </Permission>
        )
      }
    }
  ]

  useEffect(() => {
    new TemplateService(contragent?.id).list().then((r) => {
      setData(r.data.data)
    }).catch(() => {
      message.error('Возникла ошибка при считывании шаблонов')
    })
  }, [isRefresh])

  return (
    <>
      <Card title='Шаблоны' extra={<Button type='primary'>
          <Link to={'templates/create'}>Создать</Link>
        </Button>}
        size='small'
      >
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ pageSize: 100 }}
          rowKey='id'
          size='small'
        />
      </Card>
    </>
  )
}
