import {AttributableService} from "./AttributableService";
import { error_handler } from "./BaseAPI";

export default class DiscountService extends AttributableService {

    constructor(contragent_id, discount_id){
        super(discount_id);
        this.MODEL = `contragent/${contragent_id}/discount`
    }

    generatePromocodes(data){
        // генерирует промокоды
        return this._post('generate', data).then(res => {
            return Promise.resolve(res)
        }).catch(error_handler)
    }
}
