import {Card, Col, Form, Input, InputNumber, message, Radio, Row, Select, Space, Typography} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useEffectOnce} from "react-use";
import {ContragentService} from "src/API/ContragentAPI";
import {DictionaryService} from "src/API/DictionaryAPI";
import {OfferService} from "src/API/OfferAPI";
import {AccountSelect, ContragentSelect} from "src/Base/Inputs";
import Tagger from "src/Base/Inputs/Tagger";
import useUser from "src/Providers/UserProvider";
import AttributesForm from "../../Common/Forms/AttributesForm";


const requires_field = {required: true, message: "обязательное поле для заполнения"};


function GeneralOfferSubForm() {
    const [accounts, setAccounts] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [contragentList, setContragentList] = useState([]);
    const [productList, setProductList] = useState([]);
    const {contragent} = useUser();

    useEffectOnce(() => {
        Promise.all([
                        new ContragentService(contragent?.id).getContragentAccount(),
                        new ContragentService(contragent?.id).getAllProducts(),
                        new OfferService(contragent?.id).getTaxes(),
                        new ContragentService().list(),
                    ]).then(([accs, products, taxs, ctgs]) => {
            setAccounts(accs.data.data.filter(el => el.type === "internal"));
            setProductList(products.data.data);
            setTaxes(taxs.data.data);
            setContragentList(ctgs.data.data);
        });
    });

    let searchContragent = (value) => {
        new ContragentService().list({params: {"search": value}}).then(res => {
            setContragentList(res.data.data);
        });
    };

    return <>
        <Form.Item name="name" label="Наименование услуги" rules={[
            requires_field,
        ]}>
            <Input type="text"/>
        </Form.Item>
        <Form.Item name={"account"} label="Счёт поступления">
            <AccountSelect accounts={accounts} placeholder="счёт по умолчанию"/>
        </Form.Item>
        <Form.Item name={"product"} label="Продукт">
            <Select>
                {productList.map(el => {
                    return <Select.Option value={el.id}>
                        {el.name}
                    </Select.Option>;
                })}
            </Select>
        </Form.Item>
        <Form.Item label={"Тэги"} name={"tags"}>
            <Tagger/>
        </Form.Item>
        <Form.Item name="price" label="Цена" rules={[
            requires_field,
            {
                validator: (_, value) => {
                    if (value < 0) {
                        return Promise.reject("цена не может быть отрицательной");
                    }
                    return Promise.resolve();
                },
            },
        ]}>
            <InputNumber/>
        </Form.Item>
        <Form.Item name="taxes" label="НДС" rules={[
            requires_field,
        ]}>
            <Select>
                {taxes.map(el => (
                    <Select.Option value={el[0]}>{el[1]}</Select.Option>
                ))}
            </Select>
        </Form.Item>
        {/* <Form.Item name='count' label='Количество'>
            <InputNumber min={1} />
        </Form.Item> */}
        <Form.Item name="access_contragents" label="Кому доступно предложение" help="при пустом списке - доступно всем">
            <ContragentSelect
                mode="multiple"
                onClear={() => searchContragent(null)}
                onSearch={searchContragent}
                contragentsList={contragentList}
            />
        </Form.Item>
    </>;
}

function CounterOfferSubForm() {

    const [formulas, setFormulas] = useState([]);
    const {contragent} = useUser();

    useEffectOnce(() => {
        new OfferService(contragent?.id).getFourmulas().then(res => {
            setFormulas(res.data.data);
        });
    });

    return (
        <>
            <Form.Item name="pre" label="По предоплате в">
                <Select defaultValue={[0]}>
                    <Select.Option value={"0"}>0%</Select.Option>
                    {/*<Select.Option value={100}>100%</Select.Option>*/}
                </Select>
            </Form.Item>
            <Form.Item name="formulas" label="По формуле расчёта">
                <Select>
                    {formulas.map(el => (
                        <Select.Option value={el.value}>{el.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </>
    );
}

function OnceOfferSubForm() {

    return (
        <>
            <Form.Item name="pre" label="по предоплате в">
                <Select defaultValue={[100]} disabled>
                    <Select.Option value={0}>0%</Select.Option>
                    <Select.Option value={100}>100%</Select.Option>
                </Select>
            </Form.Item>
            <RestrictionIntervalBuyOrderSubForm/>
        </>
    );
}

function RestrictionIntervalBuyOrderSubForm() {

    return <Form.Item label="Ограничение за заказ (мининимальное - максимальное количество)">
        <Space>
            <Form.Item name="min_orders" initialValue={1}>
                <InputNumber min={1} disabled/>
            </Form.Item>
            <Form.Item name="max_orders" help="При отсутсвии - неограниченно">
                <InputNumber min={1}/>
            </Form.Item>
        </Space>
    </Form.Item>;
}


function PeriodInput({value = {}, onChange}) {
    const [number, setNumber] = useState(1);
    const [period, setPeriod] = useState("months");

    useEffect(() => {
        if (value?.months || value?.years) {
            setNumber((value.months||0) + (value.years||0) * 12);
            setPeriod("months");
        } else if (value?.days) {
            setNumber(value.days);
            setPeriod("days");
        }else{
            onChange({months: 1})
        }
    }, [value]);

    function triggerChange(changedValue) {
        onChange?.(changedValue);
    }

    function onNumberChange(e) {
        const newNumber = parseInt(e.target.value || "0");
        setNumber(newNumber);
        triggerChange({[period]: newNumber});
    }

    function onPeriodChange(newPeriod) {
        // Обнуляем предыдушее значение
        triggerChange({[period]: 0});

        setNumber(0);
        setPeriod(newPeriod);
    }

    return (
        <span>
            <Input
                type="number"
                value={number}
                onChange={onNumberChange}
                style={{
                    width: 100,
                }}
            />
            <Select
                value={period}
                style={{
                    width: 100,
                    margin: "0 8px",
                }}
                onChange={onPeriodChange}
            >
                <Select.Option value="days">дней</Select.Option>
                <Select.Option value="months">месяцев</Select.Option>
            </Select>
        </span>
    );
}


function SubscribeOfferSubForm() {

    return (
        <>
            <Form.Item name="period" label="Период подписки">
                <PeriodInput/>
            </Form.Item>
            <Form.Item name="pre" label="По предоплате в" rules={[
                {required: true, message: "необходимое поле"},
            ]}>
                <Select>
                    <Select.Option value={0}>0%</Select.Option>
                    <Select.Option value={100}>100%</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="reserve" label="Резервировать средства клиента?" initialValue={false}>
                <Radio.Group>
                    <Radio.Button value={true}>Да</Radio.Button>
                    <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <RestrictionIntervalBuyOrderSubForm/>
        </>
    );
}


export default function OfferForm({type, ...props}) {
    // Форма создания предложения
    const {contragent} = useUser();
    const [dictionaries, setDictionaries] = useState([]);
    const [attrs_form] = useForm();
    const [offer_id, setOffer_id] = useState(null);
    const requires_field = {required: true, message: "обязательное поле для заполнения"};
    const [_type, setTypeForm] = useState(props.form.getFieldValue('type') || 0);
    const price_type = {
        0: {component: null, title: null},
        1: {component: OnceOfferSubForm, title: "Разовая услуга"},
        2: {component: CounterOfferSubForm, title: "Счётчик"},
        3: {component: SubscribeOfferSubForm, title: "Подписка"},
    };
    const Component = price_type[_type]["component"];

    useEffect(() => {
        if (props.item?.id) {
            new OfferService(contragent?.id, props.item.id).attributes.get().then(res => {
                setDictionaries(res.data.data);
            });
        } else {
            new DictionaryService().getDictsByEntity("offers").then(res => {
                setDictionaries(res.data.data);
            });
        }
    }, [props?.item]);

    function sendAttributes(props) {
        new OfferService(contragent?.id, offer_id).attributes.update(props).then(res => {
            message.success("Атрибуты сохранены");
        });
    }

    const onChangeProductType = (e) => {
        setTypeForm(e.target.value);
    };

    function onFinish(data) {
        data['contragent'] = contragent?.id;
        data['attributes'] = attrs_form.getFieldsValue()

        new OfferService(contragent?.id, data?.id).update_or_create(data).then(res => {
            setOffer_id(res.data.id);
            attrs_form.submit();
            typeof props.onCancel === "function" && props.onCancel();
            typeof props.onFinish === "function" && props.onFinish(data);
        });
    };

    return <Form layout="vertical" {...props} onFinish={onFinish}>
        {/* если поле идентификатора присутствует, то значит редактируем, иначе создаем */}
        <Form.Item name="id" label="Идентификатор" hidden>
            <Input/>
        </Form.Item>
        <Form.Item name="type" label="Тип услуги" rules={[
            requires_field,
        ]}>
            <Radio.Group onChange={onChangeProductType}>
                <Radio.Button value={1}>{price_type[1]["title"]}</Radio.Button>
                <Radio.Button value={2}>{price_type[2]["title"]}</Radio.Button>
                <Radio.Button value={3}>{price_type[3]["title"]}</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <Row gutter={16}>
            <Col xs={12}>
                <Card size="small" title="Основные данные">
                    <GeneralOfferSubForm/>
                </Card>
            </Col>
            <Col xs={12}>
                {Component && <Card size="small">
                    <Component/>
                </Card>}
                {!dictionaries.length &&
                    <Typography>Расширьте информацию в <Link to="/dictionary">справочнике</Link></Typography>}
                {dictionaries.map(el => {
                    return <Card key={el.codename} title={el.name} style={{marginBottom: "1em"}}>
                        <AttributesForm id={el.codename}
                                        attributes={el.attributes}
                                        form={attrs_form}
                        />
                    </Card>;
                })}
            </Col>
        </Row>
    </Form>;
}

// DEPRECATED
// export function EditOfferForm({onFinish, type, ...props}) {
//   // Форма создания Продукта
//   //TODO: Подгрузка доступных статусов в случае, если их не передали в props

//   const price_type = {
//     1: {component: OnceOfferSubForm, title: 'разовая услуга'},
//     2: {component: CounterOfferSubForm, title: 'счётчик'},
//     3: {component: SubscribeOfferSubForm, title: 'подписка'}
//   }

//   const [commit, setCommit] = useState(false);
//   const [object, setObject] = useState();

//   const Component = price_type[type]['component']

//   // useEffect(() => {
//   //   console.log(props.form?.getFieldValue('id'))
//   //   setObject(props.form?.getFieldValue('id'))
//   // }, [props])


//   const requires_field = {required: true, message: 'обязательное поле для заполнения'}

//   function _onFinish(data) {
//     onFinish(data).then(res => {
//       setObject(res?.data?.id);
//       // setCommit(true);
//     })
//   }

//   return <Form layout="vertical" onFinish={_onFinish} {...props}>
//     <Form.Item name='type' label='тип услуги' rules={[
//       requires_field
//     ]}>
//       <Radio.Group defaultValue={type} value={type}>
//         <Radio.Button value={1} disabled={type !== 1}>{price_type[1]['title']}</Radio.Button>
//         <Radio.Button value={2} disabled={type !== 2}>{price_type[2]['title']}</Radio.Button>
//         <Radio.Button value={3} disabled={type !== 3}>{price_type[3]['title']}</Radio.Button>
//       </Radio.Group>
//     </Form.Item>
//     <Row gutter={16}>
//       <Col xs={12}>
//         <Card size='small' title='основные данные'>
//           <GeneralOfferSubForm tagger={<Tagger object={object} />}/>
//         </Card>
//       </Col>
//       <Col xs={12}>
//         <Card size='small'>
//           <Component/>
//         </Card>
//       </Col>
//     </Row>
//   </Form>
// }
