import {message} from 'antd'
import axios from '../config/axios'


class Axios {
    constructor(error = 'error', success = 'success') {
        this.error = error
        this.success = success
    }

    catch(error) {
        if (this.error) {
            message.error(this.error)
        } else if (typeof error.response.data === 'object') {
            for (const k in error.response.data) {
                message.error(error.response.data[k])
            }
        }
    }

    create(id, values) {
        axios.post(`/api/${this.url}/${id}`, values).then(() => {
            message.success(this.success)
        }).catch((error) => {
            this.catch(error)
        })
    }

    getRetrieveURL(id) {
        return `/api/${this.url}/${id}`
    }

    list(then) {
        axios.get(`/api/${this.url}`).then((response) => {
            then(response)
        }).catch((error) => {
            // this.catch(error)
        })
    }

    new_list(then) {
        axios.get(`/api/${this.url}/integrations`).then((response) => {
            then(response)
        }).catch((error) => {
            // this.catch(error)
        })
    }

    retrieve(id, then) {
        axios.get(`/api/${this.url}/${id}`).then((response) => {
            then(response)
        }).catch((error) => {
            this.catch(error)
        })
    }

    forms(id, then) {
        axios.get(`/api/${this.url}/${id}/forms`).then((response) => {
            then(response)
        }).catch((error) => {
            this.catch(error)
        })
    }

    detail_query(id, action, method, data, content_type = 'application/json', then) {
        let query = axios.get

        if (method === 'POST') {
            query = axios.post
        } else if (method === 'GET') {
            query = axios.get
        } else if (method === 'PATCH') {
            query = axios.get
        } else if (method === 'DELETE') {
            query = axios.get
        }

        query(`/api/${this.url}/${id}/${action}`, data, {
            headers: {'Content-Type': content_type}
        }).then((response) => {
            if (response.data.msg) {
                message.success(response.data.msg)
            }
            then(response)
        }).catch((error) => {
            this.catch(error)
        })
    }

}

export default class API extends Axios {
    constructor(...args) {
        super(...args)
        this.url = 'modules'
    }
}
