import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Col, Input, message, Row, Table} from "antd";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {AccountService} from "src/API/AccountAPI";
import useAccount from "src/Providers/AccountProvider";
import useUser from "src/Providers/UserProvider";
import usePagination, {usePaginationEx} from "../../Common/Hooks/usePagination";
import {DateTime} from "../../Common/OtherComponents";
import useContragent from "../../Contragents/ContragentContext";
import DebitAction from "../Actions/DebitAction";
import PayUpAction from "../Actions/PayUpAction";
import Money from "../utils/Money";


const {Column, ColumnGroup} = Table;

export default function BalanceTable({...props}) {
    // Основная таблица транзакциями
    const {is_global_permitted} = useUser();
    const {account, update, toggle} = useAccount();
    const {contragent} = useContragent();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTransactionsValue, setSearchTransactionsValue] = useState("");

    const [{page, limit}, pagination, {setPage, setLimit, setTotal}] = usePaginationEx();

    function get_transactions() {
        setLoading(true);
        new AccountService(contragent?.id, account?.number)
            .getTransactions({search: searchTransactionsValue, page, limit}).then(res => {
            setTransactions(res.data.data);
            setTotal(res.data?.count);
        }).catch(err => {
            message.error("Возникла ошибка");
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if (account) {
            get_transactions();
        }
    }, [searchTransactionsValue, update, account, page, limit]);

    return <Table size="small"
                  dataSource={transactions}
                  pagination={pagination}
                  scroll={{x: 900}}
                  loading={loading}
                  title={() => (
                      <Row gutter={8}>
                          {is_global_permitted("application.account.charge") &&
                              <Col>
                                  <PayUpAction
                                      onTransactionDone={() => toggle()}
                                      component={
                                          <Button disabled={account?.number === null || account?.number === ""}>
                                              Пополнить
                                          </Button>
                                      }
                                  />
                              </Col>
                          }
                          {is_global_permitted("application.account.debit") &&
                              <Col>
                                  <DebitAction
                                      onTransactionDone={() => toggle()}
                                      component={
                                          <Button style={{width: "100%"}}
                                                  disabled={account?.number === null || account?.number === ""}>
                                              Списать
                                          </Button>
                                      }
                                  />
                              </Col>
                          }
                          <Col flex="auto">
                              <Input.Search
                                  key="0"
                                  prefix={<SearchOutlined/>}
                                  placeholder="Фильтр"
                                  allowClear
                                  onSearch={(value) => {
                                      setSearchTransactionsValue(value);
                                  }}
                              />
                          </Col>
                          <Col>
                              <Button icon={<ReloadOutlined/>} onClick={() => toggle()}/>
                          </Col>
                      </Row>
                  )}
                  {...props}
    >
        <Column title="№" dataIndex="id" key="id" width={50} fixed="left"/>
        <Column title="Время" dataIndex="_created" key="_created" width={160} render={val => <DateTime dt={val}/>}/>
        <Column title={"Тип транзакции"} dataIndex={"paytype_name"} key={"paytype_name"} width={160}/>
        <Column title={"Комментарий"} dataIndex={"type_name"} key={"type_name"} ellipsis/>
        {/* <Column title={"Тип платежа"} dataIndex={"paytype_name"} key={"paytype_name"} width={'200px'}/> */}
        <ColumnGroup title={"Баланс"}>
            <Column title={"До"} dataIndex={"balance_before"} key={"balance_before"} align="right" width={160}
                    render={(sum) => (
                        <Money currency={account?.currency} sum={sum}/>
                    )}/>
            <Column title={"После"} dataIndex={"balance_after"} key={"balance_after"} align="right" width={160}
                    render={(sum) => (
                        <Money currency={account?.currency} sum={sum}/>
                    )}/>
        </ColumnGroup>
        {/*<Column title="НДС" dataIndex='tax' align='right' key='tax' width={100} render={(sum) => (*/}
        {/*  <Money currency={account?.currency} sum={Math.round(sum * 100) / 100}/>*/}
        {/*)}/>*/}
        <Column title="Сумма" dataIndex="sum" align="right" key="sum" width={200} render={(sum) => (
            <Money currency={account?.currency} sum={sum}/>)}/>
    </Table>;
}

BalanceTable.propTypes = {
    contragent: PropTypes.number,
    account: PropTypes.string,
    onCountBalance: PropTypes.func,
};
