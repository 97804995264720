import React from "react";
import {Form, Input} from 'antd';

export default function MeasureForm({form, ...props}){

  return <Form layout='vertical' form={form} {...props} >
    <Form.Item name='name' label='Наименование' rules={[
      {required: true, message: 'Это поле необходимо заполнить'}
    ]}>
      <Input type='text' />
    </Form.Item>
    <Form.Item name='codename' label='codename Ед.изм.' rules={[
      {
        required: true,
        message: 'Это поле необходимо заполнить. Допустимые символы A-Z и _',
        type: "regexp",
        pattern: new RegExp("^[A-Z_]+$")
      }
    ]}>
      <Input type='text' />
    </Form.Item>
    <Form.Item name='short' label='Краткое наименование'>
      <Input type='text' />
    </Form.Item>
  </Form>
}
