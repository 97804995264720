import React from "react";
import {Button, Modal} from 'antd';
import CreateContragentForm from "../Forms/CreateContragent";


export default function ClientModal({...props}) {

    return (
        <Modal 
            title='Создание контрагента' 
            width={'60%'} 
            footer={<Button type='primary' htmlType='submit' form="create_contragent">Создать</Button>} 
            {...props}
        >
            <CreateContragentForm id='create_contragent' onFinish={() => props?.onCancel()} />
        </Modal>
    )
}
