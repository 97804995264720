import React, {useEffect, useRef, useState} from "react";
import {Button, Form, Input, List} from 'antd';
import {DeleteOutlined} from "@ant-design/icons";

const Item = List.Item;

function MyItem({children, onEdit, onDelete, ...props}) {
    const [edit, setEdit] = useState(!children);
    const [value, setValue] = useState(children);

    const input = useRef(null);

    function enter(d) {
        typeof onEdit === 'function' && onEdit(value);
        setEdit(false);
    }

    useEffect(() => {
        if (input && edit) input.current.focus();
    }, [edit])

    return <Item>
        <div style={{width: "100%"}} onClick={() => {
            setEdit(true);
        }}>
            {(!edit) ? value : <Input type={"text"}
                                      size={"small"}
                                      value={value}
                                      onChange={d => setValue(d.target.value)}
                                      onBlur={enter}
                                      ref={input}
                                      onPressEnter={enter}/>}</div>
        <Button size='small'
                shape='circle'
                icon={<DeleteOutlined/>}
                danger
                onClick={() => (typeof onDelete === 'function' && onDelete(value))}/>
    </Item>
        ;
}

function MyList({value, onChange, ...props}) {
    const [values, setValues] = useState(value || []);

    function edit(d, index) {
        values[index].value = d;
        setValues(values);
        onChange(values);
    }

    function del(d, index) {
        const _vals = values.filter((v, idx) => !(idx === index));
        setValues(_vals);
        onChange(_vals);
    }

    return <><List>
        {values.map((v, index) => <MyItem onEdit={(d) => edit(d, index)}
                                          onDelete={(d) => del(d, index)}
                                          value={v.value}
                                          key={v.id}>{v.value}</MyItem>)}
    </List>
        <div style={{width: "100%", align: "right"}}>
            <Button size='small'
                    onClick={() => setValues([...values, {value: ""}])}>Добавить</Button>
        </div>

    </>;
}


export default function EnumGroupForm({onFinish, ...props}) {

    function _onFinish(data) {
        let {name, values} = data;
        return onFinish({name, values})
    }

    return <Form layout='vertical' onFinish={_onFinish} {...props}>
        <Form.Item name='name' label='Название' rules={[
            {required: true, message: 'Это поле необходимо заполнить'}
        ]}>
            <Input type='text'/>
        </Form.Item>
        <Form.Item name='values' label='Список значений'>
            <MyList/>
        </Form.Item>
    </Form>
}
