import React from "react";
import Flags from "country-flag-icons/react/1x1";


export default function Currency({code, type, ...props}){
    const currency_code = {
        810: {flags: Flags["RU"], symbol: '₽'},
        643: {flags: Flags["RU"], symbol: '₽'},
        '₽': {flags: Flags["RU"], symbol: '₽'},
        840: {flags: Flags["US"], symbol: '$'},
        '$': {flags: Flags["US"], symbol: '$'},
        156: {flags: Flags["CN"], symbol: '¥'},
        '¥': {flags: Flags["CN"], symbol: '¥'},
        978: {flags: Flags["EU"], symbol: '€'},
        '€': {flags: Flags["EU"], symbol: '€'},
    };
    
    try{
        if(code === undefined) return null
        switch (type){
            case 'flag':
                let Component = currency_code[code].flags
                return <Component {...props} style={{width: '1em', height: '1em', borderRadius: '50%'}} />
            case 'symbol':
                return '₽'
            default:
                return currency_code[code].symbol
        }
    }catch(e){
        return null
    }
}