import React from "react";
import { Layout } from "antd";
import PackageTable from "./PackageTable";
import { Route, Switch } from "react-router";
import PackageDetail from "./PackageDetail";
import {AccountProvider} from '../../Providers/AccountProvider';
import { PackageProvider } from "./PackageContext";


export default function Package(){

    return <Layout.Content>
        <Switch>
            <Route exact path='/packages/:id'>
                <PackageProvider>
                    <AccountProvider>
                        <PackageDetail />
                    </AccountProvider>
                </PackageProvider>
            </Route>
            <Route exact path='/packages'>
                <PackageTable />
            </Route>
        </Switch>
    </Layout.Content>
}
