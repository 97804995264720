import React from "react";
import PayUpForm from "../Forms/PayUpForm";
import Modal from "app/Base/Modals/Modal";


export default function PayUpModal({onSuccess, ...props}) {

    return (
        <Modal title="Пополнить счёт"
               {...props}
               form={"payup_form"}
               btnname={"Пополнить"}>
            <PayUpForm id="payup_form" onFinish={onSuccess}/>
        </Modal>
    );
}
