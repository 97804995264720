import axios from "app/config/axios";
import {message, notification} from "antd";
import moment from "moment";

function ObjectHasNoIds(message) {
    this.message = message;
    this.name = 'ObjectHasNoIds';
}

export function get_id_ifnot(id_or_object) {
    if (isNaN(id_or_object)) {
        if (isNaN(id_or_object?.id))
            throw new ObjectHasNoIds("object is not ID and has not any ID");
        return id_or_object.id;

    } else {
        return id_or_object;
    }
}

export function convertDateTime(time) {
    if (moment.isMoment(time)) {
        return time
    } else if (typeof time === 'string') {
        return moment(time, 'YYYY-MM-DD')
    } else if (moment.isDate(time)) {
        return moment(time)
    }
}

export function check_permission(permissions, perms) {
    if (typeof perms === 'string') {
        return permissions.filter(x => perms === x).length > 0

    } else if (typeof perms === 'object' && Array.isArray(perms)) {
        return permissions.filter(x => (new Set(perms)).has(x)).length > 0
    }
    return false
}

export function sleep(ms) {
    return new Promise(r => setTimeout(r, ms));
}


export function convert_url(url) {
    let o_url;

    try {
        o_url = new URL(url);
    } catch (TypeError) {
        o_url = new URL(url, document.location.origin)
    }
    o_url.protocol = document.location.protocol;

    if (process.env.REACT_APP_BASE_URL)
        o_url = new URL(`${o_url.pathname}${o_url.search}`, process.env.REACT_APP_BASE_URL)
    else
        o_url = new URL(`${o_url.pathname}${o_url.search}`, document.location.origin)

    return o_url.href;
}


export function compare_ar(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
}
