import React, {useState} from "react";
import {Form, Input, Radio, message} from 'antd';

import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import {useDebounce} from 'react-use';
import {getExistUsername} from '../../../API/UserAPI';

export default function RegisterForm(props){

    const [typePerson, setTypePerson] = useState(null)
    const [form] = Form.useForm()
    const [usernameValue, setUsernameValue] = useState(null);
    const [validateStatus, setValidateStatus] = useState(null);

    useDebounce(() => {
        if(usernameValue)
            getExistUsername({username: usernameValue}).then(res => {
                if(res.data.allow_username){
                    setValidateStatus('success');
                }else{
                    setValidateStatus('error');
                }
            }).catch(err => {
                message.error('Произошла ошибка');
            })
        }, 500, [usernameValue]
      );

    let pattern_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    let password_validator = (rule, value) => {
        let password = form.getFieldValue('password');
        return password === value ? Promise.resolve() : Promise.reject()
    }

    return <Form form={form} layout='vertical' {...props}>
        <Form.Item 
            name='email'
            rules={[
                {
                    pattern: RegExp(pattern_email),
                    message: 'неккоректный email'
                }
            ]}
        >
            <Input type='email' placeholder='Почта' prefix={<MailOutlined />} />
        </Form.Item>
        <Form.Item 
            name='username' 
            hasFeedback 
            validateStatus={validateStatus}
            onChange={(e) => {
                setUsernameValue(e.target.value);
                setValidateStatus('validating');
            }}
        >
            <Input type='text' placeholder='username' prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item name='password' rules={[
            {required: true, message: 'необходимо ввести пароль'},
            {required: true, min: 8, message: 'пароль слишком короткий!'}
        ]}>
            <Input type='password' placeholder='Пароль' prefix={<LockOutlined />} />
        </Form.Item>
        <Form.Item 
            name='second_password' 
            rules={[
                {required: true, message: 'необходимо заполнить'},
                {validator: password_validator, message: 'пароль должен совпадать'}
            ]}
        >
            <Input type='password' placeholder='Подтвердите пароль' prefix={<LockOutlined />} />
        </Form.Item>

        <Form.Item name='type'>
            <Radio.Group onChange={(e) => setTypePerson(e.target.value)}>
                <Radio value={1}>Физическое лицо</Radio>
                <Radio value={2}>Юридическое лицо</Radio>
            </Radio.Group>
        </Form.Item>
        {typePerson && 
            <Form.Item label='Название контрагента' name='name'>
                <Input type='text' />
            </Form.Item>
        }
        {/* инфа о контрагенте юр лица убраны. Добавятся позже */}
        {/* {typePerson === 2 && <>
            <Divider>Реквизиты</Divider>
        
            <Form.Item label='ИНН' name='inn'>
                <Input type='text' />
            </Form.Item>
            <Form.Item label='Номер расчётного счёта' name='mrc'>
                <Input type='text' />
            </Form.Item>
            <Form.Item label='Корреспондентский счёт' name='cc'>
                <Input type='text' />
            </Form.Item>
            <Form.Item label='ОКПО' name='okpo'>
                <Input type='text' />
            </Form.Item>
            <Form.Item label='КПП' name='kpp'>
                <Input type='text' />
            </Form.Item>
            <Form.Item label='БИК' name='bik'>
                <Input type='text' />
            </Form.Item>
            <Form.Item label='Наименование банка' name='name_bank'>
                <Input type='text' />
            </Form.Item>
            <Form.Item label='ОГРН' name='ogrn'>
                <Input type='text' />
            </Form.Item>
            <Form.Item label='Полное наименование' name='full_name'>
                <Input type='text' />
            </Form.Item>
    
            <Divider>Адреса</Divider>
            
            <Form.Item label='Фактический адрес' name='fact_address'>
                <Input type='text' />
            </Form.Item>
            <Form.Item label='Юридический адрес' name='curr_address'>
                <Input type='text' />
            </Form.Item>
            
        </>} */}
        {/* <Button type='primary' htmlType='submit'>СОХР</Button> */}
    </Form>
}