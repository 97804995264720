import {message} from "antd";

import {EXPRESSION_MAP} from "app/Base/Tables/Fable/const";
import {loadFromLS} from "app/Base/Tables/FlexibleTable/SaveLoad";
import {useEffect, useState} from "react";
import {useQuery, useQueryClient} from "react-query";
import {useToggle} from "react-use";
import {AtolFullReportService, AtolFullReportTransactionService} from "src/API/AtolAPI";
import {BlockingContragentService} from "src/API/BlockingAPI";
import {ContractContragentService} from "src/API/ContractAPI";
import {ContragentService} from "src/API/ContragentAPI";
import DiscountService from "src/API/Discount";
import {OfferContragentService} from "src/API/OfferAPI";
import {OrderContragentService} from "src/API/OrderAPI";
import {ProductOfferService, ProductService} from "src/API/ProductAPI";
import {RevenueContragentService} from "src/API/RevenueAPI";
import {SubscribeContragentService} from "src/API/ServiceAPI";
import useUser from "src/Providers/UserProvider";
import {EdoMessageService} from "../API/EdoAPI";
import {PPMailService} from "../API/PPFMailAPI";
import DocumentService from "app/API/DocumentAPI";


function is_class(value) {
    return value.toString().includes("class");
}

function is_function(value) {
    return value.toString().includes("function");
}

function is_object(value) {
    return value.toString().includes("object Object");
}

/**
 * отдельный хук для запроса метадаты у вьюхи через HTTP OPTIONS
 * @param get_service
 * @param ModelName
 */
function useMetadata(get_service, ModelName) {
    const [mdLoading, setMdLoading] = useState(false);

    const options = useQuery(
        [ModelName, "options"],
        async () => {
            const srv = get_service();

            setMdLoading(true);

            try {
                const data = (await srv.options()).data || {fields: {}, attributes: []};
                const fields = data.fields;
                const keys = data.fields.map(x => x.name);

                const attributes = data.attributes.map(x => (
                    {
                        name: x.codename,
                        label: x.name,
                        type: x.type,
                        choices: x.choices,
                    }));
                const columns = Object.values(fields).map(x => (
                                          {
                                              title: x.label,
                                              dataIndex: x.name,
                                              key: x.name,
                                              type: x.type,
                                              choices: x.choices,
                                          }))
                                      .concat(attributes.map(x => (
                                          {
                                              title: x.label,
                                              dataIndex: x.name,
                                              key: x.name,
                                              type: x.type,
                                              choices: x.choices,
                                          })).filter(x => (!keys.includes(x.key))));
                const filters = data.filters;

                return {fields, attributes, columns, filters};
            } catch (err) {
                return {fields: [], attributes: [], columns: [], filters: []};
            }
        },
        {
            onSuccess: data => {
                setMdLoading(false);
            },
            onError: err => {
                setMdLoading(false);
            }
        },
    ).data || {};

    return {
        options,
        mdLoading
    };
}

/**
 * Хук для запроса данных + метаданных из вьюхи (list + options)
 * @param Service {BaseService}
 * @param page {int}
 * @param columns {array}
 * @param id {int}
 * @param contragent {Object}
 * @returns {{query, options}}
 */
export function useData(Service, {page, params, id, contragent}) {

    const ModelName = Service.MODEL.split("/").reverse()[0];
    const qc = useQueryClient();

    const {globalUpdate, viewsetsLoaded} = useUser();
    const {options, mdLoading} = useMetadata(get_service, ModelName);
    const {fields, attributes, filters} = options;

    const [_update, toggleTableUpdate] = useToggle();
    const [_page, setPage] = useState(page || 1);
    const [search, setSearch] = useState();
    const [view, setView] = useState(null);
    const [loading, setLoading] = useState(mdLoading);

    const _columns = view ?
        view.columns.filter(x => x.key).map(x => x.key) :
        [];

    function _convert_orderby() {
        let pref = "",
            col = "";
        if (view?.orderby?.column) {
            if (!view.orderby.asc)
                pref = '-';
            col = view.orderby.column;
        }
        return [`${pref}${col}`].filter(x => !["", null, undefined].includes(x));
    }

    const _orderby = _convert_orderby();

    function convert_filters(filters) {
        let _flts;

        if (filters && filters instanceof Array) {
            _flts = filters.map(flt => {
                const oper = EXPRESSION_MAP[flt.operator];
                const col = flt.column;
                const val = flt.value;
                if (oper && col && val !== undefined)
                    return {
                        [`${col}${oper}`]: val,
                    };
                else
                    return {};
            }).reduce((res, flt) => ({...res, ...flt}), {});
            _flts.order_by = _orderby;
        }
        else if (filters && filters instanceof Object) {
            _flts = filters;
            _flts.order_by = _orderby;
        }

        return _flts;
    }

    const _filters = convert_filters(view?.filters || {});

    useEffect(() => {
        qc.invalidateQueries({queryKey: [ModelName]});
    }, [globalUpdate, _update, id, view]);

    function get_service() {
        if (is_class(Service))
            return new Service(id);
        else if (is_object(Service))
            return Service;
        else if (is_function(Service))
            return Service();
    }

    function _trans_columns() {
        const _flds = fields?.map(x => x.name || x.key)
                            ?.filter(x => _columns?.includes(x)) || _columns.filter(x => x != "collapse");
        const _attrs = attributes?.map(x => x.name || x.key)
                                 ?.filter(x => _columns?.includes(x)) || [];

        return [_flds, _attrs];
    }

    function saveAsCSV() {
        const srv = get_service();
        const [_flds, _attrs] = _trans_columns();
        const name = loadFromLS(ModelName);

        return srv.csv(_flds, _attrs, _filters, `${ModelName}.csv`, name);
    }

    function saveAsXLSX() {
        const srv = get_service();
        const [_flds, _attrs] = _trans_columns();
        const name = loadFromLS(ModelName);

        return srv.excel(_flds, _attrs, _filters, `${ModelName}.xlsx`, name);
    }

    function setLimit(val) {
        setView({...view, limit: val});
    }

    let query_key;
    if (contragent && contragent.id)
        query_key = [ModelName, _page, search, view, contragent.id];
    else
        query_key = [ModelName, _page, search, view];

    const query = useQuery(
        query_key,
        async () => {
            const srv = get_service();
            let data;

            setLoading(true);

            try {
                if (!!view?.id && !!fields && !!attributes) {
                    const [_flds, _attrs] = _trans_columns();

                    data = (await srv.fable(
                        _flds, _attrs, _filters, _orderby, _page, view?.limit, search,
                    )).data || {data: []};
                } else {
                    data = (await srv.list({
                        page: _page,
                        limit: 20,
                        ...{params},
                        ...{search},
                    })).data || {data: []};
                }
                return data;
            } catch (ex) {
                return {data: []};
            }
        },
        {
            enabled: (!!view?.id && !!fields && !!attributes) ||
                (!view?.id && !!view), // обязательно надо дождаться метадату, а иначе мы не знаем схему
            onError: err => {
                setLoading(false);
                message.error(`Не удалось запросить данные ${err}`);
            },
            onSuccess: data => {
                setLoading(false);
            }
        },
    );

    return {
        query,
        options,
        page: _page, setPage,
        search, setSearch,
        Service,
        toggleTableUpdate,
        saveAsXLSX,
        saveAsCSV,
        ModelName,
        setView,
        limit: view?.limit || 20,
        setLimit,
        loading
    };
}


/**
 *
 * @param page {int}
 * @param filters {object}
 * @param id {int|null}
 * @param contragent
 * @returns {{query, options}}
 */
export
function useOrdersData({page, filters, id, contragent}) {
    return useData(new OrderContragentService(contragent?.id), {page, filters, id, contragent});
}


export
function useContragentData(page = 0, filters = {}, id = null) {
    // return useData(ContragentService, {page, filters, id}); ТАК НЕ РАБОТАЕТ при сборке для PROD
    return useData(new ContragentService(), {page, filters, id});
}


export
function useProductData(page = 0, filters = {}, id = null) {
    return useData(ProductService, {page, filters, id});
}


export
function useBlockingData(contragent) {
    return useData(new BlockingContragentService(contragent.id), {contragent});
}

export
function useDiscountData(contragent, page = 0, filters = {}, id = null) {
    return useData(new DiscountService(contragent.id), {page, id, filters});
}

export
function useReportData(page = 0, filters = {}, id = null) {
    return useData(new AtolFullReportService(), {page, filters, id});
}

export
function useReportTransactionData(page = 0, filters = {}, id = null) {
    return useData(new AtolFullReportTransactionService(), {page, filters, id});
}


export
function useContractData(page = 0, contragent = null) {
    return useData(new ContractContragentService(contragent), {page, contragent});
}


export
function useOfferData({page, contragent}) {
    return useData(new OfferContragentService(contragent?.id || 0), {page: page || 0, contragent});
}

export
function useOfferProductData({contragent_id, product_id, page}) {
    return useData(new ProductOfferService(contragent_id, product_id), {page: page || 0});
}

export
function useRevenueData({page, contragent}) {
    return useData(new RevenueContragentService(contragent?.id || 0), {page: page || 0, contragent});
}


export
function useSubscribeData({page, contragent}) {
    return useData(new SubscribeContragentService(contragent?.id || 0), {page: page || 0, contragent});
}

export
function usePPMailData({page, contragent}) {
    return useData(PPMailService.contragented(contragent?.id || 0), {page: page || 0, contragent});
}

export
function useEdoData() {
    return useData(new EdoMessageService(), {page: 1});
}

export
function useDocumentData({page, contragent, document}) {
    return useData(new DocumentService(contragent?.id || 0, document?.id), {page: page||0, contragent});
}

