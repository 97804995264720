import React from 'react'
import {Button, Modal, Space, Tag, notification} from 'antd'
import BillingOrderTable, {SubscribeDetalizationTable, SubscribePlanTable} from '../Tables/BillingTable';
import { SubscribeService } from '../../../API/ServiceAPI';
import useContragent from '../ContragentContext';


export default function BillingModal({order_item, ...props}) {

    return (
        <Modal {...props} destroyOnClose width={'1100px'} title='детализация' footer={false}>
            <BillingOrderTable
                order_item={order_item}
            />
        </Modal>
    )
}

export function SubscribePlanModal({service_item, ...props}) {

    return (
        <Modal {...props} destroyOnClose width={'80%'} title='План-Факт' footer={false}>
            <SubscribePlanTable service_item={service_item}/>
        </Modal>
    )
}

export function SubscribeDetalizationModal({service_item, ...props}) {

    return (
        <Modal {...props} destroyOnClose width={'80%'} title='Детализация подписки' footer={false}>
            <SubscribeDetalizationTable service_item={service_item}/>
        </Modal>
    )
}

export function CancelServiceModal({item, ...props}){
    const {contragent} = useContragent();

    function onCancelService(){
        new SubscribeService(contragent?.id, item?.id).closeService().then(res => {
            notification.success({
                message: "Подписка прекращена",
                description: "Подписка прекращена. Неиспользованные средства возвращены на баланс.",
            });
            onClose();
        }).catch(err => {
            SubscribeService.error_handler(err.response);
        })
    }

    function onClose(){
        typeof props.onCancel === 'function' && props.onCancel();
    }

    return (
        <Modal {...props} destroyOnClose title='Отмена услуги' footer={
            <Space>
                <Button onClick={onClose}>Отмена</Button>
                <Button danger onClick={onCancelService}>Подтвердить</Button>
            </Space>
        }>
            При отмене услуги:
            <ul>
                <li>Статус услуги изменится на <Tag color='red'>Отменен</Tag></li>
                <li>За отработанный период будет создана реализация.</li>
                <li>Средства за неотработанный период будут возвращены на баланс.</li>
            </ul>
            Вы действительно хотите отменить эту подписку?
        </Modal>
    )
}

// export function BillingServiceModal({service_item, ...props}) {

//   return (
//     <Modal {...props} destroyOnClose width={'1100px'} title='детализация' footer={false}>
//       <BillingServiceTable
//         service_item={service_item}
//       />
//     </Modal>
//   )
// }
