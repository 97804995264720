import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {DatePicker, Form, Input, notification, Select, Space} from "antd";
import {ContragentService} from "src/API/ContragentAPI";
import useUser from "src/Providers/UserProvider";
import DocumentService, {ReconService} from "src/API/DocumentAPI";
import ContractService from "src/API/ContractAPI";
import useContragent from '../ContragentContext';
// import {useParams} from 'react-router-dom'

export default function DocumentCreateForm({form, ...props}) {
    const [ctgs, setCtgs] = useState([])
    const [ctrs, setCtrs] = useState([])
    const [name, setName] = useState("")

    const {contragent} = useUser()

    useEffect(() => {
        new ContragentService(contragent.id).listContracted().then(r => {
            setCtgs(r.data.data)
            setName(`Акт сверки от ${moment().format('YYYY-MM-DD')}`)
        })
        new ContractService(contragent?.id).list().then(r => {
            setCtrs(r.data.data)
        })
    }, [])

    function finish(vals) {
        if (name && vals.buyer && vals.date_from && vals.date_to) {
            vals.seller = contragent.id
            vals.name = name
            vals.date_from = moment(vals.date_from).format('YYYY-MM-DD')
            vals.date_to = moment(vals.date_to).format('YYYY-MM-DD')
            new ReconService(contragent?.id).create(vals)
                .then(r => {
                    notification.success({
                        message: 'Документ создан успешно',
                        description: `Создан документ ${name}`
                    })
                })
                .catch(e => notification.error({
                    message: 'Ошибка создания документа',
                    description: e
                }))

        }
    }

    function onChangeContragent(id, ctg) {
        new ContractService(contragent?.id).listByContragents(contragent.id, id).then(r => {
            setCtrs(r.data.data)
        })
    }

    return (
        <Form form={form}
              layout={"vertical"}
              onFinish={finish}
              {...props}>
            <Form.Item name={'name'}
                       valuePropName={'name'}
                       label={'Заголовок документа'}>
                <Input type={'text'}
                       placeholder={'Заголовок документа'}
                       value={name}
                       onChange={r => setName(r.target.value)}
                />
            </Form.Item>
            <Form.Item name={'buyer'}
                       valuePropName={'buyer'}
                       label={'Контрагент'}
            >
                <Select
                    showSearch
                    onChange={onChangeContragent}
                    options={ctgs.map((v) => ({label: v.name, value: v.id}))}
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }/>
            </Form.Item>
            <Space>
                <Form.Item name={'date_from'}
                           valuePropName={'date_from'}
                           label={'С'}>
                    <DatePicker/>
                </Form.Item>
                <Form.Item name={'date_to'}
                           valuePropName={'date_to'}
                           label={'По'}>
                    <DatePicker/>
                </Form.Item>
            </Space>
            <Form.Item name={'contract'}
                       valuePropName={'contract'}
                       label={'Договор'}
            >
                <Select
                    showSearch
                    options={ctrs.map((v) => ({label: `${v.seller_name} -> ${v.buyer_name}`, value: v.id}))}
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }/>
            </Form.Item>
        </Form>
    )


}


export function DocumentForm({...props}){

    return (
        <Form layout='vertical' {...props}>
            <Form.Item name='name' label='Наименование документа'>
                <Input />
            </Form.Item>
            <Form.Item name='number' label='Номер документа'>
                <Input />
            </Form.Item>
        </Form>
    )
}
