import {Button, Menu, notification, Space, Table, Tag} from 'antd'
import {Fable} from "app/Base/Tables/Fable/Fable";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from 'react'

import ContractModal, {ContractUpdateModal} from '../Modals/ContractModal'
import ContractRequestModal from '../Modals/ContractRequestModal'
import ContractService, {ContractContragentService} from 'app/API/ContractAPI'
import {SimplPerm} from 'app/config/PermissionRouter'
import {ContractStatusTag} from "../../Common/Tags";
import moment from 'moment';
import {DateTime} from '../../Common/OtherComponents'
import {BlockingModal} from '../../Common/Modal/BlockingModal';
import FlexibleTable from "app/Base/Tables/FlexibleTable/FlexibleTable.js";
import {useContractData, useData} from "app/Data/Base";
import useContragent from '../ContragentContext'


export default function ContractTable(props) {
    // const [data, setData] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [isModalR, setIsModalR] = useState(false);
    const [isModalEdit, setIsModalEdit] = useState(false);
    const [editingRow, setEditingRow] = useState([]);
    // const [loading, setLoading] = useState(false);
    const [isModalBlock, setModalBlock] = useState(false)
    const params = useParams();
    const model = useContractData(0, params?.id);
    const {contragent} = useContragent();
    const {toggleTableUpdate} = model;


    function updateRecord(changeData, record) {
        let values = record;
        let cs = new ContractService(contragent?.id);

        for (let key in changeData) {
            values[key] = changeData[key]
        }

        cs.set_id(values.id);
        cs.update(values).then(() => {
            notification.success({'message': "Договор отредактирован"})
        }).catch(ContractService.error_handler);
    }

    function contract_menu(record) {
        return <Menu>
            {/*{is_permitted('application.contract.edit') &&*/}
            <SimplPerm perms={'application.contract.edit'}>

                <Menu.Item key="1" onClick={() => {
                    setEditingRow(record);
                    setIsModalEdit(true)
                }}>Редактировать</Menu.Item>
            </SimplPerm>
            {/*}*/}
            {record && record.buyer === params.id && record.status === 0 &&
            <>
                    <SimplPerm perms={'application.contract.edit'}>
                <Menu.Item key="2" onClick={() => updateRecord({
                        status: 1,
                        start_data: new Date().toLocaleString()
                    }, record)}
                >Подтвердить</Menu.Item>
                    </SimplPerm>
                <Menu.Item key="3" onClick={() => updateRecord({status: 2}, record)}>Отказать</Menu.Item>
                </>
            }
            <Menu.Item key='block' onClick={() => {
                setModalBlock(true);
                setEditingRow(record);
            }}>
                Заблокировать
            </Menu.Item>
        </Menu>
    }

    const columns = [
        // {title: '№', dataIndex: 'id'},
        // {title: 'внешний ID', dataIndex: 'external_id', align: 'center'},
        {title: 'Номер договора', dataIndex: 'number', align: 'left', key: 'number'},
        {
            title: 'Дата начала', dataIndex: 'start_date', key: 'start_date',
            render: (value) => (<DateTime dt={value} dateOnly/>)
        },
        {
            title: 'Тип договора', dataIndex: 'type', key: 'type',
            render: (val, record) => (<Tag>{val.name}</Tag>)
        },
        {
            title: 'Сторона 1', dataIndex: 'seller_name', key: 'seller_name',
            render: (_, record) => {
                return <Link to={`/contragents/${record.seller.id}`}>{record.seller.name}</Link>
            }
        },
        {
            title: 'Сторона 2', dataIndex: 'buyer_name', key: 'buyer_name',
            render: (_, record) => {
                return <Link to={`/contragents/${record.buyer.id}`}>{record.buyer.name}</Link>
            }
        },
        {
            title: 'Дата окончания', dataIndex: 'end_date', key: 'end_date',
            render: (value) => {
                return <DateTime dt={value} dateOnly />
            }
        },
        {
            title: 'Статус договора', dataIndex: 'status', key: 'status',
            width: 150,
            render: (value, record) => {
                if (record.active_blockings) return <Tag color='red'>Заблокировано</Tag>
                return ContractStatusTag(value)
            }
        },
    ];

    return (
        <>
            <Fable model={model}
                   columns={columns}
                   rowKey={"id"}
                   actions={(record) => contract_menu(record)}
                   title={() => (
                       <Space>
                           <SimplPerm perms={"application.contract.create"}>
                               <Button onClick={() => setIsModal(true)}>Добавить</Button>
                           </SimplPerm>
                       </Space>
                   )}
                   expandable={{
                       expandedRowRender: (record) => (
                           <ExpandableServiceContract contract_id={record.id}/>
                       ),
                       rowExpandable: record => record.type.codename,
                   }}
                   {...props}
            />
            <ContractModal
                onCancel={() => setIsModal(false)}
                onSuccess={() => {
                    setIsModal(false);
                    toggleTableUpdate();
                }}
                visible={isModal}
            />
            <ContractUpdateModal
                onCancel={() => setIsModalEdit(false)}
                onFinish={() => setIsModalEdit(false)}
                setIsModal={setIsModalEdit}
                visible={isModalEdit}
                row={editingRow}
            />
            <ContractRequestModal
                onCancel={() => setIsModalR(false)}
                setIsModalR={setIsModalR}
                visible={isModalR}
            />
            <BlockingModal
                    targetObject={editingRow}
                    onCancel={() => setModalBlock(false)}
                    onSuccess={() => setModalBlock(false)}
                    visible={isModalBlock}
            />
        </>
    )
}


function ExpandableServiceContract({contract_id, ...props}) {

    const [services, setServices] = useState([]);
    const {contragent} = useContragent();

    const columns = [
        {title: '№', dataIndex: 'id', key: 'id'},
        {title: 'услуга', dataIndex: 'type_name', key: 'type_name'},
        {title: 'продукт', dataIndex: 'offer_name', key: 'offer_name'},
        // { title: 'кол-во', dataIndex: 'num', key: 'num' },
        {
            title: 'дата создания', dataIndex: '_created', align: 'right', key: '_created',
            render: (value) => {
                return <DateTime dt={value} dateOnly/>
            }
        },
        {
            title: 'дата окончания', dataIndex: 'termination_date', align: 'right', key: 'termination_date',
            render: (value) => {
                return <DateTime dt={value} dateOnly ifnull={'бессрочно'}/>
            }
        },
        {
            title: 'статус', dataIndex: 'status', key: 'status', render: (_, record) => {
                return moment(record.termination_date) < new Date() ? 'закрыт' : 'активен'
            }
        },
    ];

    useEffect(() => {
        new ContractService(contragent?.id, contract_id).listServices().then(res => {
            setServices(res.data.data);
        })
    }, [contract_id]);

    return (
        <Table
            size='small'
            columns={columns}
            dataSource={services}
            {...props}
        />
    )
}
