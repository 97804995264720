import {Form} from "antd";
import Modal from "app/Base/Modals/Modal";
import React, {useEffect} from "react";
import PackageForm from "../Forms/PackageForm";


export function PackageModal({item, onCancel, onSuccess, ...props}) {

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        item && form.setFieldsValue(item);
    }, [item]);

    return (
        <Modal title={item ? "Изменить пакетное предложение" : "Создать пакетное предложение"}
               width={"80%"}
               onCancel={onCancel}
               onSuccess={onSuccess}
               form="create_package"
               btnname={item ? "Изменить" : "Сохранить"}
               {...props}>
            <PackageForm id="create_package"
                         form={form}
                         onFinish={onSuccess}
                         item={item || false}/>
        </Modal>
    );
}
