import { message } from "antd";
import {BaseService, error_handler} from "./BaseAPI";


export class NumeratorService extends BaseService {
    MODEL = 'numerator'

    constructor(contragent_id, numerator_id){
        super(numerator_id);
        this.MODEL = `contragent/${contragent_id}/numerator`
    }

    update_index(data){
        return this._post('index', data).then(res => {
            message.success('Последовательность обновлена');
            return Promise.resolve(res);
        }).catch(error_handler)
    }
}