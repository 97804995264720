import React, {useState} from "react";
import {Route} from "react-router-dom";
import {Redirect} from "react-router-dom";
import {Loader} from "../Base";
import {message} from "antd";
import useUser from "../Providers/UserProvider";
import {useEffectOnce} from 'react-use';


export default function PrivateRouter({children, ...props}) {
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    const {getUser} = useUser();

    useEffectOnce(() => {
        setLoading(true);
        if (localStorage.getItem('jwt_sec_token_v1')) {
            getUser().then(() => {
                setIsAuthenticated(true);
            }).catch(err => {
                message.error(err.data);
                setIsAuthenticated(false);
            }).finally(() => {
                setLoading(false);
            })
        } else {
            setIsAuthenticated(false);
            setLoading(false);
        }
    })

    return <Route {...props}>
        {loading ? <Loader visible={true}/> : isAuthenticated ? (children) : <Redirect to='/signin'/>}
    </Route>

}
