import React, {useEffect, useState} from "react";
import {Button, Card, Dropdown, Menu, message, Space, Typography} from "antd";
import {MoreOutlined,} from "@ant-design/icons";
import AccountSelect from "../../../Base/Inputs/AccountSelect";
import useAccount from "../../../Providers/AccountProvider";
import useContragent from "../ContragentContext";
import {ContragentService} from "app/API/ContragentAPI";
import {AccountService} from "app/API/AccountAPI";
import AccountModal from "../../Accounts/Modal/AccountModal";


export function AccountCard({...props}) {
    const {contragent} = useContragent();
    const {account, setAccount} = useAccount();

    const [accountsList, setAccountsList] = useState([]);
    const [accountModal, setAccountModal] = useState({visible: false});
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);

    const account_menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => setAccountModal({
                visible: true,
                item: account
            })}>Информация по текущему счёту</Menu.Item>
            <Menu.Item key="2" onClick={() => setAccountModal({visible: true})}>Создать лицевой счёт</Menu.Item>
            <Menu.Item key="3" disabled>Назначить счет по умолчанию</Menu.Item>
            <Menu.Item key="4" disabled danger>Удалить счет</Menu.Item>
        </Menu>
    )

    useEffect(() => {
        if (contragent) {
            let ctg_srv = new ContragentService(contragent.id);
            setLoading(true);
            Promise.all([
                ctg_srv.getContragentAccount(),
                ctg_srv.getDefaultAccount()
            ]).then(([accs, defaultAcc]) => {
                setAccountsList(accs.data.data);
                setAccount(defaultAcc.data);
                setLoading(false);
            }).catch((err) => {
                message.error("Нет счёта по умолчанию, создайте счёт");
            });
        }
    }, [contragent, update])

    function onSelectAccount(value) {
        new AccountService(contragent?.id, value).get().then(res => {
            setAccount(res.data);
        })
    }

    function onCloseAccountModal(){
        // accountModal?.item && setUpdate(p => !p);
        setAccountModal({visible: false});
        setUpdate(p => !p);
    }

    return (
        <Card size="small" title={`Счета`} loading={loading} {...props} extra={[
            <Dropdown overlay={account_menu} trigger={['click']}
                    placement='bottomRight'
                    disabled={account?.type === 'reserve'}>
                <Button size="small" icon={<MoreOutlined/>}/>
            </Dropdown>,
            <AccountModal title="Информация по счёту"
                visible={accountModal?.visible}
                item={accountModal?.item}
                onFinish={onCloseAccountModal}
                onCancel={onCloseAccountModal}
            />
        ]}>
            <Space direction="vertical" style={{width: '100%'}}>
                <Typography.Text type="secondary">Номер текущего счёта</Typography.Text>
                <AccountSelect accounts={accountsList}
                               onSelect={onSelectAccount}
                               OptionKey={'number'}
                               value={account?.number}
                               style={{width: "100%"}}/>
            </Space>
        </Card>
    )
}
