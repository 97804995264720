import BaseService from "app/API/BaseAPI";
import {ContragentService} from "app/API/ContragentAPI";


class ContragentableService extends BaseService{
    static contragented(contragent_id) {
        const _e = new this();
        _e._ctg_id = contragent_id;
        _e._ctg_srv = new ContragentService(contragent_id);
        return _e;
    }

    with_contragent(contragent_id) {
        this._ctg_id = contragent_id;
        this._ctg_srv = new ContragentService(contragent_id);
        return this;
    }

    get model() {
        if (this.contragent_service)
            return `${this.contragent_service.MODEL}/${this._ctg_id}/${this.MODEL}`;
        else
            return super.model;
    }

    get contragent_service() {
        if (this.hasOwnProperty('_ctg_srv'))
            return this._ctg_srv
    }

}


export {
    ContragentableService
}
