import Submit from "app/Base/Buttons/Submit";
import React from "react";
import {useToggle} from "react-use";
import PropTypes from "prop-types";
import {Button, Modal as ModalANTD, Space} from "antd";


export default function Modal({
                                  children,
                                  onSuccess,
                                  onCancel,
                                  form,
                                  title,
                                  btnname,
                                  visible,
                                  footer,
                                  id,
                                  hideCancelBtn = false,
                                  destroyOnClose = true,
                                  maskClosable = false,
                                  ...props
                              }) {
    const [finish,toggle] = useToggle(false);

    function _onSuccess(data) {
        toggle();
        onSuccess?.(data);
    }

    function _onCancel() {
        toggle();
        onCancel?.(false);
    }

    return <ModalANTD destroyOnClose={destroyOnClose}
                      visible={visible || false}
                      title={title}
                      onCancel={_onCancel}
                      onOk={_onSuccess}
                      id={id}
                      maskClosable={maskClosable}
                      footer={(footer === false) ?
                          null : (
                              footer ||
                              <Space>
                                  {(form||id)?
                                      <Submit form={form || id}
                                              finish={finish}>
                                          {btnname || "ОК"}
                                      </Submit>:
                                      <Submit onClick={_onSuccess} finish={finish}>
                                          {btnname || "OK"}
                                      </Submit>
                                  }
                                  {!hideCancelBtn &&
                                      <Button htmlType="cancel"
                                              onClick={() => (typeof _onCancel === "function" && _onCancel(false))}
                                      >Отмена</Button>}
                              </Space>)}
                      {...props}>
        {children}
    </ModalANTD>;
}


Modal.propTypes = {
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    form: PropTypes.string,
    title: PropTypes.string.isRequired,
    btnname: PropTypes.string,
    visible: PropTypes.bool.isRequired,
};
