import {useEffect, useState} from 'react';
import {Select, Space, Typography} from "antd"
import {ContragentService} from "../../API/ContragentAPI";


export default function ContragentSelect({optionKey, contragentsList, ...props}) {

    return <Select placeholder='поиск по Контрагенту | ИНН | КПП | ЛС'
                   allowClear
                   showSearch
                   filterOption={false}
                   {...props}>
        {typeof contragentsList === 'object' && contragentsList.map(el => (
            <Select.Option value={optionKey ? el[optionKey] : el.id} key={optionKey ? el[optionKey] : el.id}>
                <Space size={2} direction='vertical'>
                    <Typography.Text strong>{el.name}</Typography.Text>
                    <Typography>
                        <Typography.Text type='secondary'>ИНН: {el?.inn}</Typography.Text>
                    </Typography>
                </Space>
            </Select.Option>
        ))}
    </Select>
}


function ContragentDataSelect({value, onChange, defaultValue, ...props}) {
    const [contragents, setContragents] = useState([]);
    // const [contragent, setContragent] = useState(value);

    useEffect(() => {
        search(null);
    }, []);

    function search(val) {
        new ContragentService().list({params: {'search': val}}).then(r => {
            setContragents(r.data.data);
        });
    }

    function _onChange(val) {
        // setContragent(val);
        onChange && onChange(val);
    }

    return <ContragentSelect notFoundContent={false}
                             defaultValue={defaultValue}
                             onChange={_onChange}
                             value={value}
                             onClear={() => _onChange(null)}
                             onSearch={search}
                             contragentsList={contragents}
                             {...props}/>;
}

export {
    ContragentDataSelect
}
