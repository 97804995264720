import React, {useContext, useMemo, useState} from 'react';
import {Form} from 'antd';

const TemplateContext = React.createContext();


export function TemplateProvider({children}) {
    // Контекст хранилища по работе с авторизацией и пользователем

    const [form] = Form.useForm();
    const [selection, _setSelection] = useState({index: 0, length: 0});
    const editorRef = React.useRef();

    // TODO: переработать эту функцию и адаптировать плейсхолдеры.
    // пока решено плейсхолдеры вставлять вручную в редактор

    // function replaceTag(form_id, input_name, tag){

    //   if(!editorRef.current){
    //     return
    //   }

    //   let value = form.getFieldValue(input_name) || '';

    //   // let _form = document.getElementById(form_id);
    //   // let input = _form.querySelector(`[id=${input_name}]`);
    //   // let new_value = value.slice(0, selection.index) + `{{ ${tag} }}` + value.slice(selection.index + selection.length);

    //   // form.setFields([{name: input_name, value: new_value}])
    // }

    function setSelection(index, length) {
        if (Number.isInteger(index) && Number.isInteger(length)) {
            _setSelection({index, length});
        }
    }

    const memoedValue = useMemo(() => ({
        form, editorRef, selection, setSelection
    }), [form, selection]);


    return (
        <TemplateContext.Provider value={memoedValue}>
            {children}
        </TemplateContext.Provider>
    );
}

export default function useTemplate() {
    return useContext(TemplateContext);
}
