import React, {useCallback, useContext, useMemo, useState} from "react";


const ProductContext = React.createContext();


export class Product {
  // Объект контрагента

  constructor({id, name, type, status, status_text, description, measure, nomenclature,
     nomenclature_code, nomenclature_name, measure_name}) {
    this.id = id
    this.name = name
    this.type = type
    this.status = status
    this.measure = measure
    this.measure_name = measure_name
    this.status_text = status_text
    this.description = description
    this.nomenclature = nomenclature
    this.nomenclature_code = nomenclature_code
    this.nomenclature_name = nomenclature_name
  }
}

export function ProductProvider({initial_product, children}) {
  // Контекст хранилища по работе с продуктом
  let initial = null
  if (initial_product){
    initial = new Product(initial_product)
  }

  const [product, _setProduct] = useState(initial);

  const setProduct = useCallback((ctg) => {
    if (ctg instanceof Product) {
        _setProduct(ctg)
    } else if (ctg === null) {
        _setProduct(null)
    } else {
      console.error('Объект должен быть типа "Product"')
    }
  }, [])

  const memoedValue = useMemo(
    () => ({
      product,
      setProduct,
    }),
    [product, setProduct]
  );


  return (
    <ProductContext.Provider value={memoedValue}>
      {children}
    </ProductContext.Provider>
  );
}

export default function useProduct() {
  return useContext(ProductContext);
}
