import BaseService from './BaseAPI'
import {AttributableService} from "./AttributableService";

import {message} from "antd";

class ProductService extends AttributableService {
    MODEL = 'product'

    constructor(contragent_id, product_id){
        super(product_id);
        this.MODEL = `contragent/${contragent_id}/product`
    }

    getStatuses() {
        return this._get('status')
    }

    getContragents() {
        return this._get('contragents')
    }

    updateContragents(data) {
        return this._patch('contragents', data)
    }

    getFreeConts() {
        return this._get('free-conts')
    }

    getPrices() {
        return this._get(`offer`)
    }

    getTaxes() {
        return this._get(`taxes`)
    }

    deletePrice(id) {
        this._check_id()
        return this._delete(`offer/${id}`)
    }

    patchPrice(price_id, data) {
        return this._patch(`offer/${price_id}`, data)
    }

    createPrice(data) {
        return this._post(`offer`, data)
    }

    createOrUpdatePrice(price_id, data) {
        if (price_id)
            return this.patchPrice(price_id, data)
        else
            return this.createPrice(data)
    }

    setNomenclature (data) {
        return this._post('set-nomenclature', data).then(res => {
            message.success(`Номенклатура обновлена`);
            return Promise.resolve(res);
        }).catch(err => {
            if (err.response.data && err.response.data.detail) {
                message.error(err.response.data.detail)
            }
            return Promise.reject(err)
        })
    }
}


class ProductOfferService extends BaseService{
    MODEL = 'product'

    constructor(contragent_id, product_id){
        super()
        this.MODEL = `contragent/${contragent_id}/product/${product_id}/offer`
    }
}


class ProductNomenclatureService extends BaseService {
    MODEL = 'nomenclature'

    constructor(contragent_id, nomeclature_id){
        super(nomeclature_id);
        this.MODEL = `contragent/${contragent_id}/nomenclature`
    }
}


export {
    ProductService,
    ProductNomenclatureService,
    ProductOfferService
}
