import React, { useState } from "react";
import { Typography } from "antd";
import DebitModal from "../../Accounts/Modal/DebitModal";



export default function DebitAction({component: Component, onTransactionDone }) {
  const [visible, setVisible] = useState(false);

  let renderAction = () => {
      if(Component){
        return (
          <div onClick={() => setVisible(true)}>
            {Component}
          </div>
        )
      }
      return (
        <Typography.Text onClick={() => setVisible(true)}>
          Списание со счёта
        </Typography.Text>
      )
  }

  return (
    <>
      {renderAction()}
      <DebitModal
        visible={visible}
        onCancel={() => setVisible(false)}
        onSuccess={() => {
          setVisible(false);
          typeof onTransactionDone === 'function' && onTransactionDone();
        }
      }
      />
    </>
  );
}
