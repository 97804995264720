import React, {useCallback, useContext, useMemo, useState} from "react";

const PackageContext = React.createContext();

export class Package {
    // Объект пакетного предложения
    // Нафиг 50 строк непонятно для чего
    constructor({
                    id, name, type, status, status_text, description, measure, measure_name,
                    identifier, tags, tags_as_objects
                }) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.status = status;
        this.measure = measure;
        this.measure_name = measure_name;
        this.status_text = status_text;
        this.description = description;
        this.identifier = identifier;
        this.tags = tags;
        this.tags_as_objects = tags_as_objects;
    }
}

export function PackageProvider({initial_package, children}) {
    // Контекст хранилища по работе с пакетом
    let initial = null;
    if (initial_package) {
        initial = new Package(initial_package);
    }

    const [packages, _setPackage] = useState(initial);
    const setPackage = useCallback((ctg) => {
        if (ctg instanceof Package) {
            _setPackage(ctg)
        } else if (ctg === null) {
            _setPackage(null)
        } else {
            console.error('Объект должен быть типа "Package"')
        }
    }, []);

    const memoedValue = useMemo(
        () => ({
            packages,
            setPackage,
        }),
        [packages, setPackage]
    );


    return (
        <PackageContext.Provider value={memoedValue}>
            {children}
        </PackageContext.Provider>
    );
}

export default function usePackage() {
    return useContext(PackageContext);
}
