import React from "react";
import {Form, Input} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';


export function SigninForm(props){

    return <Form {...props}>
        <Form.Item name='username' rules={[{
            required: true, message: 'необходимо ввести логин'
        }]}>
            <Input type='text'
                   placeholder='Логин'
                   prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item name='password' rules={[{
            required: true, message: 'необходимо ввести пароль'
        }]}>
            <Input type='password'
                   placeholder='Пароль'
                   prefix={<LockOutlined />} />
        </Form.Item>
    </Form>
}
