import React from "react";
import { Modal, Button } from "antd";
import AccountForm from "../Forms/AccountForm";


export default function AccountModal({item, title, ...props}) {

  return <Modal title={title} width={'50%'}
                footer={<Button type='primary' htmlType='submit' form='form_account'>
                  {item ? 'Сохранить' : 'Создать'}
                </Button>}
                {...props}
        >
    <AccountForm id='form_account' onFinish={props?.onFinish} item={item} />
  </Modal>
}
