import {
    DeleteOutlined,
    EditOutlined,
    MoreOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    ShoppingCartOutlined,
} from "@ant-design/icons";

import {Button, Card, Col, Dropdown, Menu, message, Popconfirm, Row, Space, Table, Typography} from "antd";
import {ContragentService} from "app/API/ContragentAPI";
import {CartService} from "app/API/OrderAPI";

import {PackageService} from "app/API/PackageAPI";
import SearchTable from "app/Base/Tables/SearchTable";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useToggle} from "react-use";

import Money from "../../Components/Accounts/utils/Money";
import useUser from "../../Providers/UserProvider";

import {productStatusTag} from "../Common/Tags";
import useContragent from "../Contragents/ContragentContext";
import {PackageOrderModal} from "./Modals/OrderModal";
import {PackageModal} from "./Modals/PackageModal";

import usePackage, {PackageProvider} from "./PackageContext";


export function PackageTable({onPackageClick}) {

    const [modalPackageVisible, setModalPackageVisible] = useState({visible: false});
    const [packages, setPackages] = useState([]);
    const [update, toggle] = useToggle(false);
    const {contragent, refreshCart} = useContragent();
    const [loading, setLoading] = useState(false);
    const [fltdPackages, setFltdPackages] = useState(packages);
    const [search, setSearch] = useState();

    useEffect(() => {
        setFltdPackages(
            packages.filter(x =>
                !search ||
                x.name.toLowerCase().includes(search.toLowerCase())),
        );
    }, [search, packages]);

    useEffect(() => {
        setLoading(true);
        new ContragentService(contragent?.id).getPackages().then(res => {
            setPackages(res.data.data.map(el => {
                el.key = el.id;
                setLoading(false);
                return el;
            }));
        });
    }, [modalPackageVisible, update]);

    function addToBucket(record) {
        new CartService(contragent?.id).add_package({
            contragent: contragent.id,
            package: record?.id,
        });
        refreshCart();
    }

    const columns = [
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            width: 50,
        },

        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            render: function (name, item) {
                if (typeof onPackageClick === "function")
                    onPackageClick(item);
                return <Link to={`/packages/${item.id}`}>{name}</Link>;
            },
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            width: 150,
            render: productStatusTag,
        },
        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            align: "right",
            render: (value, record) => (
                <Space disabled={false}>
                    <Button
                        size="small" icon={<PlusOutlined/>}
                        onClick={() => {
                            setModalPackageVisible({visible: true, item: record});
                        }}
                    />
                    <Button
                        size="small"
                        icon={<ShoppingCartOutlined/>}
                        onClick={() => addToBucket(record)}
                    />
                </Space>
            ),
        },
    ];

    return (
        <SearchTable
            size="small"
            columns={columns}
            dataSource={fltdPackages}
            width={1000}
            toggle={toggle}
            loading={loading}
            onSearchEdit={setSearch}
            expandable={{
                expandedRowRender: (record) => (
                    <PackageProvider initial_package={record}>
                        <PackageItemTable/>
                    </PackageProvider>
                ),
            }}
            title={() => (
                <Row justify="end">
                    <Col>
                        <PackageOrderModal
                            // visible={orderPackageModalVisible && !['reconciliation', 'derivation'].includes(orderModalType) }
                            visible={modalPackageVisible?.visible}
                            item={modalPackageVisible?.item}
                            onCancel={() => {
                                setModalPackageVisible({visible: false});
                                toggle();
                            }}
                        />
                    </Col>
                </Row>
            )}
            // pagination={{pageSize: 100}}
        />
    );

}

export default function CardPackageTable({onPackageClick}) {

    const [modalPackageVisible, setModalPackageVisible] = useState({visible: false});
    const [packages, setPackages] = useState([]);
    const [update, toggle] = useState(false);
    const {is_global_permitted, contragent} = useUser();
    const [search, setSearch] = useState("");
    const [fltdPackages, setFltdPackages] = useState(packages);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (!modalPackageVisible?.visible) {
            new PackageService(contragent?.id).list().then(res => {
                setPackages(res.data.data.map(el => {
                    el.key = el.id;
                    setLoading(false);
                    return el;
                }));
            });
        }
    }, [update]);

    useEffect(() => {
        setFltdPackages(
            packages.filter(x =>
                !search ||
                x.name.toLowerCase().includes(search.toLowerCase())),
        );
    }, [packages, search]);

    function access_actions() {
        let access = [];

        function render_menu(record) {

            function delete_package() {
                new PackageService(contragent?.id, record?.id).delete().then(res => {
                    message.success(res?.data.msg);
                    toggle(p => !p);
                });
            }

            return (
                <Menu>
                    {is_global_permitted("application.package.edit") &&
                        <Menu.Item icon={<EditOutlined/>}
                                   onClick={() => setModalPackageVisible({visible: true, item: record})}
                        >Редактировать</Menu.Item>
                    }
                    {is_global_permitted("application.package.delete") &&
                        <Popconfirm
                            title={`вы уверены, что хотите удалить ${record?.name}`}
                            onConfirm={delete_package}
                            okText="Удалить"
                            cancelText="Отменить"
                            icon={<QuestionCircleOutlined style={{color: "red"}}/>}
                        >
                            <Menu.Item icon={<DeleteOutlined/>} danger>Удалить</Menu.Item>
                        </Popconfirm>
                    }
                </Menu>
            );
        }

        if (is_global_permitted("application.package.delete")) {
            access.push({
                title: "",
                dataIndex: "actions",
                key: "actions",
                width: 50,
                render: (value, record) => (
                    <Dropdown overlay={() => render_menu(record)} trigger={["click"]}>
                        <Button size="small" icon={<MoreOutlined/>}/>
                    </Dropdown>
                ),
            });
        }
        return access;
    }

    const columns = [
        ...access_actions(),
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            width: 50,
        },
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            render: function (name, item) {
                if (typeof onPackageClick === "function")
                    onPackageClick(item);
                return <Link to={`/packages/${item.id}`}>{name}</Link>;
            },
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            width: 150,
            render: productStatusTag,
        },
    ];

    return (
        <Card
            title="Пакетные предложения"
            extra={
                // <Permission global={"create_package"}>
                <Button type="primary" onClick={() => setModalPackageVisible({visible: true})}>Создать</Button>
                // </Permission>
            }
        >
            <SearchTable
                size="small"
                columns={columns}
                dataSource={fltdPackages}
                loading={loading}
                toggle={toggle}
                expandable={{
                    expandedRowRender: (record) => (
                        <PackageProvider initial_package={record}>
                            <PackagePriceTable/>
                        </PackageProvider>
                    ),
                }}
                onSearchEdit={setSearch}
            />
            <PackageModal visible={modalPackageVisible?.visible}
                          item={modalPackageVisible?.item}
                          onSuccess={() => {
                              setModalPackageVisible({visible: false});
                              toggle();
                          }}
                          onCancel={() => setModalPackageVisible({visible: false})}/>
        </Card>);
}


export function PackageItemTable() { // TODO: Почти полная копия PackagePriceTable  разобраться. Отрефакторить

    const [prices, setPrices] = useState([]);
    const [loading, setLoading] = useState(true);
    const {packages} = usePackage();
    const {contragent} = useUser();

    const {Text} = Typography;

    useEffect(() => {
        if (packages?.id) {
            setLoading(true);
            new PackageService(contragent?.id, packages?.id).getPrices().then(res => {
                setPrices(res.data.data);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [packages]);

    // function addToBucket() {
    //     new CartService(contragent?.id).add_package({contragent: contragent.id, package: packages?.id})
    //     .then(res => setBucket(res.data))
    // }

    const columns = [
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            width: 50,
        },
        {
            title: "описание",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "контрагент",
            dataIndex: "contragent_name",
            key: "contragent_name",
        },
        {
            title: "цена",
            dataIndex: "price",
            key: "price",
            align: "right",
            render: (value, record) => (
                <Money sum={value} currency={record.currency}/>
            ),
        },
        {
            title: "количество",
            dataIndex: "count",
            key: "count",
            align: "right",
        },
        {
            title: "налоговая ставка",
            dataIndex: "taxes",
            key: "taxes",
            render: (value) => {
                return value === "NO_VAT" ? "Без НДС" : `${value}%`;
            },
        },
        {
            title: "предоплата",
            dataIndex: "pre",
            key: "pre",
            render: (value) => {
                return `${value} %`;
            },
        },
        {
            title: "тип услуги",
            dataIndex: "type_name",
            key: "type_name",
        },
        {
            title: "сумма",
            dataIndex: "sum",
            key: "sum",
            render: (value, record) => {
                let sum = parseFloat(record.price) * parseFloat(record.count);
                return `${sum}`;
                // <Money sum={parseFloat(record.price) * parseFloat(record.count)} />
            },
        },
    ];

    return <Table
        size="small"
        columns={columns}
        dataSource={prices}
        pagination={false}
        loading={loading}
        bordered
        summary={(pageData) => {
            let totalSum = 0;
            pageData.forEach(({price, count}) => {
                totalSum += parseFloat(price) * parseFloat(count);
            });
            return (
                <>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                        <Table.Summary.Cell index={4}></Table.Summary.Cell>
                        <Table.Summary.Cell index={5}></Table.Summary.Cell>
                        <Table.Summary.Cell index={6}></Table.Summary.Cell>
                        <Table.Summary.Cell index={7}>Итого:</Table.Summary.Cell>
                        <Table.Summary.Cell index={8}>
                            <Text type="danger"><Money sum={totalSum}/></Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                </>
            );
        }}
    />;
}

export function PackagePriceTable() { // TODO: Почти полная копия PackageItemTable разобраться. Отрефакторить

    const [prices, setPrices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [update, setUpdate] = useState(false);
    const {contragent} = useUser();
    const {packages} = usePackage();
    const {Text} = Typography;

    useEffect(() => {
        if (packages?.id) {
            setLoading(true);
            new PackageService(contragent?.id, packages?.id).getPrices().then(res => {
                setPrices(res.data.data);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [packages, update]);

    // function addToBucket() {
    //     new CartService(contragent?.id).add_package({contragent: contragent.id, package: packages?.id})
    //     .then(res => setBucket(res.data))
    // }

    const columns = [
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            width: 50,
        },
        {
            title: "описание",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "контрагент",
            dataIndex: "contragent_name",
            key: "contragent_name",
        },
        {
            title: "цена",
            dataIndex: "price",
            key: "price",
            align: "right",
            render: (value, record) => (
                <Money sum={value} currency={record.currency}/>
            ),
        },
        {
            title: "количество",
            dataIndex: "count",
            key: "count",
            align: "right",
        },
        {
            title: "налоговая ставка",
            dataIndex: "taxes",
            key: "taxes",
            render: (value) => {
                return value === "NO_VAT" ? "Без НДС" : `${value}%`;
            },
        },
        {
            title: "предоплата",
            dataIndex: "pre",
            key: "pre",
            render: (value) => {
                return `${value} %`;
            },
        },
        {
            title: "тип услуги",
            dataIndex: "type_name",
            key: "type_name",
        },
        {
            title: "сумма",
            dataIndex: "sum",
            key: "sum",
            align: "right",
            render: (value, record) => {
                let sum = parseFloat(record.price) * parseFloat(record.count);
                return <Money sum={sum} currency={record.currency}/>;
            },
        },
    ];

    return <Table
        size="small"
        columns={columns}
        dataSource={prices}
        pagination={false}
        loading={loading}
        bordered
        summary={(pageData) => {
            let totalSum = 0;
            pageData.forEach(({price, count}) => {
                totalSum += parseFloat(price) * parseFloat(count);
            });
            return (
                <>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                        <Table.Summary.Cell index={4}></Table.Summary.Cell>
                        <Table.Summary.Cell index={5}></Table.Summary.Cell>
                        <Table.Summary.Cell index={6}></Table.Summary.Cell>
                        <Table.Summary.Cell index={7}><Text strong>Итого:</Text></Table.Summary.Cell>
                        <Table.Summary.Cell index={8} align="right">
                            <Text strong>
                                <Money sum={totalSum}/>
                            </Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                </>
            );
        }}
    />;
}


export function PackageOrderTable({...props}) {

    const columns = [
        {
            title: "Наименование",
            dataIndex: "product_name",
            key: "product_name",
        },
        {
            title: "Цена",
            dataIndex: "price",
            key: "price",
            align: "right",
            render: (value, record) => (
                <Money sum={value} currency={record.currency}/>
            ),
        },
        {
            title: "Налоговая ставка",
            dataIndex: "percent_tax",
            key: "percent_tax",
            render: (value) => {
                return value === "NO_VAT" ? "Без НДС" : `${value}%`;
            },
        },
        {
            title: "Тип услуги",
            dataIndex: "product_type_name",
            key: "product_type_name",
        },
        {
            title: "Количество",
            dataIndex: "count",
            key: "count",
            align: "center",
            // render: (value, record) => (
            //     <Space size="middle">
            //         <InputNumber
            //             defaultValue={record.count}
            //             min={record.min_orders}
            //             max={record.max_orders}
            //             size='small'
            //             onChange={(val) => handleChange(val, record)}
            //         />
            //     </Space>
            // )
        },
        // {
        //     title: 'макc',
        //     dataIndex: 'max_orders',
        //     key: 'max_orders',
        // },
        {
            title: "Сумма",
            dataIndex: "sum",
            key: "sum",
            align: "right",
            render: (value, record) => {
                return <Money sum={parseFloat(record.price) * parseFloat(record.count)}/>;
            },
        },
        {
            title: "Скидка",
            dataIndex: "discount",
            key: "discount",
            align: "right",
            render: (value, record) => {
                return <Money sum={value}/>;
            },
        },
    ];

    return <Table
        size="small"
        columns={columns}
        pagination={false}
        bordered
        {...props}
    />;
}

