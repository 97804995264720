import { DatePicker, Form, Input } from 'antd'
import React from 'react'


export default function CounterForm ({...props}) {
  
  return (
    <Form layout='vertical' {...props}>
      <Form.Item label='значение счётчика' name='value'>
        <Input type='number' />
      </Form.Item>
      {/* <Form.Item label='дата' name='datetime'>
        <DatePicker type='number' />
      </Form.Item> */}
      <Form.Item label='комментарий' name='description'>
        <Input.TextArea />
      </Form.Item>
    </Form>
  )
}
