import React from "react";
import {Form, Input, InputNumber, message} from 'antd';
import {NumeratorService} from "src/API/NumeratorAPI";
import useUser from "../../../Providers/UserProvider";


export function NumeratorForm(props){

    const {contragent} = useUser();

    function onUpdateNumerator(data){
        new NumeratorService(contragent.id, data?.id).update(data).then(res => {
            typeof props.onFinish === 'function' && props.onFinish(data);
        })
    }

    return <Form {...props} onFinish={onUpdateNumerator}>
        <Form.Item name='id' hidden label='Идентификатор'></Form.Item>
        <Form.Item name='expression' label='Выражение'>
            <Input />
        </Form.Item>
        {/* <Form.Item name='fill_char' label='Символ-заполнитель'>
            <Input />
        </Form.Item>
        <Form.Item name='fill_length' label='Длина заполнителя'>
            <Input />
        </Form.Item> */}
    </Form>
}


export function SequenceForm(props){

    const {contragent} = useUser();

    function onUpdateNumerator(data){
        new NumeratorService(contragent.id, data?.id).update_index({index: data?.current_index}).then(res => {
            typeof props.onFinish === 'function' && props.onFinish(data);
        })
    }

    return <Form {...props} onFinish={onUpdateNumerator}>
        <Form.Item name='id' hidden label='Идентификатор'></Form.Item>
        <Form.Item name='current_index' label='Текущее значение'>
            <InputNumber min={1} />
        </Form.Item>
    </Form>
}