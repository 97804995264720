import {Button, Col, Layout, notification, Row, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import {ContragentService} from "../../../API/ContragentAPI";
import AttributesForm from '../../Common/Forms/AttributesForm';
import {Card} from "../../../Base";
import {DictionaryService} from "../../../API/DictionaryAPI";

import styles from '../Contragents.module.less'
import useContragent from "../ContragentContext";
import useUser from "../../../Providers/UserProvider";

export default function CardAttribute({entityType}) {
    const {is_permitted} = useUser();
    const params = useParams();
    const [dictionaries, setDictionaries] = useState([]);
    const [extAccs, setExtAccs] = useState([]);
    const {contragent} = useContragent();

    function sendUpdate(data) {
        if (contragent?.id) {
            new ContragentService(params.id).update({'attributes': data}).then(res => {
                notification.success({'message': 'Карточка контрагента обновлена', 'description': res?.data?.msg});
            })
        }
    }

    useEffect(() => {
        if (params.id) {
            const cs = new ContragentService(params.id);
            cs.attributes.get().then(res => {
                setDictionaries(res.data.data)
            });
            cs.accounts({type: ['external']}).then(({data:{data}}) => {
                setExtAccs(data);
            })
        }
    }, [params.id])

    useEffect(() => {
        if (!params.id) {
            if (entityType) {
                new DictionaryService().getDictsByEntity(entityType).then(res => {
                    setDictionaries(res.data.data)
                })
            }
        }
    }, [entityType, params.id])

    return <Layout.Content style={{padding: '1em'}}>
        <Row gutter={[16, 16]}>
            {dictionaries.length === 0 && <Typography>
                В данный момент нет активных атрибутов. Создайте или назначьте в <Link to='/dictionary'>справочнике</Link> группы атрибутов.
            </Typography>}
            {dictionaries.map(el => {
                return <Col span={24} key={el.codename}>
                    <Card className={styles.contragent__card}>
                        <AttributesForm id={el.codename} attributes={el.attributes}
                                        onFinish={(data) => sendUpdate(data)}/>
                        {is_permitted('application.contragent.edit') && 
                            <Button type='primary' htmlType='submit' form={el.codename}>Сохранить</Button>
                        }
                    </Card>
                </Col>
            })}
        </Row>
    </Layout.Content>
}


CardAttribute.propTypes = {
    entityType: PropTypes.string
}
