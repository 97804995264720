import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {Button, notification, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import {DictionaryService} from "src/API/DictionaryAPI";
import useUser from "src/Providers/UserProvider";
import DictionaryModal from "../Modals/DictionaryModal";


export default function DictionaryTable(){
    const {is_permitted} = useUser();
    const [dictionaries, setdictionaries] = useState([]);
    const [visible, setVisible] = useState(false);
    const [edited, setEdited] = useState(null);
    const [update, setUpdate] = useState(false);

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Идентификатор',
            dataIndex: 'codename',
            key: 'codename',
        },
        {
            title: 'Действие',
            key: 'operation',
            fixed: 'right',
            render: (_, record) => {
                return <Space>
                    {is_permitted('application.attribute.delete') &&
                        <Button size='small' shape='circle' icon={<DeleteOutlined />} danger ghost onClick={() => {
                            new DictionaryService(record.id).delete().then(res => {
                                notification.success({message:'Успешно', description: `Словарь "${record.name}" удалён`});
                                setUpdate(prev => !prev);
                            }).catch(err => {

                            })
                        }} />
                    }
                    {is_permitted('application.attribute.edit') &&
                        <Button size='small' shape='circle' icon={<EditOutlined />} onClick={() => {
                            setEdited(record); setVisible(true);
                        }} />
                    }
                </Space>
            },
        },
    ];

    useEffect(() => {
        new DictionaryService().list().then(res => {
            setdictionaries(res.data.data)
        })
    }, [update])

    const onSuccess = (d) => {
        setVisible(false); setEdited(null);
        setUpdate(prev => !prev);
    }

    return <>
        {is_permitted('application.attribute.create') &&
            <Button type="primary" onClick={() => setVisible(true)} style={{marginBottom: '1em'}}>Создать</Button>
        }
        <Table size='small' dataSource={dictionaries} columns={columns} pagination={false} />
        <DictionaryModal
            data={edited}
            visible={visible}
            destroyOnClose
            onCancel={() => {setVisible(false); setEdited(null)}}
            onSuccess={(data) => onSuccess(data)}
        />
    </>

}
