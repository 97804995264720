import React from "react";
import {Card, Button, Space, Layout} from 'antd';
import {Link} from 'react-router-dom';

import RegisterForm from "./Forms/RegisterForm";
import './style.css';
import useAuth from "../../Providers/AuthProvider";


export default function Register(){
    const {register} = useAuth();

    //Компонент с формой регистрации
    return <Layout style={{width: '100%', background: '#fed74d', height: '100vh', display: 'flex', alignItems: 'center'}}>
        {/* Вынести svg в отдельный файл */}
        <svg id="low_poly" viewBox="0 0 1600 600" preserveAspectRatio="xMidYMid slice">
            <defs>
            <linearGradient id="grad2" x1="0" y1="0" x2="1" y2="0" color-interpolation="sRGB">
                <stop id="stop2a" offset="0%" stop-color="#63ADD0" />
                <stop id="stop2b" offset="100%" stop-color="#034769" />
            </linearGradient>
            </defs>
            <rect id="rect1" x="0" y="0" width="1600" height="600" stroke="none" fill="url(#grad1)" />
            <rect id="rect2" x="0" y="0" width="1600" height="600" stroke="none" fill="url(#grad2)" />
        </svg>
        <Card style={{borderRadius: '10px', textAlign: 'center', margin: '50px'}}>
            <img src='/img/logo.png' alt='logo' style={{marginBottom: '1em', width: '100px'}} />
            <RegisterForm
                name='register_form'
                onFinish={(e) => register(e)}
            />
            <Space direction='vertical'>
                <Space>
                    <Link to='/signin'>
                        <Button type='link'>Войти</Button>
                    </Link>
                    <Button type='primary' htmlType='submit' form='register_form'>Регистрация</Button>
                </Space>
            </Space>
        </Card>
    </Layout>
}
