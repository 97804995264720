import {UploadOutlined} from "@ant-design/icons";
import {Button, Collapse, Form, Input, message, notification, Select, Skeleton, Upload} from "antd";
import {renderInput} from "app/lib/dynamic-comps";
import {convert_url} from "app/lib/tools";
import React, {useEffect, useState} from "react";
import {useToggle} from "react-use";
import {AccountService} from "app/API/AccountAPI";
import {downloadDocument} from "src/API/BaseAPI";
import useContragent from '../../Contragents/ContragentContext'

export function PPUpload({item, title, signs, sign, onSuccess, toggle, ...props}) {
    const {contragent} = useContragent();

    return <Upload
        listType="text"
        defaultFileList={signs[sign] ? [{
            uid: "-1",
            name: title,
            status: "done",
            url: convert_url(signs[sign]),
        }] : []}
        maxCount={1}
        onPreview={(file) => {
            downloadDocument(file.url);
        }}
        customRequest={(obj) => {
            new AccountService(contragent?.id, item?.number).uploadSigns(sign, obj).then(res => {
                onSuccess(res.data);
                toggle();
                return obj.onSuccess();
            }).catch(err => {
                return obj.onError();
            });
        }}
        {...props}
    >
        <Button icon={<UploadOutlined/>}>Загрузить</Button>
    </Upload>;
}


export default function AccountForm({item, onFinish, ...props}) {

    const [currencies, setCurrencies] = useState([]);
    const [dictionaries, setDictionary] = useState([]);
    const [signs, setSigns] = useState();
    // const [curr, setCurr] = useState();
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    // const [commit, setCommit] = useState(false);
    const [update, toggle] = useToggle();
    const {contragent} = useContragent();

    useEffect(() => {
        new AccountService(contragent?.id).getCurrencies().then(({data: {data}}) => {
            setCurrencies(data);
        }).catch(err => {
            message.error("Возникла ошибка");
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        if (item) {
            Promise.all([
                new AccountService(contragent?.id, item?.id).get(),
                new AccountService(contragent?.id, item?.id).attributes.get(),
                new AccountService(contragent?.id, item?.id).signs(),
            ]).then(([acc_res, attr_res, sngs]) => {
                form.setFieldsValue(acc_res.data);
                setSigns(sngs.data);
                setDictionary(attr_res.data.data);
                setLoading(false);
            });
        }
    }, [item, update]);

    function onFinishForm(data) {
        new AccountService(contragent?.id, item?.id).update_or_create(data).then(res => {
            (typeof onFinish === "function") && onFinish(data);
            notification.success({"message": `Лицевой счет ${item ? "обновлён" : "создан"}`});
        });
    }

    if (loading) {
        return Array(4).fill(0).map(el => (<Skeleton></Skeleton>));
    }

    return <Form layout="vertical" form={form} onFinish={onFinishForm} {...props}>
        <Form.Item name="name" label="Наименование счета" tooltip="Пометка">
            <Input/>
        </Form.Item>
        <Form.Item name='number' label='Номер'>
            <Input />
        </Form.Item>
        <Form.Item name="currency" label="Валюта">
            <Select disabled={item}>
                {currencies.map(el => {
                    return <Select.Option key={el.value} value={el.value}>{el.title}</Select.Option>;
                })}
            </Select>
        </Form.Item>
        <Form.Item name="credit_limit" label="Кредитный лимит">
            <Input type="number"/>
        </Form.Item>
        {/* <Form.Item label={'Тэги'}>
            <Tagger object={account_id} commit={commit}/>
        </Form.Item> */}
        <Form.Item name="attributes">
            {dictionaries.map(el => (
                <Collapse>
                    <Collapse.Panel header={el.name}>
                        {el.attributes.map(attr => (
                            <Form.Item key={attr.codename}
                                       name={["attributes", attr.codename]}
                                       label={attr.name}
                                       required={attr.is_required}
                                       initialValue={attr.default}>
                                {renderInput(attr.type, attr.enums)}
                            </Form.Item>
                        ))}
                    </Collapse.Panel>
                </Collapse>
            ))}
        </Form.Item>
        <Form id="stamps">
            <Form.Item label="Изображение подписи директора" name={"sign_director"}>
                <PPUpload item={item}
                          sign={"sign_director"}
                          signs={signs}
                          toggle={toggle}
                          onSuccess={setSigns}
                          title={"Подпись директора"}/>
            </Form.Item>
            <Form.Item label="Изображение подписи бухгалтера" name={"sign_buh"}>
                <PPUpload item={item}
                          sign={"sign_buh"}
                          signs={signs}
                          toggle={toggle}
                          onSuccess={setSigns}
                          title={"Подпись бухгалтера"}/>
            </Form.Item>
            <Form.Item label="Изображение печати предприятия" name={"stamp"}>
                <PPUpload item={item}
                          sign={"stamp"}
                          signs={signs}
                          toggle={toggle}
                          onSuccess={setSigns}
                          title={"Печать предприятия"}/>
            </Form.Item>
        </Form>
    </Form>;
}
