import React from "react";
import { Layout } from "antd";
import ProductTable from "./ProductTable";
import PackageTable from "./PackageTable";
import { Route, Switch } from "react-router";
import ProductDetail from "./ProductDetail";
import {AccountProvider} from '../../Providers/AccountProvider';
import { ProductProvider } from "./ProductContext";
import { PackageProvider } from "./PackageContext";
import PackageDetail from "./PackageDetail";


export default function Product(){

    return <Layout.Content>
        <Switch>
            <Route path='/contragents/:ctg_id/products/:id'>
                <ProductProvider>
                    <AccountProvider>
                        <ProductDetail />
                    </AccountProvider>
                </ProductProvider>
            </Route>
            <Route path='/contragents/:ctg_id/packages/:id'>
                <PackageProvider>
                    <AccountProvider>
                        <PackageDetail />
                    </AccountProvider>
                </PackageProvider>
            </Route>
            <Route exact path='/contragents/:ctg_id/products'>
                <ProductTable />
                {/* <PackageTable /> */}
            </Route>
        </Switch>
    </Layout.Content>
}
