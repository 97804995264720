import moment from 'moment';
import React from "react";

import {Typography, Descriptions, Col} from "antd";

import Currency from "../../Components/Accounts/utils/Currency";
import Money from "../../Components/Accounts/utils/Money";


const dtFormat = 'DD.MM.YYYY HH:mm:ss'

function dateTime(dt, date_only = false, time_only = false, ifnull = " - ") {
    if (!dt)
        return ifnull;

    // const offst = (new Date()).getTimezoneOffset() * (-1);
    try {
        // const mdt = moment(dt).utcOffset(offst);
        const mdt = moment(dt);

        let fdt = mdt.format(dtFormat);
        if (date_only)
            fdt = mdt.format('DD.MM.YYYY');

        if (time_only)
            fdt = mdt.format('HH:mm:ss');

        return fdt;
    } catch (e) {
        return ifnull;
    }
}

function DateTime({dt, dateOnly = false, timeOnly = false, style = {}, ifnull = " - "}) {
    return <Typography.Text style={style}>{dateTime(dt, dateOnly, timeOnly, ifnull)}</Typography.Text>
}


function ContractShortDetail({contract, br}) {
    return <>
        <Typography.Text italic>{contract?.get_type_display} </Typography.Text>
        {br && <br/>}
        <Typography.Text>№ {contract?.number}</Typography.Text> от {contract?.start_date ? <DateTime dt={contract?.start_date} dateOnly /> : 'N/a'}
    </>
}

function AccountShortDetail({account}) {
    return <>
        <Currency code={account.currency} type='flag' style={{
            width: "15px",
            borderRadius: "50%",
            marginTop: "7px",
        }}/>
        <Typography.Text style={{marginLeft: 6}}>{account.number}</Typography.Text>

    </>
}

function OrderItemCountPlusMeasure({item}) {
    return <>{item?.count} {item.measure ? item.measure : 'шт'}</>
}

function ItemTax({calculated_tax, currency, percent_tax}) {
    return percent_tax === 'NO_VAT'
        ? 'Без НДС'
        : <><Money sum={calculated_tax} currency={currency}/> {`(${parseInt(percent_tax)} %)`}</>
}


function formatValue (value) {
    if (value === null || value === undefined) {
        return 'отсутствует';
    } else if (Array.isArray(value)) {
        return value.join(', ');
    } else if (typeof value === 'object') {
        return JSON.stringify(value);
    } else {
        return value.toString();
    }
};

function ListAttrAsDescription({attrs, showTitle=true}) {
    return attrs && attrs.map(el => (
        <Col xs={24} key={el?.name}>
            <Descriptions column={1} bordered title={showTitle && el?.name} size='small'>
                {el?.attributes.map(attr => (
                    <Descriptions.Item label={attr?.name} key={attr?.name}>
                        <Typography.Text>
                            {formatValue(attr?.default)}
                        </Typography.Text>
                    </Descriptions.Item>
                ))}
            </Descriptions>
        </Col>
    ));
}

function DictAttrAsDescription({ attrs, showTitle = true }) {
    return (
        <Col xs={24}>
            <Descriptions column={1} bordered title={showTitle && 'Дополнительные поля'} size='small'>
                {attrs && Object.keys(attrs).map(key => (
                    <Descriptions.Item label={key} key={key}>
                        <Typography.Text>
                            {formatValue(attrs[key])}
                        </Typography.Text>
                    </Descriptions.Item>
                ))}

            </Descriptions>
        </Col>
        )
}

export {
    ContractShortDetail,
    AccountShortDetail,
    OrderItemCountPlusMeasure,
    ItemTax,
    DateTime,
    dateTime,
    ListAttrAsDescription,
    DictAttrAsDescription
}
